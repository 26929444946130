import { useCallback, useEffect, useState } from "react";
import apiClient from "src/frontend/api/ApiClient";
import TrpcClient from "src/frontend/api/TrpcClient";
import Col from "src/frontend/components/Col";
import LightLabel from "src/frontend/components/LightLabel";
import PageContainer from "src/frontend/components/PageContainer";
import PaginationControls from "src/frontend/components/PaginationControls";
import ProductCategoryFilter from "src/frontend/components/ProductCategoryFilter";
import Row from "src/frontend/components/Row";
import SearchInput from "src/frontend/components/SearchInput";
import SelectedProductFiltersList from "src/frontend/components/SelectedProductFiltersList";
import UserColumnVisibilityFilterComponent from "src/frontend/components/UserColumnVisibilityFilterComponent";
import TableSkeleton from "src/frontend/components/skeletons/TableSkeleton";
import DownloadButton from "src/frontend/components/ui/DownloadButton";
import { FancyBoxItem } from "src/frontend/components/ui/FancyBox";
import useToast from "src/frontend/components/ui/useToast";
import { cn } from "src/frontend/components/ui/utils";
import useGetCompetitorMatchReviewsQuery from "src/frontend/hooks/queries/useGetCompetitorMatchReviewsQuery";
import useTagsQuery from "src/frontend/hooks/queries/useTagsQuery";
import useUserColumnVisibilityFiltersQuery from "src/frontend/hooks/queries/useUserColumnVisibilityFiltersQuery";
import useBreakpoints from "src/frontend/hooks/useBreakpoints";
import useIsBrandInitialized from "src/frontend/hooks/useIsBrandInitialized";
import CompetitorIntelligenceInStockFilter from "src/frontend/pages/competitor-intelligence/CompetitorIntelligenceInStockFilter";
import CompetitorIntelligenceMatchLevelFilter from "src/frontend/pages/competitor-intelligence/CompetitorIntelligenceMatchLevelFilter";
import CompetitorIntelligencePriceSelect from "src/frontend/pages/competitor-intelligence/CompetitorIntelligencePriceSelectFilter";
import CompetitorIntelligencePriceTimeRangeSelect from "src/frontend/pages/competitor-intelligence/CompetitorIntelligencePriceTimeRangeSelect";
import CompetitorIntelligenceTable from "src/frontend/pages/competitor-intelligence/CompetitorIntelligenceTable";
import useCompetitorIntelligenceFiltersStore from "src/frontend/stores/useCompetitorIntelligenceFiltersStore";
import downloadCompetitorIntelligenceCsv from "src/frontend/utils/download/downloadCompetitorIntelligenceCsv";
import getCategoryFiltersFromBrandCategoriesAndTags from "src/frontend/utils/getCategoryFiltersFromBrandCategoriesAndTags";
import logClientError from "src/frontend/utils/logClientError";
import parseImpactedSkusSelection from "src/frontend/utils/parseImpactedSkusSelection";
import PRODUCT_CATALOG_PAGE_SIZE from "src/shared/constants/ProductCatalogPageSize";
import arrayNotEmpty from "src/shared/utils/arrays/arrayNotEmpty";
import sortByProperty from "src/shared/utils/arrays/sortByProperty";
import formatNumberRounded from "src/shared/utils/numbers/formatNumberRounded";
import deepEquals from "src/shared/utils/objects/deepEquals";
import SentryErrorEvent from "src/shared/utils/sentryErrorUtils";
import { useDebounce } from "usehooks-ts";

export default function CompetitorIntelligence() {
  const t = useToast();
  const { isExtraLarge } = useBreakpoints();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const visibilityFiltersQuery = useUserColumnVisibilityFiltersQuery();
  const filters = useCompetitorIntelligenceFiltersStore();
  const {
    applyAndConditionForCategory,
    applyAndConditionForFilters,
    applyAndConditionForPriceZone,
    applyAndConditionForProductBrandName,
    applyAndConditionForSubCategory,
    applyAndConditionForSupplier,
    applyAndConditionForTags,
    filteredSkus,
    inStockFilter,
    matchLevelFilters,
    page,
    recentPriceOption,
    searchText,
    sortBy,
    sortDirection,
  } = filters;
  const debouncedSearchTerm = useDebounce<string>(searchText, 400);
  const [totalCount, setTotalCount] = useState(0);
  const { brandId, enabled } = useIsBrandInitialized();
  const [skusFilterOptions, setSkusFilterOptions] = useState<FancyBoxItem[]>(
    [],
  );

  const setPage = useCallback((page: number) => {
    useCompetitorIntelligenceFiltersStore.setState({
      page,
      selectedProductIds: [],
    });
  }, []);

  const setSearchTerm = (term: string) => {
    useCompetitorIntelligenceFiltersStore.setState({ searchText: term });
  };

  const setFilteredSkus =
    useCompetitorIntelligenceFiltersStore.getState().setFilteredSkus;

  const resetPage = useCallback(() => {
    setPage(1);
  }, [setPage]);

  const setSelectedItemsOverrideFn = (item: FancyBoxItem) => {
    useCompetitorIntelligenceFiltersStore.setState(
      ({ filteredSkus: filters }) => {
        return {
          filteredSkus:
            filters.find((current) => current.value === item.value) == null
              ? filters.concat(item)
              : filters.filter((l) => l.value !== item.value),
        };
      },
    );
    resetPage();
  };

  const handleRemoveItem = (item: FancyBoxItem) => {
    setFilteredSkus((currentItems) =>
      !currentItems.includes(item)
        ? currentItems.concat(item)
        : currentItems.filter((l) => l.value !== item.value),
    );
    resetPage();
  };

  const {
    impactedCategories: categories,
    impactedPriceZones: priceZones,
    impactedProductBrandNames: productBrandNames,
    impactedSubCategories: subCategories,
    impactedSuppliers: suppliers,
    impactedTags: tagIds,
    includeEmptyCategory,
    includeEmptyPriceZone,
    includeEmptyProductBrandName,
    includeEmptySubCategory,
    includeEmptySupplier,
    includeUntaggedSkus,
    showActiveSkus,
    showInactiveSkus,
  } = parseImpactedSkusSelection(filteredSkus);

  useGetCompetitorMatchReviewsQuery();
  const tagsQuery = useTagsQuery();
  const productCategoriesQuery =
    TrpcClient.internal.getBrandProductCategories.useQuery({ brandId });

  const competitorIntelligence =
    TrpcClient.internal.getCompetitorIntelligence.useQuery(
      {
        applyAndConditionForCategory,
        applyAndConditionForFilters,
        applyAndConditionForPriceZone,
        applyAndConditionForProductBrandName,
        applyAndConditionForSubCategory,
        applyAndConditionForSupplier,
        applyAndConditionForTags,
        brandId,
        categories,
        inStockFilter,
        includeEmptyCategory,
        includeEmptyPriceZone,
        includeEmptyProductBrandName,
        includeEmptySubCategory,
        includeEmptySupplier,
        includeUntaggedSkus,
        matchLevelFilters,
        page,
        priceZones,
        productBrandNames,
        recentPriceOption,
        searchQuery: debouncedSearchTerm,
        showActiveSkus,
        showInactiveSkus,
        sortBy,
        sortDirection,
        subCategories,
        suppliers,
        tagIds,
      },
      {
        enabled,
        refetchInterval: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
      },
    );

  useEffect(() => {
    resetPage();
  }, [debouncedSearchTerm, resetPage]);

  useEffect(() => {
    if (competitorIntelligence.data?.totalCount != null) {
      if (competitorIntelligence.data.totalCount !== totalCount) {
        setTotalCount(competitorIntelligence.data.totalCount);
      }
    }
  }, [totalCount, competitorIntelligence]);

  useEffect(() => {
    if (tagsQuery.data != null && productCategoriesQuery.data != null) {
      const initialImpactedSkusOptions =
        getCategoryFiltersFromBrandCategoriesAndTags(
          tagsQuery.data,
          productCategoriesQuery.data,
        );
      if (!deepEquals(initialImpactedSkusOptions, skusFilterOptions)) {
        setSkusFilterOptions(initialImpactedSkusOptions);
      }
    }
  }, [tagsQuery, productCategoriesQuery, skusFilterOptions]);

  const handleDownload = async () => {
    t.infoToast("Requesting download, please wait a moment...");
    setDownloadLoading(true);

    try {
      const result = await apiClient.downloadCompetitorIntelligenceCsv({
        applyAndConditionForCategory,
        applyAndConditionForFilters,
        applyAndConditionForPriceZone,
        applyAndConditionForProductBrandName,
        applyAndConditionForSubCategory,
        applyAndConditionForSupplier,
        applyAndConditionForTags,
        brandId,
        categories,
        inStockFilter,
        includeEmptyCategory,
        includeEmptyPriceZone,
        includeEmptyProductBrandName,
        includeEmptySubCategory,
        includeEmptySupplier,
        includeUntaggedSkus,
        matchLevelFilters,
        page,
        priceZones,
        productBrandNames,
        recentPriceOption,
        searchQuery: debouncedSearchTerm,
        showActiveSkus,
        showInactiveSkus,
        sortBy: sortBy,
        sortDirection,
        subCategories,
        suppliers,
        tagIds,
      });
      if (result?.csv == null) {
        throw new Error("No CSV data was present.");
      }
      downloadCompetitorIntelligenceCsv(result.csv);
      t.successToast("Competitor intelligence CSV downloaded successfully.");
    } catch (err: any) {
      logClientError(err, SentryErrorEvent.ClientApiError);
      t.errorToast("There was a problem downloading the CSV data.");
    } finally {
      setDownloadLoading(false);
    }
  };

  const totalResultsCount =
    competitorIntelligence.data?.totalCount != null
      ? formatNumberRounded(competitorIntelligence.data.totalCount)
      : null;

  const showPagination =
    !competitorIntelligence.isLoading &&
    !competitorIntelligence.isError &&
    totalCount > 0;

  return (
    <div className="h-full overflow-y-scroll bg-n-0">
      <Row className="fixed z-10 w-full justify-between border-b border-n-200 bg-n-0 px-6 py-4">
        <h1 className="font-bold">Competitor Intelligence</h1>
      </Row>
      <PageContainer className="pt-14">
        <Row className="gap-2 py-5">
          <Col className="flex-grow gap-1">
            <SearchInput
              className="w-[300px]"
              onChange={(val) => {
                setSearchTerm(val);
              }}
              placeholder="Search by SKU or product name"
              value={searchText}
            />
          </Col>
          <Col>
            <Row className="min-w-[194px] items-center gap-2">
              <UserColumnVisibilityFilterComponent visibilityFilterType="competitor_intelligence" />
              <DownloadButton
                disabled={downloadLoading}
                onClick={() => void handleDownload()}
                tooltipLabel="Download competitor intelligence as CSV"
              />
            </Row>
          </Col>
        </Row>
        <div
          className={cn(
            "flex flex-row items-center justify-between gap-2",
            isExtraLarge && "flex-col items-start",
          )}
        >
          <Row className="flex-wrap gap-2">
            <Col className="gap-1">
              <LightLabel>Filter</LightLabel>
              <ProductCategoryFilter
                activeInactiveFiltersEnabled
                height={450}
                items={skusFilterOptions}
                selectTitle="Filter products"
                selectedItems={filteredSkus}
                setSelectedItems={setFilteredSkus}
                setSelectedItemsOverrideFn={setSelectedItemsOverrideFn}
              />
            </Col>

            <Col className="gap-1">
              <LightLabel>Time Range</LightLabel>
              <CompetitorIntelligencePriceTimeRangeSelect loading={false} />
            </Col>
            <Col className="gap-1">
              <LightLabel>Price</LightLabel>
              <CompetitorIntelligencePriceSelect
                availableCompetitorPriceTargetFields={
                  competitorIntelligence.data?.availableCompetitorPriceFields
                }
                className="w-44"
                loading={competitorIntelligence.isLoading}
              />
            </Col>
            <Col className="gap-1">
              <LightLabel>Match Level</LightLabel>
              <CompetitorIntelligenceMatchLevelFilter />
            </Col>
            <Col className="gap-1">
              <LightLabel>Stock</LightLabel>
              <CompetitorIntelligenceInStockFilter />
            </Col>
          </Row>
        </div>
        <Row className="my-3 max-w-[1250px] justify-between">
          <Row>
            <p className="mt-1 whitespace-nowrap text-xs">
              {totalResultsCount != null ? (
                <span>
                  {totalResultsCount} SKUs{" "}
                  {arrayNotEmpty(filteredSkus) ? "for:" : ""}
                </span>
              ) : (
                <span>Loading...</span>
              )}
            </p>
            <SelectedProductFiltersList
              applyAndConditionForCategory={applyAndConditionForCategory}
              applyAndConditionForFilters={applyAndConditionForFilters}
              applyAndConditionForPriceZone={applyAndConditionForPriceZone}
              applyAndConditionForProductBrandName={
                applyAndConditionForProductBrandName
              }
              applyAndConditionForSubCategory={applyAndConditionForSubCategory}
              applyAndConditionForSupplier={applyAndConditionForSupplier}
              applyAndConditionForTags={applyAndConditionForTags}
              className="ml-1"
              onClickRemove={handleRemoveItem}
              selectedItems={sortByProperty(filteredSkus, "label")}
              showClearFiltersButton
              toggleCondition={(item, state) => {
                useCompetitorIntelligenceFiltersStore.setState({
                  [item]: state,
                });
              }}
            />
          </Row>
        </Row>
        <div className="mt-1 pb-16 lg:relative lg:overflow-y-auto lg:pb-0">
          {competitorIntelligence.isLoading ||
          visibilityFiltersQuery.isLoading ? (
            <div>
              <TableSkeleton
                className="gap-3"
                rowClassName="h-5"
                rowNumber={25}
              />
            </div>
          ) : competitorIntelligence.isError ||
            visibilityFiltersQuery.isError ? (
            <div>Could not load competitor intelligence data...</div>
          ) : competitorIntelligence.data.totalCount === 0 ? (
            <div>
              <p>No results.</p>
            </div>
          ) : (
            <div>
              <CompetitorIntelligenceTable
                competitorNamesList={
                  competitorIntelligence.data.competitorNamesList
                }
                data={competitorIntelligence.data.data}
                refetchProducts={competitorIntelligence.refetch}
                visibilityFilters={
                  visibilityFiltersQuery.data.competitor_intelligence
                }
              />
            </div>
          )}
          {showPagination && (
            <div className="relative my-4 flex w-full items-center justify-center">
              <PaginationControls
                currentPage={page}
                disabled={competitorIntelligence.isLoading}
                pageSize={PRODUCT_CATALOG_PAGE_SIZE}
                setPage={setPage}
                totalCount={totalCount}
              />
            </div>
          )}
        </div>
      </PageContainer>
    </div>
  );
}
