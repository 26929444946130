import { useParams } from "react-router";
import TrpcClient from "src/frontend/api/TrpcClient";
import useIsBrandInitialized from "src/frontend/hooks/useIsBrandInitialized";

export default function useGetPricePlanSummaryMetricsQuery() {
  const { enabled } = useIsBrandInitialized();
  const { groupId, pricePlanId } = useParams();
  return TrpcClient.internal.getPricePlanSummaryMetrics.useQuery(
    {
      groupId: groupId != null && groupId !== "all" ? groupId : undefined,
      pricePlanId: pricePlanId!,
    },
    {
      enabled: enabled && (groupId != null || pricePlanId != null),
    },
  );
}
