import React, { useState } from "react";
import { CartesianGrid, XAxis, YAxis, Bar, BarChart } from "recharts";
import { MatchByPriceZoneType } from "src/backend/internal-api/queries/getCompetitorIntelligenceSummaryQuery";
import Card, {
  CardHeader,
  CardTitle,
  CardContent,
} from "src/frontend/components/ui/CardWithContent";
import { Label } from "src/frontend/components/ui/Label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/frontend/components/ui/Select";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "src/frontend/components/ui/chart";
import toTitleCase from "src/shared/utils/strings/toTitleCase";

/* eslint-disable sort-keys-fix/sort-keys-fix */
const chartConfig = {
  exactMatch: {
    color: "hsl(var(--chart-1))",
    label: "Exact Match",
  },
  differentBrand: {
    color: "hsl(var(--chart-2))",
    label: "Different Brand",
  },
  differentFlavor: {
    color: "hsl(var(--chart-3))",
    label: "Different Flavor",
  },
  differentSize: {
    color: "hsl(var(--chart-4))",
    label: "Different Size",
  },
  differentOrganicness: {
    color: "hsl(var(--chart-5))",
    label: "Different Organicness",
  },
  differentVariety: {
    color: "hsl(var(--chart-6))",
    label: "Different Variety",
  },
  differentFlavorAndSize: {
    color: "hsl(var(--chart-7))",
    label: "Different Flavor and Size",
  },
  similarProduct: {
    color: "hsl(var(--chart-8))",
    label: "Similar Product",
  },
  noMatch: {
    color: "hsl(var(--chart-9))",
    label: "No Match",
  },
} satisfies ChartConfig;

export type CompetitorMatchData = {
  differentBrand: number;
  differentFlavor: number;
  differentFlavorAndSize: number;
  differentOrganic: number;
  differentSize: number;
  differentVariety: number;
  exactMatch: number;
  noMatch: number;
  prizeZone: string;
  similarProduct: number;
};

export type MatchKey = Exclude<keyof CompetitorMatchData, "competitorName">;

const CompetitorIntelligenceMatchByCompetitorTable = ({
  competitors,
  matchByPrizeZoneData,
}: {
  competitors: string[];
  matchByPrizeZoneData: MatchByPriceZoneType[];
}) => {
  const [competitorName, setCompetitorName] = useState<string>(
    competitors[0] ?? "",
  );
  const priceZones = matchByPrizeZoneData.find(
    (data) => data.competitorName === competitorName,
  )?.priceZones;
  const matchByPrizeZoneChartData = priceZones?.map((data) => ({
    priceZone: data.priceZone,
    ...data.matchCounts,
  }));

  return (
    <Card className="w-full">
      <CardHeader className="flex-row items-center justify-between p-0 pt-6">
        <CardTitle>Match by Prize Zone</CardTitle>
        <div className="flex items-center gap-2">
          <Label htmlFor="competitor-summary-competitor">Competitor:</Label>
          <Select
            onValueChange={(value) => setCompetitorName(value)}
            value={competitorName}
          >
            <SelectTrigger
              className="w-full"
              id="competitor-summary-competitor"
            >
              <SelectValue placeholder="Select competitor" />
            </SelectTrigger>
            <SelectContent>
              {competitors.map((competitor) => (
                <SelectItem key={competitor} value={competitor}>
                  {competitor
                    .split(/(?<=\.|\/)|\s+/)
                    .map((word) => toTitleCase(word))
                    .join(" ")}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </CardHeader>
      <CardContent className="p-0 pt-6">
        <ChartContainer
          className="aspect-[17/10] w-full 2xl:aspect-video"
          config={chartConfig}
        >
          <BarChart
            accessibilityLayer
            data={matchByPrizeZoneChartData}
            maxBarSize={48}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              angle={45}
              axisLine={false}
              dataKey="priceZone"
              height={55}
              interval={0}
              tick={(props) => {
                const { payload, x, y } = props;
                const words = (payload.value as string).split(/(?<=\.|\/)|\s+/);
                const maxLength = 12;
                let currentLength = 0;
                let dy = 0;
                let dx = 0;
                return words.length === 1 ||
                  words.every((word) => word.length === 1) ? (
                  <text
                    fill="#666"
                    fontSize={10}
                    textAnchor="middle"
                    x={x}
                    y={y}
                  >
                    {words}
                  </text>
                ) : (
                  <g transform={`translate(${x - 35},${y + 30}) rotate(-45)`}>
                    {words.map((word, index, arr) => {
                      const previousWord = index > 0 ? arr[index - 1] : null;
                      const containsI =
                        previousWord &&
                        previousWord.toLowerCase().includes("i");
                      if (index === 0) {
                        currentLength += word.length + 1; // +1 for space
                      } else if (currentLength + word.length <= maxLength) {
                        currentLength += word.length + 1; // +1 for space
                        dx +=
                          index > 0
                            ? previousWord!.length * (containsI ? 4.5 : 6)
                            : 0;
                      } else if (currentLength + word.length > maxLength) {
                        dy += 12;
                        dx = 0;
                        currentLength = 0;
                      }
                      return (
                        <text
                          dx={dx}
                          dy={dy}
                          fill="#666"
                          fontSize={9}
                          key={index}
                          textAnchor="start"
                          x={0}
                          y={0}
                        >
                          {toTitleCase(word)}
                        </text>
                      );
                    })}
                  </g>
                );
              }}
              tickLine={false}
              tickMargin={10}
            />
            <YAxis
              axisLine={false}
              label={{
                angle: -90,
                dx: -30,
                style: { fontSize: 12 },
                value: "Number of SKUs",
              }}
              tickCount={3}
              tickLine={false}
              tickMargin={8}
            />
            <ChartTooltip
              content={
                <ChartTooltipContent className="flex gap-2 p-2" hideLabel />
              }
            />
            {Object.keys(chartConfig).map((bar, i, arr) => {
              let radius: [number, number, number, number] | undefined;
              const isFirst = i === 0;

              const isLast = i === arr.length - 1;

              if (isFirst && isLast) {
                radius = [8, 8, 8, 8];
              } else if (isFirst) {
                radius = [0, 0, 8, 8];
              } else if (isLast) {
                radius = [8, 8, 0, 0];
              }
              return (
                <Bar
                  dataKey={bar}
                  fill={`var(--color-${bar})`}
                  key={bar}
                  radius={radius}
                  stackId="a"
                />
              );
            })}
          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
};

export default CompetitorIntelligenceMatchByCompetitorTable;
