import { useQueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@trpc/react-query";
import { useState } from "react";
import { useNavigate } from "react-router";
import TrpcClient from "src/frontend/api/TrpcClient";
import Code from "src/frontend/components/Code";
import Checkbox from "src/frontend/components/ui/Checkbox";
import { Modal } from "src/frontend/components/ui/Modal";
import useToast from "src/frontend/components/ui/useToast";
import usePricePlanQuery from "src/frontend/hooks/queries/usePricePlanQuery";
import useIsLucaAdmin from "src/frontend/hooks/useIsLucaAdmin";
import usePricePlanTab from "src/frontend/hooks/usePricePlanTab";
import usePricePlanStore from "src/frontend/stores/usePricePlanStore";
import isDraftsTab from "src/frontend/utils/isDraftsTab";
import { PricePlanResponseType } from "src/shared/trpc/common/PricePlanResponse";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import invariant from "tiny-invariant";

type DeletePricePlanModalProps = {
  modalOpen: boolean;
  pricePlan: MaybeNull<PricePlanResponseType>;
  setModalOpen: (open: boolean) => void;
};

export default function DeletePricePlanModal({
  modalOpen,
  pricePlan,
  setModalOpen,
}: DeletePricePlanModalProps) {
  const navigate = useNavigate();
  const t = useToast();
  const tab = usePricePlanTab();
  const isLucaAdmin = useIsLucaAdmin();
  const [deleteAll, setDeleteAll] = useState(false);
  const queryClient = useQueryClient();
  const queryKey = getQueryKey(TrpcClient.internal.getPricePlans);

  const pricePlansQuery = usePricePlanQuery();
  const deletePricePlanMutation =
    TrpcClient.internal.deletePricePlan.useMutation({
      onError: () => {
        t.errorToast(`Failed to delete price plan${deleteAll ? "s" : ""}.`);
      },
      onSettled: () => void queryClient.invalidateQueries(queryKey),
      onSuccess: () => {
        usePricePlanStore.setState({
          pricePlanId: undefined,
          tab: undefined,
        });
        setModalOpen(false);
        t.successToast(
          `Price plan${deleteAll ? "s" : ""} deleted successfully.`,
        );
        navigate(`/pricing/${isDraftsTab(tab) ? "drafts" : "launched"}`);
      },
    });

  const pricePlanId = pricePlan?.id;

  const handleDelete = () => {
    invariant(pricePlanId != null);
    deletePricePlanMutation.mutate({
      deleteAll,
      pricePlanId,
    });
  };

  if (pricePlanId == null || pricePlansQuery.isLoading || tab == null) {
    return null;
  }

  return (
    <Modal
      confirmText={deleteAll ? "Delete ALL" : "Delete"}
      dialogDescription={
        <div className="flex flex-col gap-2">
          <p>The current price plan will be deleted. This cannot be undone.</p>
          {isLucaAdmin && (
            <Checkbox
              checked={deleteAll}
              disabled={deletePricePlanMutation.isLoading}
              id="delete-all-price-plans-checkbox"
              label={
                <p>
                  Delete ALL <Code>{tab.toUpperCase()}</Code> price plans,
                  including this one.
                </p>
              }
              onCheckedChange={(_, checked) => setDeleteAll(checked)}
            />
          )}
        </div>
      }
      dialogTitle="Delete Price Plan"
      loading={deletePricePlanMutation.isLoading}
      onConfirm={handleDelete}
      onOpenChange={(open) => {
        if (!open) {
          setDeleteAll(false);
        }
        setModalOpen(open);
      }}
      open={modalOpen}
    />
  );
}
