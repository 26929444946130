import { price_plan_status } from "@prisma/client";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import PageContainer from "src/frontend/components/PageContainer";
import Row from "src/frontend/components/Row";
import {
  Table,
  TableDataCell,
  TableHeader,
  TableHeaderCell,
} from "src/frontend/components/TableComponents";
import SearchInputV2 from "src/frontend/components/design-system/SearchInputV2";
import { Badge } from "src/frontend/components/ui/Badge";
import Spinner from "src/frontend/components/ui/Spinner";
import { cn } from "src/frontend/components/ui/utils";
import usePricePlansByStatusQuery from "src/frontend/hooks/queries/usePricePlansByStatusQuery";
import PricePlansDropdownMenu from "src/frontend/pages/pricing/PricePlansDropdownMenu";
import { PricePlanStatusType } from "src/shared/trpc/queries/getPricePlansQuerySchema";
import formatDateWithTime from "src/shared/utils/dates/formatDateWithTime";

function statusToTab(status: price_plan_status) {
  switch (status) {
    case "DRAFT":
      return "draft";
    case "PREVIEW":
      return "preview";
    case "INTERNAL_DRAFT":
      return "internal";
    case "CURRENT":
      return "current";
    case "COMPLETED":
      return "past";
    default:
      null;
  }
}

function statusToLabel(status: price_plan_status | "GENERATING") {
  switch (status) {
    case "DRAFT":
      return "Draft";
    case "PREVIEW":
      return "Preview";
    case "INTERNAL_DRAFT":
      return "Internal Draft";
    case "CURRENT":
      return "Current";
    case "COMPLETED":
      return "Past";
    case "GENERATING":
      return "Generating";
    default:
      null;
  }
}

const STATUS_COLORS: Record<
  PricePlanStatusType | "GENERATING",
  { bg: string; color: string }
> = {
  COMPLETED: { bg: "bg-blue-100", color: "text-blue-600" },
  CURRENT: { bg: "bg-lime-100", color: "text-lime-700" },
  DRAFT: { bg: "bg-violet-100", color: "text-violet-700" },
  GENERATING: { bg: "bg-orange-100", color: "text-orange-600" },
  INTERNAL_DRAFT: { bg: "bg-cyan-100", color: "text-cyan-600" },
  PREVIEW: { bg: "bg-amber-100", color: "text-amber-500" },
};

const StatusBadge = ({
  status,
}: {
  status: price_plan_status | "GENERATING";
}) => {
  if (!(status in STATUS_COLORS)) {
    return null;
  }
  // @ts-ignore
  const color = STATUS_COLORS[status];
  return (
    <Badge
      className={cn("inline h-full !rounded-md text-sm", color.bg, color.color)}
      variant="success"
    >
      {statusToLabel(status)}
    </Badge>
  );
};

type PricePlansProps = {
  status: PricePlanStatusType[];
};

const PricePlans = ({ status }: PricePlansProps) => {
  const [searchValue, setSearchValue] = useState("");
  const pricePlanDraftsQuery = usePricePlansByStatusQuery(status, searchValue);
  const navigate = useNavigate();
  const isDrafts = status.includes("DRAFT");

  return (
    <PageContainer>
      <Row className="my-4">
        <SearchInputV2 onChange={setSearchValue} value={searchValue} />
      </Row>
      <div className="relative">
        <Table className="border-y-0">
          <TableHeader className="border-b">
            <tr>
              <TableHeaderCell>Price Plan Name</TableHeaderCell>
              <TableHeaderCell>Strategy</TableHeaderCell>
              <TableHeaderCell>Date Created</TableHeaderCell>
              <TableHeaderCell>Status</TableHeaderCell>
              <TableHeaderCell>Actions</TableHeaderCell>
            </tr>
          </TableHeader>
          {pricePlanDraftsQuery.data?.map((plan) => (
            <tr
              className="border-b border-n-100 hover:cursor-pointer [&:hover>*]:bg-n-100 "
              key={plan.id}
              onClick={() =>
                navigate(`/pricing/${statusToTab(plan.status)}/${plan.id}`)
              }
            >
              <TableDataCell maxWidth="lg:w-[70ch] w-[25ch]">
                {plan.title}
              </TableDataCell>
              <TableDataCell
                maxWidth="w-[25ch]"
                onClick={(e) => e.stopPropagation()}
              >
                <Link
                  className="text-blue-500 underline"
                  to={`/company-settings/strategy/${plan.pricing_strategy_id}`}
                >
                  {plan.pricing_strategy_name}
                </Link>
              </TableDataCell>
              <TableDataCell>
                {formatDateWithTime(plan.created_at, "MM/DD/YY, h:mm A")}
              </TableDataCell>
              <TableDataCell>
                <StatusBadge
                  status={
                    plan.generation_in_progress ? "GENERATING" : plan.status
                  }
                />
              </TableDataCell>
              <TableDataCell onClick={(e) => e.stopPropagation()}>
                <PricePlansDropdownMenu
                  isDeleteEnabled={isDrafts || plan.status === "CURRENT"}
                  pricePlan={plan}
                />
              </TableDataCell>
            </tr>
          ))}
          {pricePlanDraftsQuery.isLoading && (
            <div className="absolute left-0 top-0 z-10 flex h-full min-h-[200px] w-full items-center justify-center rounded-3xl bg-white bg-opacity-80 dark:bg-black dark:bg-opacity-20">
              <Spinner />
            </div>
          )}
          {pricePlanDraftsQuery.isError && <p>Failed to load price plans...</p>}
        </Table>
      </div>
    </PageContainer>
  );
};

export default PricePlans;
