import BRAND_ID_TO_NAME from "src/shared/constants/brands/brandIdToName.json";
import BRAND_NAME_TO_NAME from "src/shared/constants/brands/brandName.json";
import BRAND_NAME_TO_ID from "src/shared/constants/brands/brandNameToId.json";
import BRAND_NAME_TO_PUBLIC_ID from "src/shared/constants/brands/brandNameToPublicId.json";

export type BrandName = keyof typeof BRAND_NAME_TO_NAME;

export type BrandId = keyof typeof BRAND_ID_TO_NAME;

export {
  BRAND_ID_TO_NAME,
  BRAND_NAME_TO_ID,
  BRAND_NAME_TO_NAME,
  BRAND_NAME_TO_PUBLIC_ID,
};
