import { useState } from "react";
import Button from "src/frontend/components/ui/Button";
import { Modal } from "src/frontend/components/ui/Modal";

export default function RevertExperimentsModal({
  numberOfSelectedExperimentsThatCanBeReverted,
  onConfirm,
}: {
  numberOfSelectedExperimentsThatCanBeReverted: number;
  onConfirm: () => void;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Modal
      dialogDescription="This will publish the “previous price” for the selected SKUs. Are you sure you want to publish these changes to your store?"
      dialogTitle="Revert Changes"
      loading={false}
      onConfirm={() => {
        onConfirm();
        setIsModalOpen(false);
      }}
      onOpenChange={setIsModalOpen}
      open={isModalOpen}
      trigger={
        <Button
          disabled={numberOfSelectedExperimentsThatCanBeReverted === 0}
          size="sm"
          variant="outline"
        >
          Revert{" "}
          {numberOfSelectedExperimentsThatCanBeReverted > 0 &&
            numberOfSelectedExperimentsThatCanBeReverted}
        </Button>
      }
    />
  );
}
