import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { updateCurrentSessionBrandId } from "src/frontend/components/misc/BrandIdSyncComponent";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/frontend/components/ui/Select";
import useGetUserBrandsQuery from "src/frontend/hooks/queries/useGetUserBrandsQuery";
import useBrandSelectStore from "src/frontend/stores/useBrandSelectStore";
import useBrandStore, { toBrandStore } from "src/frontend/stores/useBrandStore";
import useCompetitorIntelligenceFiltersStore from "src/frontend/stores/useCompetitorIntelligenceFiltersStore";
import useLucaAdminSettingsStore from "src/frontend/stores/useLucaAdminSettingsStore";
import usePricePlanStore from "src/frontend/stores/usePricePlanStore";
import useProductCatalogFiltersStore from "src/frontend/stores/useProductCatalogFilters";
import findBrandById from "src/frontend/utils/findBrandById";
import stringToColor from "src/frontend/utils/stringToColor";
import arrayEmpty from "src/shared/utils/arrays/arrayEmpty";
import sortByProperty from "src/shared/utils/arrays/sortByProperty";
import deepEquals from "src/shared/utils/objects/deepEquals";

const LOADING_KEY = "loading";

export default function BrandSelect() {
  const location = useLocation();
  const navigate = useNavigate();
  const viewAsCompanyUser = useLucaAdminSettingsStore(
    (state) => state.view_as_company_user,
  );
  const isBrandSelectOpen = useBrandSelectStore();
  const brandsQuery = useGetUserBrandsQuery();
  const brandStore = useBrandStore((state) => state);
  const brandId = brandStore.brand_id;
  const queryClient = useQueryClient();

  useEffect(() => {
    if (brandsQuery.data == null || arrayEmpty(brandsQuery.data)) {
      return;
    }

    if (!brandId) {
      useBrandStore.setState(toBrandStore(brandsQuery.data[0]));
    }
  }, [brandsQuery.data, brandId]);

  useEffect(() => {
    if (
      brandStore.brand_id == null ||
      brandsQuery.data == null ||
      arrayEmpty(brandsQuery.data)
    ) {
      return;
    }

    const brand = brandsQuery.data.find(
      (brand) => brand.brand_id === brandStore.brand_id,
    );
    if (brand != null) {
      const brandStoreData = toBrandStore(brand);
      if (!deepEquals(brandStore, brandStoreData)) {
        useBrandStore.setState(() => brandStoreData, true);
      }
    }
  }, [brandsQuery.data, brandStore]);

  const isLoading = brandsQuery.isLoading || brandsQuery.data?.length === 0;

  let sortedBrands = sortByProperty(brandsQuery.data ?? [], "brand_name");
  if (viewAsCompanyUser) {
    sortedBrands = sortedBrands.filter(
      (brand) => brand.company_id === brandStore.company_id,
    );
  }

  const resetLocalState = () => {
    void queryClient.clear();
    usePricePlanStore.setState({ customerSegment: null });
    useProductCatalogFiltersStore.getState().reset();
    useCompetitorIntelligenceFiltersStore.getState().reset();
  };

  const handleSelectBrand = (brandId: string) => {
    const brandStore = findBrandById(brandsQuery.data ?? [], brandId);
    useBrandStore.setState(() => toBrandStore(brandStore));
    updateCurrentSessionBrandId(brandId);
    resetLocalState();
    if (location.pathname.includes("pricing")) {
      usePricePlanStore.setState({
        pricePlanId: undefined,
        tab: undefined,
      });
      navigate("/pricing/drafts");
    } else if (location.pathname.includes("strategy")) {
      navigate("/company-settings/strategy");
    }
  };

  return (
    <Select
      disabled={isLoading}
      onOpenChange={(open) => useBrandSelectStore.setState(open)}
      onValueChange={handleSelectBrand}
      open={isBrandSelectOpen}
      value={isLoading ? LOADING_KEY : brandId}
    >
      <SelectTrigger>
        <SelectValue placeholder="Select a brand" />
      </SelectTrigger>
      <SelectContent
        className="max-h-[625px] overflow-y-auto"
        onCloseAutoFocus={(e) => e.preventDefault()}
      >
        {isLoading && <SelectItem value={LOADING_KEY}>Loading...</SelectItem>}
        {sortedBrands.map((brand, i) => {
          const isSelected = brand.brand_id === brandId;
          const { backgroundColor, textColor } = stringToColor(
            brand.brand_name!,
          );
          return (
            <SelectItem key={brand.brand_id + i} value={brand.brand_id}>
              <div className="flex items-center gap-2">
                {!isBrandSelectOpen && isSelected && (
                  <>
                    <div
                      className="flex h-6 min-w-[24px] items-center justify-center rounded-full"
                      style={{ backgroundColor }}
                    >
                      <p
                        className="text-lg font-bold"
                        style={{ color: textColor }}
                      >
                        {brand.brand_name?.charAt(0)}
                      </p>
                    </div>
                    <p className="text-lg font-bold">{brand.brand_name}</p>
                  </>
                )}
                {isBrandSelectOpen && <p>{brand.brand_name}</p>}
              </div>
            </SelectItem>
          );
        })}
      </SelectContent>
    </Select>
  );
}
