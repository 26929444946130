import {
  isWeightUnitOfMeasurement,
  isCountUnitOfMeasurement,
  getUnitOfMeasurement,
} from "src/shared/constants/UnitOfMeasurementMapping";
import MaybeDecimal from "src/shared/types/maybe/MaybeDecimal";
import MaybeNumber from "src/shared/types/maybe/MaybeNumber";
import MaybeString from "src/shared/types/maybe/MaybeString";

export type ProductUOMType = {
  count: MaybeDecimal | MaybeNumber;
  weight: MaybeDecimal | MaybeNumber;
  weight_unit: MaybeString;
};

export default function calculateUOMMatchPrice({
  competitor,
  product,
  reference_price,
}: {
  competitor: {
    quantity: MaybeNumber;
    secondary_quantity: MaybeNumber;
    secondary_unit_of_measurement: MaybeString;
    unit_of_measurement: MaybeString;
  };
  product: ProductUOMType;
  reference_price: MaybeNumber;
}): MaybeNumber {
  if (
    reference_price != null &&
    competitor.quantity != null &&
    competitor.secondary_quantity != null &&
    ((isWeightUnitOfMeasurement(competitor.unit_of_measurement) &&
      isCountUnitOfMeasurement(competitor.secondary_unit_of_measurement)) ||
      (isWeightUnitOfMeasurement(competitor.secondary_unit_of_measurement) &&
        isCountUnitOfMeasurement(competitor.unit_of_measurement))) &&
    product.weight != null &&
    isWeightUnitOfMeasurement(product.weight_unit) &&
    (getUnitOfMeasurement(competitor.unit_of_measurement) ===
      getUnitOfMeasurement(product.weight_unit) ||
      getUnitOfMeasurement(competitor.secondary_unit_of_measurement) ===
        getUnitOfMeasurement(product.weight_unit)) &&
    product.count != null
  ) {
    const weight: number =
      typeof product.weight === "object" && "toNumber" in product.weight
        ? product.weight.toNumber()
        : product.weight;
    const count: number =
      typeof product.count === "object" && "toNumber" in product.count
        ? product.count.toNumber()
        : product.count;
    return (
      (reference_price /
        (competitor.quantity * competitor.secondary_quantity)) *
      (weight * count)
    );
  }
  return null;
}
