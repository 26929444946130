import { Link } from "react-router-dom";
import Bold from "src/frontend/components/Bold";
import Header from "src/frontend/components/Header";
import PageContainer from "src/frontend/components/PageContainer";
import PageTitle from "src/frontend/components/PageTitle";
import Row from "src/frontend/components/Row";
import Button from "src/frontend/components/ui/Button";
import CardWithContent from "src/frontend/components/ui/CardWithContent";
import usePriceParityGroupStore from "src/frontend/stores/usePriceParityGroupStore";

export default function CreateProductRelationship() {
  const state = usePriceParityGroupStore();
  return (
    <PageContainer enforceMaxWidth>
      <Header
        backTo="/company-settings/relationships"
        leftNode={<PageTitle>Select Relationship Type</PageTitle>}
      />
      <CardWithContent className="w-fit" title="Select Price Relationship">
        <Row className="gap-4">
          <Link
            onClick={() => state.reset()}
            to="/company-settings/relationships/price-parity/create"
          >
            <Button className="h-52 w-80 items-start p-4" variant="basic">
              <div className="text-left">
                <Bold className="text-base">Price Parity</Bold>
                <p className="mt-2">
                  For a group of SKUs that need to share the same price.
                </p>
                <p className="my-2 italic">For example:</p>
                <ul>
                  <li>• Flavors of La Croix</li>
                  <li>• Colors of the same t-shirt</li>
                </ul>
              </div>
            </Button>
          </Link>
          <Button
            className="h-52 w-80 items-start p-4"
            disabled
            variant="basic"
          >
            <div className="text-left">
              <Bold className="text-base">Price Delta (coming soon)</Bold>
              <p className="mt-2">
                For a group of SKUs that need to share a price delta.
              </p>
              <p className="my-2 italic">For example:</p>
              <ul>
                <li>• Medium t-shirts are 5% more expensive than small</li>
              </ul>
            </div>
          </Button>
          <Button
            className="h-52 w-80 items-start p-4"
            disabled
            variant="basic"
          >
            <div className="text-left">
              <Bold className="text-base">Price Bundle (coming soon)</Bold>
              <p className="mt-2">
                For a group of SKUs that have a price relationship with one
                another.
              </p>
              <p className="my-2 italic">For example:</p>
              <ul>
                <li>
                  • The price of a pajama-set should be less than the price of a
                  top and bottom purchased separately
                </li>
              </ul>
            </div>
          </Button>
        </Row>
      </CardWithContent>
    </PageContainer>
  );
}
