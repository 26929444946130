import { BaseResultsMetricsWithMargin } from "src/shared/trpc/common/BaseResultsMetricsWithMargin";
import { z } from "zod";

export const GroupResultsMetrics = BaseResultsMetricsWithMargin.merge(
  z.object({
    created_at: z.date().nullable(),
    elasticity: z.number().nullable(),
    is_draft: z.boolean(),
    profit_lower_bound: z.number().nullable(),
    profit_upper_bound: z.number().nullable(),
    revenue_lower_bound: z.number().nullable(),
    revenue_upper_bound: z.number().nullable(),
    units_sold_lower_bound: z.number().nullable(),
    units_sold_upper_bound: z.number().nullable(),
  }),
);

export type GroupResultsMetricsResponseType = z.infer<
  typeof GroupResultsMetrics
>;
