import Col from "src/frontend/components/Col";
import { PAGE_CONTAINER_ID } from "src/frontend/components/PageContainer";
import { Skeleton } from "src/frontend/components/ui/Skeleton";
import usePricePlanGroupsQuery from "src/frontend/hooks/queries/usePricePlanGroupsQuery";
import PricePlanGroupCard from "src/frontend/pages/pricing/PricePlanGroupCard";
import useLocationHistoryStore from "src/frontend/stores/useLocationHistoryStore";
import range from "src/shared/utils/arrays/range";

export default function PricePlanGroups() {
  const pricePlanGroupsQuery = usePricePlanGroupsQuery();
  const setScrollPosition = () => {
    const scrollContainer = document.getElementById(PAGE_CONTAINER_ID);
    const pricePlanScrollPosition = scrollContainer?.scrollTop ?? null;
    useLocationHistoryStore.setState({ pricePlanScrollPosition });
  };
  return (
    <Col className="pb-8">
      {pricePlanGroupsQuery.isLoading ? (
        <Col className="mb-16 gap-4">
          {range(10).map((_, i) => {
            return <Skeleton className="h-[140px] w-full" key={i} />;
          })}
        </Col>
      ) : !pricePlanGroupsQuery.data?.length ? (
        <Col className="gap-1">
          <p className="flex flex-row text-lg font-bold">No groups found.</p>
        </Col>
      ) : (
        <div className="grid grid-cols-[3fr,2fr,6fr,2fr,72px] gap-y-8">
          <div className="contents text-sm font-bold [&>*]:sticky [&>*]:top-0 [&>*]:border-b [&>*]:bg-n-0 [&>*]:py-7">
            <button>Group</button>
            <button>Avg. Price change %</button>
            <button>Opportunity / Impact</button>
            <button>SKUs</button>
            <span />
          </div>
          {pricePlanGroupsQuery.data.map((opportunity, index) => (
            <PricePlanGroupCard
              group={opportunity}
              key={index}
              onClick={setScrollPosition}
            />
          ))}
        </div>
      )}
    </Col>
  );
}
