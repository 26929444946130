import React from "react";
import { Pie, Label, PieChart } from "recharts";
import { MatchBySkuType } from "src/backend/internal-api/queries/getCompetitorIntelligenceSummaryQuery";
import Card, {
  CardContent,
  CardHeader,
  CardTitle,
} from "src/frontend/components/ui/CardWithContent";
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "src/frontend/components/ui/chart";

const chartConfig = {
  noMatches: {
    color: "hsl(var(--chart-9))",
    label: "No Matches",
  },
  oneMatch: {
    color: "#E0F2FE",
    label: "1 Match",
  },
  skus: {
    label: "Skus",
  },
  threeOrMoreMatches: {
    color: "#2563EB",
    label: "3+ Matches",
  },
  twoMatches: {
    color: "#60A5FA",
    label: "2 Matches",
  },
} satisfies ChartConfig;

function CompetitorIntelligenceMatchBySkuTable({
  matchBySku,
}: {
  matchBySku: MatchBySkuType;
}) {
  const data = [
    {
      fill: "var(--color-noMatches)",
      name: "noMatches",
      value: matchBySku.noMatches,
    },
    {
      fill: "var(--color-oneMatch)",
      name: "oneMatch",
      value: matchBySku.oneMatch,
    },
    {
      fill: "var(--color-twoMatches)",
      name: "twoMatches",
      value: matchBySku.twoMatches,
    },
    {
      fill: "var(--color-threeOrMoreMatches)",
      name: "threeOrMoreMatches",
      value: matchBySku.threeOrMoreMatches,
    },
  ];
  return (
    <Card className="w-full">
      <CardHeader className="p-0 pt-9">
        <CardTitle>Matches by SKU</CardTitle>
      </CardHeader>
      <CardContent className="flex-1 pb-0 pt-6">
        <ChartContainer
          className="mx-auto aspect-square max-h-[250px]"
          config={chartConfig}
        >
          <PieChart>
            <ChartLegend content={<ChartLegendContent />} />
            <ChartTooltip
              content={
                <ChartTooltipContent hideLabel total={matchBySku.totalSkus} />
              }
              cursor={false}
            />
            <Pie
              data={data}
              dataKey="value"
              innerRadius={60}
              nameKey="name"
              strokeWidth={5}
            >
              <Label
                content={({ viewBox }) => {
                  if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                    return (
                      <text
                        dominantBaseline="middle"
                        textAnchor="middle"
                        x={viewBox.cx}
                        y={viewBox.cy}
                      >
                        <tspan
                          className="fill-muted-foreground"
                          x={viewBox.cx}
                          y={(viewBox.cy ?? 0) + 24}
                        >
                          Total SKUs
                        </tspan>
                        <tspan
                          className="fill-foreground text-3xl font-bold"
                          x={viewBox.cx}
                          y={viewBox.cy}
                        >
                          {matchBySku.totalSkus.toLocaleString()}
                        </tspan>
                      </text>
                    );
                  }
                }}
              />
            </Pie>
          </PieChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}

export default CompetitorIntelligenceMatchBySkuTable;
