import usePricePlanStore from "src/frontend/stores/usePricePlanStore";
import { BaseResultsMetricsType } from "src/shared/trpc/common/BaseResultsMetrics";
import {
  GetPricePlanGroupsQueryResponseType,
  GroupOpportunityMetricsType,
} from "src/shared/trpc/queries/getPricePlanGroupsQuerySchema";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import formatCurrencyDelta from "src/shared/utils/formatCurrencyDelta";
import formatPercentageDirect from "src/shared/utils/numbers/formatPercentageDirect";

function getOpportunityLabel(
  opportunity_metrics: MaybeNull<GroupOpportunityMetricsType>,
): MaybeNull<string> {
  if (opportunity_metrics == null) {
    return null;
  }

  const profit = opportunity_metrics.profit_lift;
  const profitFormatted = formatCurrencyDelta(profit);
  const profitLift = opportunity_metrics.profit_lift_pct;
  const profitPct = formatPercentageDirect(profitLift);

  const revenue = opportunity_metrics.revenue_lift;
  const revenueFormatted = formatCurrencyDelta(revenue);
  const revenueLift = opportunity_metrics.revenue_lift_pct;
  const revenuePct = formatPercentageDirect(revenueLift);

  return `Revenue: ${revenueFormatted} (${revenuePct}) | Profit: ${profitFormatted} (${profitPct})`;
}

function getResultsLabel(
  result_metrics: MaybeNull<BaseResultsMetricsType>,
): MaybeNull<string> {
  if (result_metrics == null) {
    return null;
  }

  const profit = result_metrics.profit_change;
  const profitFormatted = formatCurrencyDelta(profit);
  const profitLift = result_metrics.profit_change_percent;
  const profitPct = formatPercentageDirect(profitLift);

  const revenue = result_metrics.revenue_change;
  const revenueFormatted = formatCurrencyDelta(revenue);
  const revenueLift = result_metrics.revenue_change_percent;
  const revenuePct = formatPercentageDirect(revenueLift);

  return `Revenue: ${revenueFormatted} (${revenuePct}) | Profit: ${profitFormatted} (${profitPct})`;
}

type PricePlanGroupCardProps = {
  group: GetPricePlanGroupsQueryResponseType;
};

export default function GroupSummaryLabel({ group }: PricePlanGroupCardProps) {
  const { customerSegment } = usePricePlanStore();
  const resultsSegment = group.result_metrics.find(
    (val) => val.customer_segment === customerSegment,
  );
  if (resultsSegment != null) {
    return (
      <p>
        <span className="px-2 font-semibold">Impact:</span>
        {getResultsLabel(resultsSegment)}
      </p>
    );
  }

  const opportunitySegment = group.opportunity_metrics.find(
    (val) => val.customer_segment === customerSegment,
  );
  if (opportunitySegment != null) {
    return (
      <p>
        <span className="px-2 font-semibold">Opportunity:</span>
        {getOpportunityLabel(opportunitySegment)}
      </p>
    );
  }

  return <span />;
}
