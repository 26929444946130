import { ChevronDown } from "lucide-react";
import { useState } from "react";
import TrpcClient from "src/frontend/api/TrpcClient";
import Row from "src/frontend/components/Row";
import Button from "src/frontend/components/ui/Button";
import Checkbox from "src/frontend/components/ui/Checkbox";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "src/frontend/components/ui/DropdownMenu";
import useToast from "src/frontend/components/ui/useToast";
import USER_COLUMN_VISIBILITY_CONFIG, {
  VisibilityConfigType,
} from "src/frontend/constants/UserColumnVisibilityConfig";
import useVisibilityColumnsToHideForBrand from "src/frontend/hooks/columnVisibilityHooks/useVisibilityColumnsToHideForBrand";
import useUserColumnVisibilityFiltersQuery from "src/frontend/hooks/queries/useUserColumnVisibilityFiltersQuery";
import useFlags from "src/frontend/hooks/useFlags";
import formatGenericEnumLabel from "src/frontend/utils/formatGenericEnumLabel";
import {
  VisibilityFilterKeyType,
  VisibilityFiltersUnion,
} from "src/shared/trpc/common/UserCommonVisibilityFilters";
import arrayEmpty from "src/shared/utils/arrays/arrayEmpty";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";
import filterMaybeValuesFromObject from "src/shared/utils/objects/filterMaybeValuesFromObject";
import invariant from "tiny-invariant";

function getDropdownConfig(
  visibilityFilterType: VisibilityFilterKeyType,
): VisibilityConfigType {
  switch (visibilityFilterType) {
    case "competitor_intelligence":
      return USER_COLUMN_VISIBILITY_CONFIG.CompetitorIntelligenceConfigMap;
    case "price_plan":
      return USER_COLUMN_VISIBILITY_CONFIG.PricePlanConfigMap;
    case "product_catalog":
      return USER_COLUMN_VISIBILITY_CONFIG.ProductCatalogConfigMap;
    default:
      return assertUnreachable(visibilityFilterType);
  }
}

type UserColumnVisibilityFilterComponentProps = {
  visibilityFilterType: VisibilityFilterKeyType;
};

export default function UserColumnVisibilityFilterComponent({
  visibilityFilterType,
}: UserColumnVisibilityFilterComponentProps) {
  const t = useToast();
  const { pricePlanPushChangesEnabled } = useFlags();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [localFilters, setLocalFilters] = useState<
    Partial<VisibilityFiltersUnion>
  >({});
  const visibilityFiltersQuery = useUserColumnVisibilityFiltersQuery();
  const updateUserColumnVisibilityFiltersMutation =
    TrpcClient.internal.updateUserColumnVisibilityFilters.useMutation();

  const onSuccess = async () => {
    await visibilityFiltersQuery.refetch();
    setOpen(false);
    setLoading(false);
    t.successToast("View settings updated successfully.");
  };

  const { data } = visibilityFiltersQuery;
  const columns = data?.[visibilityFilterType];
  const columnsToHide =
    useVisibilityColumnsToHideForBrand(visibilityFilterType);

  const handleSaveFilters = () => {
    setLoading(true);
    updateUserColumnVisibilityFiltersMutation.mutate(
      {
        filters: localFilters,
        visibility_key: visibilityFilterType,
      },
      {
        onError: () => {
          setLoading(false);
          t.errorToast("Failed to update view settings");
        },
        onSuccess: () => void onSuccess(),
      },
    );
  };

  const config = getDropdownConfig(visibilityFilterType);

  return (
    <DropdownMenu
      onOpenChange={(val) => {
        setLocalFilters({});
        setOpen(val);
      }}
      open={open}
    >
      <DropdownMenuTrigger asChild>
        <Button
          disabled={
            visibilityFiltersQuery.isLoading || visibilityFiltersQuery.isError
          }
          variant="basic"
        >
          {visibilityFiltersQuery.isLoading ? (
            <p>Loading...</p>
          ) : visibilityFiltersQuery.isError ? (
            <p>Error</p>
          ) : (
            <Row className="gap-1">
              <p>View Settings</p>
              <ChevronDown size={18} />
            </Row>
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {data != null && columns != null && (
          <>
            <div className="h-[375px] w-[325px] overflow-y-scroll p-3">
              {Object.entries(config).map(([label, options]) => {
                return (
                  <div key={label}>
                    <DropdownMenuLabel className="font-bold">
                      {label}
                    </DropdownMenuLabel>
                    {options
                      .filter((option) => {
                        if (
                          option === "experiment_status" ||
                          (typeof option !== "string" &&
                            option.filter === "experiment_status")
                        ) {
                          return pricePlanPushChangesEnabled;
                        }
                        return true;
                      })
                      .map((val) => {
                        const column =
                          typeof val === "string" ? val : val.filter;
                        const label =
                          typeof val === "string"
                            ? formatGenericEnumLabel(val)
                            : val.label;
                        invariant(
                          column in columns,
                          `${column} must exist in current visibility filters.`,
                        );

                        if (columnsToHide.has(column)) {
                          return null;
                        }

                        const value =
                          columns[column as keyof VisibilityFiltersUnion];
                        const localValue =
                          localFilters[column as keyof VisibilityFiltersUnion];
                        const currentValue = localValue ?? value;
                        return (
                          <DropdownMenuItem
                            asChild
                            className="hover:cursor-pointer"
                            disabled={loading}
                            key={column}
                            onClick={(e) => {
                              e.preventDefault();
                              const newValue = !currentValue;
                              setLocalFilters((current) =>
                                filterMaybeValuesFromObject({
                                  ...current,
                                  [column]:
                                    newValue === value ? null : newValue,
                                }),
                              );
                            }}
                          >
                            <Row className="w-full justify-between gap-4">
                              <p>{label}</p>
                              <Checkbox
                                checked={currentValue}
                                id={`${column}-checkbox`}
                                onCheckedChange={() => null}
                                size="sm"
                              />
                            </Row>
                          </DropdownMenuItem>
                        );
                      })}
                  </div>
                );
              })}
            </div>
            <Row className="justify-evenly gap-6 px-4 py-3">
              <DropdownMenuItem asChild>
                <Button className="w-full" disabled={loading} variant="basic">
                  Cancel
                </Button>
              </DropdownMenuItem>
              <Button
                className="w-full"
                disabled={loading || arrayEmpty(Object.keys(localFilters))}
                onClick={handleSaveFilters}
              >
                Save
              </Button>
            </Row>
          </>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
