import { FancyBoxItem } from "src/frontend/components/ui/FancyBox";
import { ACTIVE_SKUS_OPTION } from "src/frontend/constants/CustomProductFilters";
import { PricingConstraintType } from "src/shared/trpc/common/PricingConstraint";
import { ProductConditionFiltersType } from "src/shared/trpc/common/ProductConditionFilters";
import { create } from "zustand";

export type CreateUpdatePricingStrategyInput = ProductConditionFiltersType & {
  constraints: PricingConstraintType[];
  customSkusList: string[];
  excludeSkus: boolean;
  impactedSkus: FancyBoxItem[];
  impactedSkusOptions: FancyBoxItem[];
  isActive: boolean;
  strategyName: string;
};

type StoreState = CreateUpdatePricingStrategyInput;

type StoreActions = {
  reset: () => void;
  setConstraints: (
    newConstraints:
      | PricingConstraintType[]
      | ((prevItems: PricingConstraintType[]) => PricingConstraintType[]),
  ) => void;
  setImpactedSkus: (
    items: FancyBoxItem[] | ((prevItems: FancyBoxItem[]) => FancyBoxItem[]),
  ) => void;
};

const defaultState: StoreState = {
  applyAndConditionForCategory: false,
  applyAndConditionForFilters: false,
  applyAndConditionForPriceZone: false,
  applyAndConditionForProductBrandName: false,
  applyAndConditionForSubCategory: false,
  applyAndConditionForSupplier: false,
  applyAndConditionForTags: false,
  // TODO: move the following to a separate store:
  constraints: [],
  customSkusList: [],
  excludeSkus: false,
  impactedSkus: [ACTIVE_SKUS_OPTION],
  impactedSkusOptions: [],
  isActive: false,
  strategyName: "",
};

const useCreatePricingStrategyStore = create<StoreActions & StoreState>(
  (set) => ({
    ...defaultState,
    reset: () => set(defaultState),
    setConstraints: (arg) =>
      set((state) => ({
        constraints: arg instanceof Function ? arg(state.constraints) : arg,
      })),
    setImpactedSkus: (arg) =>
      set((state) => ({
        impactedSkus: arg instanceof Function ? arg(state.impactedSkus) : arg,
      })),
  }),
);

export default useCreatePricingStrategyStore;
