import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { IconCheck, IconMinus } from "@tabler/icons-react";
import { VariantProps, cva } from "class-variance-authority";
import * as React from "react";
import { Label } from "src/frontend/components/ui/Label";
import { cn } from "src/frontend/components/ui/utils";
import ClassNameProp from "src/frontend/types/ClassNameProp";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";

export const checkboxVariants = cva("", {
  defaultVariants: {
    size: "default",
    variant: "default",
  },
  variants: {
    size: {
      default: "h-5 w-5",
      lg: "h-6 w-6",
      sm: "h-4 w-4",
    },
    variant: {
      default:
        "peer shrink-0 rounded-sm border border-primary shadow focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 data-[state='indeterminate']:bg-primary data-[state=checked]:bg-primary data-[state='indeterminate']:text-primary-foreground data-[state=checked]:text-primary-foreground",
    },
  },
});

type CheckboxSizeVariants = VariantProps<typeof checkboxVariants>["size"];
function getIconSize(variant: CheckboxSizeVariants): number {
  switch (variant) {
    case null:
    case undefined:
    case "default":
      return 16;
    case "sm":
      return 13;
    case "lg":
      return 18;
    default:
      return assertUnreachable(variant);
  }
}

const RadixCheckbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> &
    VariantProps<typeof checkboxVariants> & { indeterminate?: boolean }
>(({ className, size, variant, ...props }, ref) => {
  const iconSize = getIconSize(size);
  return (
    <CheckboxPrimitive.Root
      className={cn(checkboxVariants({ className, size, variant }))}
      ref={ref}
      {...props}
    >
      <CheckboxPrimitive.Indicator
        className={cn("flex items-center justify-center text-current")}
      >
        {props.checked === true && (
          <IconCheck size={iconSize} strokeWidth={3} />
        )}
        {props.checked === "indeterminate" && (
          <IconMinus size={iconSize} strokeWidth={3} />
        )}
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  );
});
RadixCheckbox.displayName = CheckboxPrimitive.Root.displayName;

type CheckboxProps = VariantProps<typeof checkboxVariants> & {
  checkboxClassName?: ClassNameProp;
  checked: boolean | "indeterminate";
  disabled?: boolean;
  id: string;
  label?: React.ReactNode;
  onCheckedChange: (value: string, checked: boolean) => void;
};

export default function Checkbox({
  checkboxClassName,
  checked,
  disabled,
  id,
  label,
  onCheckedChange,
  size,
  variant,
}: CheckboxProps) {
  const checkbox = (
    <RadixCheckbox
      checked={checked}
      className={checkboxClassName}
      disabled={disabled}
      id={id}
      onCheckedChange={(checked) => onCheckedChange(id, checked as boolean)}
      size={size}
      variant={variant}
    />
  );

  if (label == null) {
    return checkbox;
  }

  return (
    <div className="my-2 flex items-center space-x-2">
      {checkbox}
      <Label
        className="text-sm font-medium leading-none hover:cursor-pointer peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        htmlFor={id}
      >
        {label}
      </Label>
    </div>
  );
}
