import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/frontend/components/ui/Select";

type Option = {
  disabled?: boolean;
  label: string;
  value: string;
};

type SimpleSelectProps = {
  disabled?: boolean;
  onValueChange: (value: string) => void;
  options: Option[];
  placeholder?: string;
  value: string | undefined;
};

export default function SimpleSelect({
  disabled = false,
  onValueChange,
  options,
  placeholder = "Select",
  value,
}: SimpleSelectProps) {
  return (
    <Select disabled={disabled} onValueChange={onValueChange} value={value}>
      <SelectTrigger>
        <SelectValue placeholder={placeholder} />
      </SelectTrigger>
      <SelectContent>
        {options.map(({ disabled, label, value }) => {
          return (
            <SelectItem disabled={disabled} key={value} value={value}>
              {label}
            </SelectItem>
          );
        })}
      </SelectContent>
    </Select>
  );
}
