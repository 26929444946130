import { ChevronRight, EqualIcon, PlusCircleIcon } from "lucide-react";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "src/frontend/components/Card";
import Col from "src/frontend/components/Col";
import PaginationControls from "src/frontend/components/PaginationControls";
import Row from "src/frontend/components/Row";
import SearchInput from "src/frontend/components/SearchInput";
import { Badge } from "src/frontend/components/ui/Badge";
import Button from "src/frontend/components/ui/Button";
import SemiBold from "src/frontend/components/ui/SemiBold";
import { Skeleton } from "src/frontend/components/ui/Skeleton";
import COLORS from "src/frontend/constants/Colors";
import useGetPriceParityGroupsQuery from "src/frontend/hooks/queries/useGetPriceParityGroupsQuery";
import PriceParityGroupActiveToggle from "src/frontend/pages/company/relationships/PriceParityGroupActiveToggle";
import arrayEmpty from "src/shared/utils/arrays/arrayEmpty";
import len from "src/shared/utils/arrays/len";
import range from "src/shared/utils/arrays/range";
import { useDebounce } from "usehooks-ts";

const TRIGGER_ID_PREFIX = "group-active-trigger-";

function PriceParityGroupsLoadingSkeleton() {
  return (
    <div>
      {range(6).map((val) => (
        <Skeleton className="mt-4 h-[50px] w-full" key={val} />
      ))}
    </div>
  );
}

export default function ProductRelationships() {
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const [isPromptOpenId, setIsPromptOpenId] = useState("");
  const [page, setPage] = useState(1);
  const debouncedSearchInput = useDebounce(searchInput, 500);
  const query = useGetPriceParityGroupsQuery({
    page,
    pageSize: 50,
    search: debouncedSearchInput,
  });

  const priceParityGroups = query.data?.groups ?? [];
  const totalCount = query.data?.totalCount ?? 0;

  const isNotLoadingAndHasNoErrors = !query.isLoading && !query.isError;

  return (
    <div>
      <Row className="justify-between">
        <SearchInput
          className="w-96"
          onChange={setSearchInput}
          placeholder="Search by group name, SKU or product name"
          value={searchInput}
        />
        <Link to="/company-settings/relationships/create">
          <Button>
            <PlusCircleIcon className="mr-2" size={16} strokeWidth={2} /> Add
            New
          </Button>
        </Link>
      </Row>
      <div className="mt-6">
        {query.isLoading && <PriceParityGroupsLoadingSkeleton />}
        {query.isError && <p>Could not load price parity groups.</p>}
        {isNotLoadingAndHasNoErrors && arrayEmpty(priceParityGroups) && (
          <p>
            No product relationships exist yet. Add a new one to get started.
          </p>
        )}
        {isNotLoadingAndHasNoErrors &&
          searchInput !== "" &&
          arrayEmpty(priceParityGroups) && (
            <p>
              No groups exist with any products matching the search input (match
              by SKU or product name).
            </p>
          )}
        {isNotLoadingAndHasNoErrors && (
          <Col className="gap-3">
            {priceParityGroups.map((group) => {
              return (
                <Card
                  className="flex h-[58px] flex-row justify-between px-6 py-2 hover:cursor-pointer hover:shadow"
                  key={group.id}
                  onClick={(e) => {
                    const target = e.target as Element;
                    const isPromptElement =
                      isPromptOpenId || target.id.startsWith(TRIGGER_ID_PREFIX);
                    if (!isPromptElement) {
                      navigate(
                        `/company-settings/relationships/price-parity/${group.id}`,
                      );
                    }
                  }}
                >
                  <Row className="gap-2">
                    <EqualIcon stroke={COLORS.LUCA_RED} strokeWidth={2.5} />
                    <p>
                      <SemiBold>Price Parity:</SemiBold> {group.name}
                    </p>
                  </Row>
                  <Row className="gap-2">
                    <Badge className="text-sm" variant="muted">
                      <p>{len(group.product_short_list)} SKUs</p>
                    </Badge>
                    <PriceParityGroupActiveToggle
                      group={group}
                      isPromptOpen={isPromptOpenId === group.id}
                      setIsPromptOpen={(open) =>
                        setIsPromptOpenId(open ? group.id : "")
                      }
                      trigger={
                        <Badge
                          className="text-sm"
                          id={`${TRIGGER_ID_PREFIX}${group.id}`}
                          variant={group.is_active ? "default" : "destructive"}
                        >
                          {group.is_active ? "Live" : "Inactive"}
                        </Badge>
                      }
                    />
                    <ChevronRight size={32} strokeWidth={1.5} />
                  </Row>
                </Card>
              );
            })}
            {totalCount > 0 && (
              <div className="relative my-4 flex w-full items-center justify-center">
                <PaginationControls
                  currentPage={page}
                  pageSize={50}
                  setPage={setPage}
                  totalCount={totalCount}
                />
              </div>
            )}
          </Col>
        )}
      </div>
    </div>
  );
}
