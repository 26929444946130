import React from "react";
import TrpcClient from "src/frontend/api/TrpcClient";
import Row from "src/frontend/components/Row";
import { Skeleton } from "src/frontend/components/ui/Skeleton";
import useBrandId from "src/frontend/hooks/useBrandId";
import CompetitorIntelligenceMatchByCompetitorTable from "src/frontend/pages/competitor-intelligence/summary/CompetitorIntelligenceMatchByCompetitorTable";
import CompetitorIntelligenceMatchByPrizeZoneTable from "src/frontend/pages/competitor-intelligence/summary/CompetitorIntelligenceMatchByPrizeZoneTable";
import CompetitorIntelligenceMatchBySkuTable from "src/frontend/pages/competitor-intelligence/summary/CompetitorIntelligenceMatchBySkuTable";
import CompetitorIntelligencePriceIndexTable from "src/frontend/pages/competitor-intelligence/summary/CompetitorIntelligencePriceIndexTable";
import formatDate from "src/shared/utils/dates/formatDate";
import now from "src/shared/utils/dates/now";

export default function CompetitorIntelligenceSummary() {
  const brandId = useBrandId();
  const { data, isLoading } =
    TrpcClient.internal.getCompetitorIntelligenceSummary.useQuery({
      brandId,
    });

  if (!isLoading && !data) {
    return <div>No data</div>;
  }
  const today = now();
  const thirtyDaysPrior = today.subtract(30, "days");
  return (
    <div className="h-full overflow-y-scroll bg-n-0">
      <Row className="z-10 w-full items-baseline justify-start gap-4 border-b border-n-200 bg-n-0 px-6 py-4">
        <h1 className="font-bold">Summary</h1>
        <p className="text-sm italic text-n-400">
          Most recent match from {formatDate(thirtyDaysPrior)} -{" "}
          {formatDate(today)}
        </p>
      </Row>
      {isLoading && (
        <div className="flex flex-col gap-10 p-6 pt-5">
          <div className="flex h-fit flex-col gap-10 md:flex-row">
            <Skeleton className="h-[350px] w-full md:w-1/2" />
            <Skeleton className="h-[350px] w-full md:w-1/2" />
          </div>
          <div className="flex h-fit flex-col gap-10 md:flex-row">
            <Skeleton className="h-[350px] w-full md:w-1/2" />
            <Skeleton className="h-[350px] w-full md:w-1/2" />
          </div>
        </div>
      )}
      {data && (
        <div className="flex flex-col gap-10 p-6 pt-5">
          <div className="flex flex-col gap-10 md:flex-row">
            <CompetitorIntelligenceMatchByCompetitorTable
              matchByCompetitorData={data.matchByCompetitor}
            />
            <CompetitorIntelligenceMatchByPrizeZoneTable
              competitors={data.competitors}
              matchByPrizeZoneData={data.matchByPriceZone}
            />
          </div>
          <div className="flex flex-col gap-10 md:flex-row">
            <CompetitorIntelligenceMatchBySkuTable
              matchBySku={data.matchesBySku}
            />
            <CompetitorIntelligencePriceIndexTable
              brandPriceIndex={data.brandPriceIndex}
              competitorPriceIndex={data.competitorPriceIndex}
            />
          </div>
        </div>
      )}
    </div>
  );
}
