import { X } from "lucide-react";
import React, { useState } from "react";
import TrpcClient from "src/frontend/api/TrpcClient";
import Bold from "src/frontend/components/Bold";
import { Badge } from "src/frontend/components/ui/Badge";
import Button from "src/frontend/components/ui/Button";
import CardWithContent from "src/frontend/components/ui/CardWithContent";
import { Input } from "src/frontend/components/ui/Input";
import { Modal } from "src/frontend/components/ui/Modal";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/frontend/components/ui/Select";
import { Skeleton } from "src/frontend/components/ui/Skeleton";
import useToast from "src/frontend/components/ui/useToast";
import { cn } from "src/frontend/components/ui/utils";
import useUserPermissionsQuery from "src/frontend/hooks/queries/useUserPermissionsQuery";
import useIsLucaAdmin from "src/frontend/hooks/useIsLucaAdmin";
import useBrandStore from "src/frontend/stores/useBrandStore";
import { PermissionLevelType } from "src/shared/trpc/common/enum/PermissionLevel";
import { BrandUserType } from "src/shared/trpc/queries/getUsersForBrandQuerySchema";
import arrayNotEmpty from "src/shared/utils/arrays/arrayNotEmpty";
import range from "src/shared/utils/arrays/range";
import isValidEmail from "src/shared/utils/isValidEmail";

function ManagerUsersLoadingSkeleton() {
  return (
    <div className="flex w-full flex-col gap-6">
      <Skeleton className="h-12 w-full" />
      <Skeleton className="h-4 w-1/12" />
      <div className="flex flex-col gap-2">
        {range(3).map((_, i) => {
          return (
            <div className="flex flex-row gap-4" key={i}>
              <Skeleton className="h-6 w-1/6" />
              <Skeleton className="h-6 w-1/4" />
            </div>
          );
        })}
      </div>
    </div>
  );
}

type RemoveUserModalProps = {
  onSuccess: () => void;
  removeUserMutation: ReturnType<
    typeof TrpcClient.internal.removeUserFromBrand.useMutation
  >;
  user: BrandUserType;
};

function RemoveUserModal({
  onSuccess,
  removeUserMutation,
  user,
}: RemoveUserModalProps) {
  const { errorToast, successToast } = useToast();
  const [removeUserModalOpen, setRemoveUserModalOpen] = useState(false);
  const brand = useBrandStore((state) => state);
  return (
    <Modal
      dialogDescription={
        <span>
          This will remove <b>{user.email_address}</b> from {brand.brand_name}.
        </span>
      }
      loading={removeUserMutation.isLoading}
      onConfirm={() => {
        removeUserMutation.mutate(
          {
            brandId: brand.brand_id,
            userIdToRemove: user.id,
          },
          {
            onError: () => {
              errorToast({
                description: "Failed to remove user.",
              });
            },
            onSuccess: () => {
              successToast({
                description: "User removed from brand.",
              });
              setRemoveUserModalOpen(false);
              onSuccess();
            },
          },
        );
      }}
      onOpenChange={setRemoveUserModalOpen}
      open={removeUserModalOpen}
      trigger={
        <Button size="smIcon" variant="basic">
          <X size={14} />
        </Button>
      }
    />
  );
}

export default function TeamSettings() {
  const { errorToast, successToast } = useToast();
  const [emailAddress, setEmailAddress] = React.useState("");
  const [permission, setPermission] =
    React.useState<PermissionLevelType>("READ_ONLY");
  const brand = useBrandStore((state) => state);
  const userPermissions = useUserPermissionsQuery();
  const getUsersForBrandQuery = TrpcClient.internal.getUsersForBrand.useQuery({
    brandId: brand.brand_id,
  });
  const addUserToBrandMutation =
    TrpcClient.internal.addUserToBrand.useMutation();
  const removeUserMutation =
    TrpcClient.internal.removeUserFromBrand.useMutation();
  const isBrandAdmin = userPermissions.data?.permissionLevel === "ADMIN";
  const isLucaAdmin = useIsLucaAdmin();

  const handleAddUser = () => {
    if (!isValidEmail(emailAddress)) {
      errorToast({
        description: "Please enter a valid email address.",
      });
      return;
    }

    addUserToBrandMutation.mutate(
      {
        brandId: brand.brand_id,
        emailAddress,
        permissionLevel: permission,
      },
      {
        onSuccess: () => {
          setEmailAddress("");
          setPermission("READ_ONLY");
          successToast({
            description: "User added successfully.",
          });
          void getUsersForBrandQuery.refetch();
        },
      },
    );
  };

  const brandUsers = getUsersForBrandQuery?.data?.brandUsers ?? [];
  const lucaUsers = getUsersForBrandQuery?.data?.lucaUsers ?? [];

  return (
    <div className="flex flex-col gap-6">
      <CardWithContent title="Manage Users">
        {userPermissions.isLoading || getUsersForBrandQuery.isLoading ? (
          <ManagerUsersLoadingSkeleton />
        ) : userPermissions.isError ? (
          <div>
            <p>Could not load user information.</p>
          </div>
        ) : (
          <>
            {isBrandAdmin ? (
              <div className="flex w-full flex-col gap-6">
                <div className="w-full">
                  <div className="flex flex-1 items-end gap-3">
                    <div className="flex flex-1 flex-col gap-2 lg:flex-row">
                      <div className="flex flex-1 flex-col gap-2">
                        <p className="text-xs font-semibold uppercase text-zinc-400">
                          Add User
                        </p>
                        <Input
                          disableLastPassAutoFill
                          onChange={(e) => setEmailAddress(e.target.value)}
                          placeholder="Email address"
                          value={emailAddress}
                        />
                      </div>
                      <div className="flex flex-1 flex-col gap-2">
                        <p className="text-xs font-semibold uppercase text-zinc-400">
                          Select a Permission Level
                        </p>
                        <Select
                          onValueChange={(e) => {
                            setPermission(e as PermissionLevelType);
                          }}
                          value={permission}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Permission level" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="READ_ONLY">Read Only</SelectItem>
                            <SelectItem value="ADMIN">Admin</SelectItem>
                          </SelectContent>
                        </Select>
                      </div>
                    </div>
                    <Button
                      disabled={
                        addUserToBrandMutation.isLoading || emailAddress === ""
                      }
                      onClick={() => handleAddUser()}
                      size="xs"
                      variant="basic"
                    >
                      {addUserToBrandMutation.isLoading
                        ? "Loading..."
                        : "Add User"}
                    </Button>
                  </div>
                  <div className="my-5 h-[1px] w-full bg-border" />
                  <div className="flex flex-col">
                    <p className="mb-3 text-xs font-semibold uppercase text-zinc-400">
                      Remove Users
                    </p>
                    <div className="flex flex-col gap-3">
                      {getUsersForBrandQuery.isError ? (
                        <div>
                          <p>Could not load users.</p>
                        </div>
                      ) : (
                        <>
                          {brandUsers.map((user) => (
                            <BrandUserRow
                              key={user.id}
                              loading={
                                removeUserMutation.isLoading &&
                                removeUserMutation.variables?.userIdToRemove ===
                                  user.id
                              }
                              onRemoveSuccess={() => {
                                void getUsersForBrandQuery.refetch();
                              }}
                              removeUserMutation={removeUserMutation}
                              user={user}
                            />
                          ))}
                          {isLucaAdmin && arrayNotEmpty(lucaUsers) && (
                            <>
                              <Bold>Luca Team:</Bold>
                              {lucaUsers.map((user) => (
                                <BrandUserRow
                                  key={user.id}
                                  loading={
                                    removeUserMutation.isLoading &&
                                    removeUserMutation.variables
                                      ?.userIdToRemove === user.id
                                  }
                                  onRemoveSuccess={() => {
                                    void getUsersForBrandQuery.refetch();
                                  }}
                                  removeUserMutation={removeUserMutation}
                                  user={user}
                                />
                              ))}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-3">
                <p>Users</p>
                {getUsersForBrandQuery.isLoading ? (
                  <ManagerUsersLoadingSkeleton />
                ) : getUsersForBrandQuery.isError ? (
                  <div>
                    <p>Could not load users.</p>
                  </div>
                ) : (
                  brandUsers.map((user) => (
                    <p key={user.email_address}> {user.email_address}</p>
                  ))
                )}
              </div>
            )}
          </>
        )}
      </CardWithContent>
    </div>
  );
}

type BrandUserRowProps = {
  loading: boolean;
  onRemoveSuccess: () => void;
  removeUserMutation: ReturnType<
    typeof TrpcClient.internal.removeUserFromBrand.useMutation
  >;
  user: BrandUserType;
};

function BrandUserRow({
  loading,
  onRemoveSuccess,
  removeUserMutation,
  user,
}: BrandUserRowProps) {
  return (
    <div className={cn("flex items-center gap-3", loading ? "opacity-50" : "")}>
      <RemoveUserModal
        onSuccess={() => {
          onRemoveSuccess();
        }}
        removeUserMutation={removeUserMutation}
        user={user}
      />
      <p>{user.email_address}</p>
      <Badge variant="secondary">{user.permission_level}</Badge>
    </div>
  );
}
