import MetricSummaryCard from "src/frontend/pages/pricing/PricePlanSummaryMetrics/MetricSummaryCard";
import mapMetricData from "src/frontend/pages/pricing/PricePlanSummaryMetrics/mapMetricData";
import usePricePlanStore from "src/frontend/stores/usePricePlanStore";
import { GetPricePlanSummaryQueryResponseType } from "src/shared/trpc/queries/getPricePlanMetricsQuerySchema";
import { ImpactViewFilterType } from "src/shared/types/ImpactViewFilter";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";

function getLevel(
  metricViewFilter: ImpactViewFilterType,
): "brandLevel" | "pricePlanLevel" {
  switch (metricViewFilter) {
    case "price-plan-level":
      return "pricePlanLevel";
    case "brand-level":
      return "brandLevel";
    default:
      return assertUnreachable(metricViewFilter);
  }
}

type MetricsTabsProps = {
  opportunitySegment?: GetPricePlanSummaryQueryResponseType["opportunity_metrics"][number];
  resultsSegment?: GetPricePlanSummaryQueryResponseType["group_results_metrics"][number];
  type: "FORECAST" | "IMPACT";
};

export default function MetricSummaryCards({
  opportunitySegment,
  resultsSegment,
  type,
}: MetricsTabsProps) {
  const { impactViewFilter } = usePricePlanStore();

  const level = getLevel(impactViewFilter);
  const typeLowercase = type.toLowerCase() as Lowercase<typeof type>;

  const { margin, profit, revenue, units } = mapMetricData(
    opportunitySegment,
    resultsSegment,
  )[typeLowercase][level];

  return (
    <div className="flex-grow basis-0">
      <div className="grid w-full grid-cols-4 gap-6">
        <MetricSummaryCard
          confidenceInterval={revenue.confidence}
          metric={revenue.impact}
          metricType="currency"
          percentChange={revenue.impactPercent}
          title="Revenue"
        />
        <MetricSummaryCard
          confidenceInterval={units.confidence}
          metric={units.impact}
          metricType="number"
          percentChange={units.impactPercent}
          title="Units"
        />
        <MetricSummaryCard
          confidenceInterval={profit.confidence}
          metric={profit.impact}
          metricType="currency"
          percentChange={profit.impactPercent}
          title="Profit"
        />
        <MetricSummaryCard
          confidenceInterval={margin.confidence}
          metric={margin.impact}
          metricType="percent"
          percentChange={margin.impactPercent}
          title="Margin"
        />
      </div>
    </div>
  );
}
