import React, { useState } from "react";
import { CompetitorPriceIndexType } from "src/backend/internal-api/queries/getCompetitorIntelligenceSummaryQuery";
import {
  Table,
  TableDataCell,
  TableHeader,
  TableHeaderCell,
} from "src/frontend/components/TableComponents";
import Card, {
  CardContent,
  CardHeader,
  CardTitle,
} from "src/frontend/components/ui/CardWithContent";
import { Label } from "src/frontend/components/ui/Label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/frontend/components/ui/Select";
import toTitleCase from "src/shared/utils/strings/toTitleCase";

const PriceIndexLabel = ({ priceIndex }: { priceIndex: number | null }) => {
  return <span>{priceIndex != null ? `$${priceIndex}` : "-"}</span>;
};

function CompetitorIntelligencePriceIndexTable({
  brandPriceIndex,
  competitorPriceIndex,
}: {
  brandPriceIndex: Record<string, CompetitorPriceIndexType> | null;
  competitorPriceIndex: Record<string, CompetitorPriceIndexType[]>;
}) {
  const priceZones = Object.keys(competitorPriceIndex);
  const [priceZone, setPriceZone] = useState(priceZones[0]);
  const brandPriceIndexData = brandPriceIndex?.[priceZone];
  const competitorPriceIndexData = competitorPriceIndex[priceZone];
  let priceIndexData = competitorPriceIndexData;
  if (brandPriceIndexData) {
    priceIndexData = [brandPriceIndexData, ...competitorPriceIndexData];
  }

  return (
    <Card className="w-full">
      <CardHeader className="flex-row items-center justify-between p-0 pt-6">
        <CardTitle>Price Index</CardTitle>
        <div className="flex items-center gap-2">
          <Label
            className="whitespace-nowrap"
            htmlFor="competitor-summary-price-index-prize-zone"
          >
            Price Zone:
          </Label>
          <Select
            onValueChange={(value) => setPriceZone(value)}
            value={priceZone}
          >
            <SelectTrigger
              className="w-full"
              id="competitor-summary-price-index-prize-zone"
            >
              <SelectValue placeholder="Select price zone" />
            </SelectTrigger>
            <SelectContent>
              {priceZones.map((priceZone) => (
                <SelectItem key={priceZone} value={priceZone}>
                  {priceZone
                    .split(/(?<=\.|\/)|\s+/)
                    .map((word) => toTitleCase(word))
                    .join(" ")}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </CardHeader>
      <CardContent className="p-0 pt-6">
        <Table className="h-64 border-y-0">
          <TableHeader className="border-b">
            <tr>
              <TableHeaderCell />
              <TableHeaderCell className="max-w-[12ch] whitespace-break-spaces">
                All Products Median
              </TableHeaderCell>
              <TableHeaderCell className="max-w-[12ch] whitespace-break-spaces">
                All Products Weighted Average
              </TableHeaderCell>
              <TableHeaderCell className="max-w-[12ch] whitespace-break-spaces">
                KVI Weighted Average
              </TableHeaderCell>
            </tr>
          </TableHeader>
          <tbody>
            {competitorPriceIndexData == null && (
              <tr>
                <TableDataCell
                  className="flex items-center justify-center"
                  colSpan={4}
                >
                  No data
                </TableDataCell>
              </tr>
            )}
            {priceIndexData?.map((data) => (
              <tr className="border-b" key={data.competitorName}>
                <TableDataCell className="font-medium">
                  {data.competitorName}
                </TableDataCell>
                <TableDataCell>
                  <PriceIndexLabel priceIndex={data.medianPriceIndex} />
                </TableDataCell>
                <TableDataCell>
                  <PriceIndexLabel
                    priceIndex={data.weightedAveragePriceIndex}
                  />
                </TableDataCell>
                <TableDataCell>
                  <PriceIndexLabel
                    priceIndex={data.kviWeightedAveragePriceIndex}
                  />
                </TableDataCell>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardContent>
    </Card>
  );
}

export default CompetitorIntelligencePriceIndexTable;
