import { IconDotsVertical, IconDownload, IconTrash } from "@tabler/icons-react";
import React, { useState } from "react";
import apiClient from "src/frontend/api/ApiClient";
import Row from "src/frontend/components/Row";
import DeletePricePlanModal from "src/frontend/components/modals/DeletePricePlanModal";
import Button from "src/frontend/components/ui/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "src/frontend/components/ui/DropdownMenu";
import useToast from "src/frontend/components/ui/useToast";
import useBrandId from "src/frontend/hooks/useBrandId";
import usePricePlanStore from "src/frontend/stores/usePricePlanStore";
import downloadNewPricePlanCsv from "src/frontend/utils/download/downloadNewPricePlanCsv";
import { PricePlanResponseType } from "src/shared/trpc/common/PricePlanResponse";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";

export const PRICE_PLAN_DROPDOWN_ID = "price-plan-dropdown-menu";

type PricePlanDropdownMenuProps = {
  disabled?: boolean;
  hasResults?: boolean;
  isDeleteEnabled?: boolean;
  isDownloadEnabled?: boolean;
  pricePlan: MaybeNull<PricePlanResponseType>;
};

export default function PricePlansDropdownMenu({
  disabled = false,
  hasResults = false,
  isDeleteEnabled,
  isDownloadEnabled,
  pricePlan,
}: PricePlanDropdownMenuProps) {
  const t = useToast();
  const brandId = useBrandId();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const impactViewFilter = usePricePlanStore((state) => state.impactViewFilter);

  const { customerSegment } = usePricePlanStore();

  const handleDownload = async () => {
    if (pricePlan != null) {
      t.infoToast("Starting report generation, this may take a moment...");
      setDownloadLoading(true);
      if (!hasResults) {
        try {
          const result = await apiClient.downloadNewPricePlanCsv({
            brandId,
            customerSegment,
            groupIds: [],
            impactFilter: impactViewFilter,
            pricePlanId: pricePlan.id,
          });
          downloadNewPricePlanCsv(result, customerSegment);
          t.successToast("Report downloaded successfully");
        } catch (err: any) {
          t.errorToast("There was an error generating the report.");
        }
      } else {
        try {
          const result = await apiClient.downloadResultsPricePlanCsv({
            brandId,
            customerSegment,
            groupIds: [],
            pricePlanId: pricePlan.id,
          });
          downloadNewPricePlanCsv(result, customerSegment);
          t.successToast("Report downloaded successfully");
        } catch (err: any) {
          t.errorToast("There was an error generating the report.");
        }
      }
      setDownloadLoading(false);
    }
  };

  if (!isDeleteEnabled && !isDownloadEnabled) {
    return null;
  }

  return (
    <>
      <DeletePricePlanModal
        modalOpen={isDeleteModalOpen}
        pricePlan={pricePlan}
        setModalOpen={setIsDeleteModalOpen}
      />
      <DropdownMenu>
        <DropdownMenuTrigger
          asChild
          className="focus:outline-none focus:ring-0"
        >
          <Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            size="icon"
            variant="ghost"
          >
            <IconDotsVertical color="#A3A3A3" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" id={PRICE_PLAN_DROPDOWN_ID}>
          {isDownloadEnabled && (
            <DropdownMenuItem
              className="hover:cursor-pointer"
              disabled={disabled || downloadLoading}
              onClick={() => void handleDownload()}
            >
              <Row className="gap-2">
                <IconDownload size={16} stroke={1.5} />
                <p>Download</p>
              </Row>
            </DropdownMenuItem>
          )}
          {isDeleteEnabled && (
            <DropdownMenuItem
              className="hover:cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setIsDeleteModalOpen(true);
              }}
            >
              <Row className="gap-2">
                <IconTrash size={16} stroke={1.5} />
                <p>Delete</p>
              </Row>
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
