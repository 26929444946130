import { IconChartBarPopular, IconTable } from "@tabler/icons-react";
import { ReactNode } from "react";
import { Navigate, Outlet, useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import logServerError from "src/backend/sentry/logServerError";
import Row from "src/frontend/components/Row";
import { cn } from "src/frontend/components/ui/utils";
import PricePlanName from "src/frontend/pages/pricing/PricePlanName";
import SentryErrorEvent from "src/shared/utils/sentryErrorUtils";

const validTabs = ["preview", "internal", "draft", "current", "past", "new"];

function getCurrentPage({
  groupId,
  pricePlanId,
}: {
  groupId: string | undefined;
  pricePlanId: string | undefined;
}): "PRICE_BOOK" | "SUMMARY" | null {
  if (pricePlanId != null) {
    if (groupId != null) {
      return "PRICE_BOOK";
    }
    return "SUMMARY";
  }
  return null;
}

function Tab({
  active,
  children,
  to,
}: {
  active: boolean;
  children: ReactNode;
  to: string;
}) {
  return (
    <Link
      className={cn(
        "px-4 py-2 text-sm font-semibold",
        active ? "bg-n-100" : "bg-n-0",
      )}
      to={to}
    >
      <Row className="h-full items-center gap-1">{children}</Row>
    </Link>
  );
}

function NavTab() {
  useLocation();
  const { groupId, pricePlanId } = useParams();
  const page = getCurrentPage({ groupId, pricePlanId });

  if (page == null) {
    return null;
  }

  return (
    <Row className="items-stretch gap-[1px] overflow-hidden rounded-lg border border-solid border-n-200 bg-n-200">
      <Tab active={page === "SUMMARY"} to={`${pricePlanId}`}>
        <IconChartBarPopular size={16} />
        Summary
      </Tab>
      <Tab active={page === "PRICE_BOOK"} to={`${pricePlanId}/all`}>
        <IconTable size={16} /> Price Book
      </Tab>
    </Row>
  );
}

export default function PricePlan() {
  const { pricePlanId, tab } = useParams();

  if (tab == null || !validTabs.includes(tab)) {
    logServerError(
      new Error("Invalid tab provided."),
      SentryErrorEvent.RouteErrorInvalidTab,
    );
    return <Navigate to="/" />;
  }

  if (pricePlanId == null) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Row className="justify-between px-6 py-6">
        <PricePlanName />
        <NavTab />
      </Row>
      <Outlet />
    </>
  );
}
