import { useState } from "react";
import { useNavigate } from "react-router";
import TrpcClient from "src/frontend/api/TrpcClient";
import Code from "src/frontend/components/Code";
import Col from "src/frontend/components/Col";
import Checkbox from "src/frontend/components/ui/Checkbox";
import { Modal } from "src/frontend/components/ui/Modal";
import useToast from "src/frontend/components/ui/useToast";
import useGetPricingStrategiesQuery from "src/frontend/hooks/queries/useGetPricingStrategiesQuery";
import useGetUserBrandsQuery from "src/frontend/hooks/queries/useGetUserBrandsQuery";
import usePricePlanQuery from "src/frontend/hooks/queries/usePricePlanQuery";
import useBrandId from "src/frontend/hooks/useBrandId";
import useIsLucaAdmin from "src/frontend/hooks/useIsLucaAdmin";
import useBrandStore from "src/frontend/stores/useBrandStore";
import usePricePlanStore from "src/frontend/stores/usePricePlanStore";
import usePricingStrategyDropdownStateStore from "src/frontend/stores/usePricingStrategyDropdownStateStore";
import invariant from "tiny-invariant";

type CreatePricePlanModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: (open: boolean) => void;
};

export default function CreatePricePlanModal({
  isModalOpen,
  setIsModalOpen,
}: CreatePricePlanModalProps) {
  const t = useToast();
  const navigate = useNavigate();
  const pricePlanGenerationInProgress = useBrandStore(
    (state) => state.price_plan_generation_in_progress,
  );
  const [success, setSuccess] = useState(false);
  const isLucaAdmin = useIsLucaAdmin();
  const [internalPricePlanGeneration, setInternalPricePlanGeneration] =
    useState(isLucaAdmin);
  const brandId = useBrandId();
  const brandsQuery = useGetUserBrandsQuery();
  const pricePlansQuery = usePricePlanQuery();
  const pricingStrategyQuery = useGetPricingStrategiesQuery();
  const { loading, pricingStrategyId } = usePricingStrategyDropdownStateStore();
  const generateCustomerPricePlanMutation =
    TrpcClient.internal.generateCustomerPricePlan.useMutation();

  const onSuccess = async () => {
    await Promise.all([
      pricePlansQuery.refetch(),
      pricingStrategyQuery.refetch(),
    ]);
    setSuccess(true);
    void brandsQuery.refetch();
    usePricePlanStore.setState({
      pricePlanId: undefined,
      tab: undefined,
    });
    usePricingStrategyDropdownStateStore.setState({ loading: false });
    t.dismiss();
  };

  const handleCreatePricePlan = () => {
    usePricingStrategyDropdownStateStore.setState({ loading: true });
    t.infoToast("Starting price plan creation - this will take a moment...");
    invariant(pricingStrategyId != null);
    generateCustomerPricePlanMutation.mutate(
      {
        brandId,
        generationType: internalPricePlanGeneration ? "INTERNAL" : "CUSTOMER",
        pricingStrategyId,
      },
      {
        onError: () => {
          t.errorToast("Failed to create price plan.");
          usePricingStrategyDropdownStateStore.setState({ loading: false });
        },
        onSuccess: () => {
          void onSuccess();
        },
      },
    );
    void onSuccess();
  };

  const navigateToPricePlan = () => {
    navigate(`/pricing/drafts`);
    setSuccess(false);
    usePricingStrategyDropdownStateStore.setState({
      isGenerateModalOpen: false,
      pricingStrategyId: null,
    });
    setIsModalOpen(false);
  };

  return (
    <Modal
      cancelText={success ? "Dismiss" : "Cancel"}
      confirmButtonDisabled={!success && pricePlanGenerationInProgress}
      confirmText={success ? "Go to Price Plan" : "Create"}
      dialogDescription={
        success ? (
          <Col className="gap-2">
            <p>Price Plan creation in process.</p>
            <p>
              Price plan creation can take up to one hour. We&apos;ll notify you
              when it is complete.
            </p>
          </Col>
        ) : pricePlanGenerationInProgress && !loading ? (
          <Col className="gap-2">
            <p>
              Price plan generation is disabled while a generation is in
              progress.
            </p>
            <p>
              Please wait until the current price plan is ready to request
              another.
            </p>
          </Col>
        ) : (
          <Col className="gap-2">
            <p>
              A new price plan draft will be created using the current pricing
              strategy.
            </p>
            <p>You will be notified once it is ready.</p>
            {isLucaAdmin && (
              <Checkbox
                checked={internalPricePlanGeneration}
                disabled={loading}
                id="price-plan-generation-type-checkbox"
                label={
                  <p>
                    Generate <Code>INTERNAL_DRAFT</Code> price plan.
                  </p>
                }
                onCheckedChange={(_, state) =>
                  setInternalPricePlanGeneration(state)
                }
              />
            )}
          </Col>
        )
      }
      dialogTitle={success ? "Generation Started" : "Create Price Plan"}
      loading={loading && !success}
      onConfirm={success ? navigateToPricePlan : handleCreatePricePlan}
      onOpenChange={(open) => {
        if (!open) {
          setSuccess(false);
          setInternalPricePlanGeneration(isLucaAdmin);
        }
        setIsModalOpen(open);
      }}
      open={isModalOpen}
    />
  );
}
