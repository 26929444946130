import { useQueryClient } from "@tanstack/react-query";
import { Command } from "cmdk";
import {
  CopyCheckIcon,
  CandlestickChart,
  LogOut,
  RocketIcon,
  HomeIcon,
  MoonStar,
  MountainSnowIcon,
  SettingsIcon,
  PackageSearch,
  FolderKanban,
  Sun,
  BuildingIcon,
} from "lucide-react";
import { useTheme } from "next-themes";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import Code from "src/frontend/components/Code";
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  CommandShortcut,
} from "src/frontend/components/ui/Command";
import useToast from "src/frontend/components/ui/useToast";
import useBrandId from "src/frontend/hooks/useBrandId";
import useFlags from "src/frontend/hooks/useFlags";
import useAuthStore from "src/frontend/stores/useAuthStore";
import useBrandSelectStore from "src/frontend/stores/useBrandSelectStore";
import useLucaAdminSettingsStore from "src/frontend/stores/useLucaAdminSettingsStore";
import copyTextToClipboard from "src/frontend/utils/copyTextToClipboard";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";

const HOT_KEY_MAP = {
  BASE_PRICING: "v",
  BRAND_SELECT: "b",
  COMPETITOR_INTELLIGENCE: "i",
  GOALS_AND_SETTINGS: "g",
  HOME_PAGE: "h",
  PRODUCT_CATALOG: "p",
  TOGGLE_COMPANY_VIEW: "a",
  TOGGLE_THEME: "t",
  USER_SETTINGS: "s",
};

type HotKey = keyof typeof HOT_KEY_MAP;

function isTypingInputElementFocused(): boolean {
  const activeElement = document.activeElement;
  if (activeElement != null) {
    if (
      activeElement.tagName === "INPUT" ||
      activeElement.tagName === "TEXTAREA"
    ) {
      return true;
    }
  }

  return false;
}

export function LucaHotKeyCommandMenu() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const brandId = useBrandId();
  const t = useToast();
  const [open, setOpen] = useState(false);
  const { setTheme, theme } = useTheme();
  const isBrandSelectOpen = useBrandSelectStore();
  const logout = useAuthStore((state) => state.logout);
  const { lucaCommandMenuEnabled } = useFlags();
  const isDarkTheme = theme === "dark";
  const viewAsCompanyUser = useLucaAdminSettingsStore(
    (state) => state.view_as_company_user,
  );

  const toggleTheme = () => {
    setTheme(isDarkTheme ? "light" : "dark");
    setOpen(false);
  };

  const goToHomePage = () => {
    navigate("/home");
    setOpen(false);
  };

  const goToBasePricing = () => {
    navigate("/pricing/drafts");
    setOpen(false);
  };

  const goToProductCatalog = () => {
    navigate("/product-catalog");
    setOpen(false);
  };

  const goToCompetitorIntelligence = () => {
    navigate("/competitor-intelligence");
    setOpen(false);
  };

  const goToCompanySettings = () => {
    navigate("/company-settings/strategy");
    setOpen(false);
  };

  const goToUserSettings = () => {
    navigate("/user/settings");
    setOpen(false);
  };

  const toggleBrandSelect = () => {
    useBrandSelectStore.setState(!isBrandSelectOpen);
    setOpen(false);
  };

  const toggleCompanyView = () => {
    useLucaAdminSettingsStore.setState({
      view_as_company_user: !viewAsCompanyUser,
    });
    setOpen(false);
  };

  const copyBrandId = () => {
    void copyTextToClipboard(brandId);
    t.successToast(
      <span>
        <Code>{brandId}</Code> copied to clipboard.
      </span>,
    );
    setOpen(false);
  };

  const handleLogout = () => {
    logout();
    queryClient.clear();
    navigate("/");
    setOpen(false);
  };

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (!lucaCommandMenuEnabled) {
        return;
      }

      if (isTypingInputElementFocused()) {
        return;
      }

      const { ctrlKey, key, metaKey } = event;
      const menuOpenCombination =
        (key === "j" && (metaKey || ctrlKey)) ||
        (key === "k" && (metaKey || ctrlKey));
      if (menuOpenCombination) {
        event.preventDefault();
        setOpen((open) => !open);
      }

      const matchedKey = Object.entries(HOT_KEY_MAP).find(
        ([_, value]) => value === key,
      );
      if (matchedKey == null) {
        return;
      }

      const metaKeysPressed = metaKey || ctrlKey;
      if (metaKeysPressed) {
        return;
      }

      const hotKey = matchedKey[0] as HotKey;
      switch (hotKey) {
        case "HOME_PAGE":
          goToHomePage();
          break;
        case "BASE_PRICING":
          goToBasePricing();
          break;
        case "BRAND_SELECT":
          toggleBrandSelect();
          break;
        case "GOALS_AND_SETTINGS":
          goToCompanySettings();
          break;
        case "PRODUCT_CATALOG":
          goToProductCatalog();
          break;
        case "TOGGLE_THEME":
          toggleTheme();
          break;
        case "USER_SETTINGS":
          goToUserSettings();
          break;
        case "COMPETITOR_INTELLIGENCE":
          goToCompetitorIntelligence();
          break;
        case "TOGGLE_COMPANY_VIEW":
          toggleCompanyView();
          break;
        default:
          try {
            assertUnreachable(hotKey);
          } catch (_) {}
      }
    }

    document.addEventListener("keydown", handleKeyDown);
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  if (!lucaCommandMenuEnabled) {
    return null;
  }

  return (
    <CommandDialog onOpenChange={setOpen} open={open}>
      <Command loop>
        <CommandInput placeholder="Type a command or search..." />
        <CommandList>
          <CommandEmpty>No results found.</CommandEmpty>
          <CommandGroup heading="Shortcuts">
            <CommandItem onSelect={() => copyBrandId()}>
              <CopyCheckIcon className="mr-2 h-4 w-4" />
              <span>Copy Brand ID</span>
            </CommandItem>
            <CommandItem onSelect={() => toggleBrandSelect()}>
              <RocketIcon className="mr-2 h-4 w-4" />
              <span>Open Brand Select</span>
              <CommandShortcut>{HOT_KEY_MAP.BRAND_SELECT}</CommandShortcut>
            </CommandItem>
            <CommandItem onSelect={() => goToHomePage()}>
              <HomeIcon className="mr-2 h-4 w-4" />
              <span>Open Home Page</span>
              <CommandShortcut>{HOT_KEY_MAP.HOME_PAGE}</CommandShortcut>
            </CommandItem>
            <CommandItem onSelect={() => goToBasePricing()}>
              <CandlestickChart className="mr-2 h-4 w-4" />
              <span>Open Base Pricing</span>
              <CommandShortcut>{HOT_KEY_MAP.BASE_PRICING}</CommandShortcut>
            </CommandItem>
            <CommandItem onSelect={() => goToProductCatalog()}>
              <PackageSearch className="mr-2 h-4 w-4" />
              <span>Open Product Catalog</span>
              <CommandShortcut>{HOT_KEY_MAP.PRODUCT_CATALOG}</CommandShortcut>
            </CommandItem>
            <CommandItem onSelect={() => goToCompetitorIntelligence()}>
              <FolderKanban className="mr-2 h-4 w-4" />
              <span>Open Competitor Intelligence</span>
              <CommandShortcut>
                {HOT_KEY_MAP.COMPETITOR_INTELLIGENCE}
              </CommandShortcut>
            </CommandItem>
            <CommandItem onSelect={() => goToCompanySettings()}>
              <MountainSnowIcon className="mr-2 h-4 w-4" />
              <span>Open Goals & Settings</span>
              <CommandShortcut>
                {HOT_KEY_MAP.GOALS_AND_SETTINGS}
              </CommandShortcut>
            </CommandItem>
            <CommandItem onSelect={() => goToUserSettings()}>
              <SettingsIcon className="mr-2 h-4 w-4" />
              <span>Open User Settings</span>
              <CommandShortcut>{HOT_KEY_MAP.USER_SETTINGS}</CommandShortcut>
            </CommandItem>
          </CommandGroup>
          <CommandSeparator />
          <CommandGroup heading="Settings">
            <CommandItem onSelect={() => toggleTheme()}>
              {isDarkTheme ? (
                <Sun className="mr-2 h-4 w-4" />
              ) : (
                <MoonStar className="mr-2 h-4 w-4" />
              )}
              <span>Toggle Theme</span>
              <CommandShortcut>{HOT_KEY_MAP.TOGGLE_THEME}</CommandShortcut>
            </CommandItem>
            <CommandItem onSelect={() => toggleCompanyView()}>
              <BuildingIcon className="mr-2 h-4 w-4" />
              <span>Toggle Company View</span>
              <CommandShortcut>
                {HOT_KEY_MAP.TOGGLE_COMPANY_VIEW}
              </CommandShortcut>
            </CommandItem>
            <CommandItem onSelect={() => handleLogout()}>
              <LogOut className="mr-2 h-4 w-4" />
              <span>Logout</span>
            </CommandItem>
          </CommandGroup>
        </CommandList>
      </Command>
    </CommandDialog>
  );
}
