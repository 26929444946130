import { IconCheck, IconX } from "@tabler/icons-react";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  getCompetitorReferencePriceValue,
  getPriceComparisonReferencePrice,
} from "src/backend/utils/price-rules-engine/applyConstraint";
import Col from "src/frontend/components/Col";
import Row from "src/frontend/components/Row";
import { cn } from "src/frontend/components/ui/utils";
import useProductDetailSidePanelStore from "src/frontend/stores/useProductDetailSidePanelStore";
import snakeCaseToTitleCase from "src/frontend/utils/snakeCaseToTitleCase";
import { CompetitorMatchWithAveragePricesType } from "src/shared/trpc/common/CompetitorMatchWithAveragePrices";
import { PricingConstraintType } from "src/shared/trpc/common/PricingConstraint";
import { ProductMasterObjectType } from "src/shared/trpc/common/ProductMasterObject";
import { PriceBoundType } from "src/shared/trpc/common/enum/PriceBoundType";
import {
  PriceTargetType,
  PriceTargetTypeEnum,
} from "src/shared/trpc/common/enum/PriceTargetType";
import { GetExperimentForecastQueryResponseType } from "src/shared/trpc/queries/getExperimentForecastQuerySchema";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import MaybeNumber from "src/shared/types/maybe/MaybeNumber";
import MaybeString from "src/shared/types/maybe/MaybeString";
import filterEmptyValues from "src/shared/utils/arrays/filterEmptyValues";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";
import formatPriceRange from "src/shared/utils/formatPriceRange";
import formatCurrency from "src/shared/utils/numbers/formatCurrency";
import formatPercentage from "src/shared/utils/numbers/formatPercentage";
import cloneObject from "src/shared/utils/objects/cloneObject";
import invariant from "tiny-invariant";

function RuleCard({
  data,
  dev,
  met,
  priceRange,
  title,
}: {
  data: (string | null)[];
  dev: React.ReactNode;
  met: boolean | null;
  priceRange: string;
  title: string;
}) {
  return (
    <Col className="inline-flex flex-col items-start justify-start gap-2 rounded-lg border border-n-200 bg-n-0 p-4">
      <Row className="w-full items-start justify-between">
        <p
          className={cn(
            "flex items-center gap-1 text-sm font-normal text-n-500",
            met && "text-[#61932f]",
            met === false && "text-[#d38080]",
          )}
        >
          {title}
          {met && <IconCheck size={12} strokeWidth={3} />}
          {met === false && <IconX size={12} strokeWidth={3} />}
        </p>
        <p className="text-sm font-normal text-n-500">{priceRange}</p>
      </Row>
      <>
        {data
          .filter((val) => val != null)
          .map((item) => (
            <p className="text-sm font-normal text-neutral-500" key={item}>
              {item}
            </p>
          ))}
      </>
      {dev}
    </Col>
  );
}

type GroupedConstraintsType = {
  [priceTarget in keyof Partial<typeof PriceTargetTypeEnum.enum>]: {
    roundingConstraints: PricingConstraintType[];
    ruleGroups: { [ruleGroup: string]: PricingConstraintType[] };
  };
};

function getGroupedConstraints(
  constraints: PricingConstraintType[],
): GroupedConstraintsType {
  const groupedConstraints: GroupedConstraintsType = {};

  for (const constraint of constraints) {
    const priceTarget = constraint.price_target_type;
    const ruleGroup =
      constraint.pricing_rules_group_name ?? "Unknown Rule Group"; // using rule group name here as the rule group id is sometimes null
    if (groupedConstraints[priceTarget] == null) {
      groupedConstraints[priceTarget] = {
        roundingConstraints: [],
        ruleGroups: {},
      };
    }
    if (groupedConstraints[priceTarget]?.ruleGroups[ruleGroup] == null) {
      groupedConstraints[priceTarget].ruleGroups[ruleGroup] = [];
    }
    if (constraint.type === "ROUNDING") {
      groupedConstraints[priceTarget].roundingConstraints.push(constraint);
    } else {
      groupedConstraints[priceTarget].ruleGroups[ruleGroup].push(constraint);
    }
  }
  return groupedConstraints;
}

function getPricesForPriceTarget(
  experiment: GetExperimentForecastQueryResponseType,
  priceTarget: PriceTargetType,
): {
  maxPrice: number | null;
  minPrice: number | null;
  newPrice: number | null;
  oldPrice: number | null;
} {
  const priceMap: Record<
    PriceTargetType,
    {
      maxPrice: number | null;
      minPrice: number | null;
      newPrice: number | null;
      oldPrice: number | null;
    }
  > = {
    DISCOUNTED_PRICE: {
      maxPrice: experiment.discounted_price_max,
      minPrice: experiment.discounted_price_min,
      newPrice: experiment.treatment_discounted_price,
      oldPrice: experiment.control_discounted_price,
    },
    END_CUSTOMER_PRICE: {
      maxPrice: null,
      minPrice: null,
      newPrice: null,
      oldPrice: null,
    },
    LIST_PRICE: {
      maxPrice: experiment.list_price_max,
      minPrice: experiment.list_price_min,
      newPrice: experiment.treatment_list_price,
      oldPrice: experiment.control_list_price,
    },
    MEMBER_PRICE: {
      maxPrice: experiment.member_price_max,
      minPrice: experiment.member_price_min,
      newPrice: experiment.treatment_member_price,
      oldPrice: experiment.control_member_price,
    },
    SUBSCRIBER_PRICE: {
      maxPrice: experiment.subscriber_price_max,
      minPrice: experiment.subscriber_price_min,
      newPrice: experiment.treatment_subscriber_price,
      oldPrice: experiment.control_subscriber_price,
    },
  };

  return priceMap[priceTarget];
}

function formatBound(
  bound: number | null,
  boundType: PriceBoundType | null,
): string | null {
  if (bound == null) {
    return null;
  }

  switch (boundType) {
    case "PERCENT":
      return formatPercentage(bound / 100);
    case "AMOUNT":
      return formatCurrency(bound);
    default:
      return String(bound);
  }
}

function getReferencePrice(
  product_prices: ProductPrices,
  price_target_type: PriceTargetType,
): MaybeNull<number> {
  let price: MaybeNull<number> = null;
  switch (price_target_type) {
    case "LIST_PRICE":
      price = product_prices.list_price;
      break;
    case "MEMBER_PRICE":
      price = product_prices.member_price;
      break;
    case "SUBSCRIBER_PRICE":
      price = product_prices.subscriber_price;
      break;
    case "DISCOUNTED_PRICE":
      price = product_prices.discounted_price;
      break;
    case "END_CUSTOMER_PRICE":
      invariant(false, "End Customer Price not supported");
    default:
      assertUnreachable(price_target_type);
  }

  return price;
}

type ProductPrices = {
  discounted_price: MaybeNumber;
  list_price: MaybeNumber;
  member_price: MaybeNumber;
  subscriber_price: MaybeNumber;
};

type ConstraintsSummaryListProps = {
  competitorIntelligence: MaybeNull<
    MaybeNull<CompetitorMatchWithAveragePricesType>[][]
  >;
  constraints: PricingConstraintType[];
  experiment: GetExperimentForecastQueryResponseType;
  modelingPrice: PriceTargetType;
  priceParityGroupName?: MaybeString;
  product: ProductMasterObjectType;
  productPrices: ProductPrices;
};

export default function ConstraintsSummaryList({
  competitorIntelligence,
  constraints,
  experiment,
  product,
  productPrices,
}: ConstraintsSummaryListProps) {
  const groupedConstraints: GroupedConstraintsType =
    getGroupedConstraints(constraints);
  const { devFeaturesEnabled } = useFlags();
  const { productIndex } = useProductDetailSidePanelStore();
  const competitor =
    productIndex != null ? competitorIntelligence?.[productIndex] : null;

  return (
    <Col className="constraints-summary gap-8">
      {Object.entries(groupedConstraints).map(
        ([priceTargetType, { roundingConstraints, ruleGroups }]) => {
          let roundingRule: string | null = null;
          if (roundingConstraints.length === 1) {
            roundingRule = roundingConstraints[0].round_to.join(", ");
          } else if (roundingConstraints.length > 1) {
            roundingRule = "Custom rounding applied";
          }
          if (!roundingRule?.length) {
            roundingRule = "--";
          }

          const { maxPrice, minPrice, newPrice, oldPrice } =
            getPricesForPriceTarget(
              experiment,
              priceTargetType as PriceTargetType,
            );

          return (
            <div key={priceTargetType}>
              <Row className="items-start pb-2">
                <div className="flex-grow">
                  <p className="text-sm font-bold text-n-950">
                    Price range: {formatPriceRange(minPrice, maxPrice)}
                  </p>
                  <p className="text-sm font-normal text-n-800">
                    New price: {formatCurrency(newPrice)} | Previous price:{" "}
                    {formatCurrency(oldPrice)}
                  </p>
                  <p className="text-sm font-normal text-n-800">
                    Rounding: {roundingRule}
                  </p>
                </div>
                <div>
                  <p className="text-right text-sm font-bold text-n-950">
                    {snakeCaseToTitleCase(priceTargetType)}
                  </p>
                </div>
              </Row>
              {Object.entries(ruleGroups).map(([ruleGroup, constraints]) => (
                <>
                  <Row className="py-4">
                    <p className="text-sm text-n-950">{ruleGroup}</p>
                  </Row>
                  <Col className="gap-6">
                    {constraints
                      .sort((a, b) => a.priority - b.priority)
                      .map((constraint) => {
                        const isCompetitorRule =
                          constraint.type === "COMPETITOR_PRICE";
                        let title = `${snakeCaseToTitleCase(constraint.type)}`;

                        const competitorsForRule = new Set(
                          constraint.competitors,
                        );

                        if (isCompetitorRule) {
                          title = `${title}: ${[...competitorsForRule].join(
                            ", ",
                          )}`;
                        }

                        const lowerBound =
                          constraint.lower_bound != null &&
                          formatBound(
                            constraint.lower_bound,
                            constraint.bound_type,
                          );
                        const upperBound =
                          constraint.upper_bound != null &&
                          formatBound(
                            constraint.upper_bound,
                            constraint.bound_type,
                          );
                        let percentRange = null;
                        if (lowerBound && upperBound) {
                          percentRange = `Bounds: ${lowerBound} to ${upperBound}`;
                        } else if (lowerBound) {
                          percentRange = `Bounds: > ${lowerBound}`;
                        } else if (upperBound) {
                          percentRange = `Bounds: < ${upperBound}`;
                        }

                        const lowerBoundValue =
                          constraint.lower_bound_calculated != null &&
                          formatCurrency(constraint.lower_bound_calculated);
                        const upperBoundValue =
                          constraint.upper_bound_calculated != null &&
                          formatCurrency(constraint.upper_bound_calculated);
                        let priceRange = null;
                        if (lowerBoundValue && upperBoundValue) {
                          priceRange = `${lowerBoundValue} - ${upperBoundValue}`;
                        } else if (lowerBoundValue) {
                          priceRange = `> ${lowerBoundValue}`;
                        } else if (upperBoundValue) {
                          priceRange = `< ${upperBoundValue}`;
                        }

                        const constraintCopy = cloneObject(constraint);

                        const competitorReferencePrice =
                          competitor != null
                            ? getCompetitorReferencePriceValue(
                                filterEmptyValues(competitor),
                                constraintCopy,
                                {
                                  count:
                                    product.int_product_attributes?.count ??
                                    null,
                                  weight:
                                    product.int_product_attributes?.weight ??
                                    null,
                                  weight_unit:
                                    product.int_product_attributes
                                      ?.weight_unit ?? null,
                                },
                              )?.toNumber() ?? null
                            : null;

                        let referencePrice = getReferencePrice(
                          productPrices,
                          constraint.price_target_type,
                        );
                        if (constraint.type === "PRICE_COMPARISON") {
                          referencePrice = getPriceComparisonReferencePrice({
                            experiment,
                            pre_luca_product_prices: {
                              pre_luca_discounted_price:
                                experiment.pre_luca_discounted_price,
                              pre_luca_list_price:
                                experiment.pre_luca_list_price,
                              pre_luca_member_price:
                                experiment.pre_luca_member_price,
                              pre_luca_subscriber_price:
                                experiment.pre_luca_subscriber_price,
                            },
                            price_comparison_target_type:
                              constraint.comparison_price_target!,
                            price_target_type: constraint.price_target_type,
                          });
                        } else if (constraint.type === "COMPETITOR_PRICE") {
                          referencePrice = competitorReferencePrice;
                        }

                        const data: (string | null)[] = [
                          percentRange ? percentRange : null,
                          `Reference price: ${formatCurrency(referencePrice)}`,
                        ];

                        const devBlock = (
                          <div className="rounded border bg-n-100 p-2 text-xs text-n-700">
                            <p className="font-bold">DEBUG</p>
                            <p>Final Reference Price: {referencePrice}</p>
                            <p>
                              Reference Price Calculated:{" "}
                              {getReferencePrice(
                                productPrices,
                                constraint.price_target_type,
                              )}
                            </p>
                            <p>
                              Competitor Reference Price:{" "}
                              {competitorReferencePrice}
                            </p>
                            <p>Bound Type: {constraint.bound_type}</p>
                            {constraint.lower_bound_calculation_error !=
                              null && (
                              <p>
                                Lower bound calc error:{" "}
                                <span className="font-bold text-red-700">
                                  {constraint.lower_bound_calculation_error?.toString()}
                                </span>
                              </p>
                            )}
                            {constraint.upper_bound_calculation_error !=
                              null && (
                              <p>
                                Upper bound calc error:{" "}
                                <span className="font-bold text-red-700">
                                  {constraint.upper_bound_calculation_error?.toString()}
                                </span>
                              </p>
                            )}
                            {constraint.competitor_price_calculation_error !=
                              null && (
                              <p>
                                Price calc error:{" "}
                                <span className="font-bold text-red-700">
                                  {constraint.competitor_price_calculation_error?.toString()}
                                </span>
                              </p>
                            )}
                            <p>
                              constraint_met:{" "}
                              <span
                                className={cn(
                                  "font-bold",
                                  constraint.constraint_met === false &&
                                    "text-red-600",
                                  constraint.constraint_met === true &&
                                    "text-emerald-600",
                                )}
                              >
                                {constraint.constraint_met?.toString()}
                              </span>
                            </p>
                          </div>
                        );

                        return (
                          <RuleCard
                            data={data}
                            dev={devFeaturesEnabled ? devBlock : null}
                            key={constraint.id}
                            met={constraint.constraint_met}
                            priceRange={priceRange ?? ""}
                            title={title}
                          />
                        );
                      })}
                  </Col>
                </>
              ))}
            </div>
          );
        },
      )}
    </Col>
  );
}
