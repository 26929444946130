import { z } from "zod";

const MatchDetailsObject = z.object({
  brand_match: z.boolean().optional(),
  flavor_match: z.boolean().optional(),
  organic_match: z.boolean().optional(),
  size_match: z.boolean().optional(),
  variety_match: z.boolean().optional(),
});

export const MatchDetails = MatchDetailsObject.nullable();

export type MatchDetailsType = z.infer<typeof MatchDetails>;

export type MatchDetailsObjectKeys = keyof z.infer<typeof MatchDetailsObject>;

export const MatchDetailsObjectKeys = z.enum(
  Object.keys(MatchDetailsObject.shape) as [
    MatchDetailsObjectKeys,
    ...MatchDetailsObjectKeys[],
  ],
);

export const CompetitorPricingObject = z.object({
  brand_id: z.string().uuid().nullable(),
  competitor_currency: z.string().nullable(),
  competitor_discounted_price: z.number().nullable(),
  competitor_end_customer_price: z.number().nullable(),
  competitor_link: z.string().nullable(),
  competitor_list_price: z.number().nullable(),
  competitor_logo: z.string().nullable(),
  competitor_member_price: z.number().nullable(),
  competitor_name: z.string().nullable(),
  competitor_product_brand: z.string().nullable(),
  competitor_product_image_link: z.string().nullable(),
  competitor_product_name: z.string().nullable(),
  competitor_product_type: z.string().nullable(),
  competitor_service: z.string().nullable(),
  competitor_sku: z.string().nullable(),
  competitor_sku_description: z.string().nullable(),
  competitor_subscriber_price: z.number().nullable(),
  count: z.number().nullable().optional(),
  count_unit: z.string().nullable().optional(),
  country: z.string().nullable(),
  found: z.boolean().nullable(),
  id: z.string().uuid(),
  in_stock: z.boolean().nullable(),
  match_details: MatchDetails,
  match_level: z.number().nullable(),
  match_reasoning: z.string().nullable(),
  quantity: z.number().nullable(),
  secondary_quantity: z.number().nullable(),
  secondary_unit_of_measurement: z.string().nullable(),
  sku_primary: z.string().nullable(),
  sold_by: z.string().nullable(),
  timestamp: z.date().nullable(),
  unique_id: z.string().nullable(),
  unit_of_measurement: z.string().nullable(),
  weight: z.number().nullable().optional(),
  weight_unit: z.string().nullable().optional(),
});

export type CompetitorPricingObjectType = z.infer<
  typeof CompetitorPricingObject
>;
