import { CustomerSegmentType } from "src/shared/trpc/common/enum/CustomerSegment";
import { ImpactViewFilterType } from "src/shared/types/ImpactViewFilter";
import PricePlanTab from "src/shared/types/PricePlanTab";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import { MaybeUndefined } from "src/shared/types/maybe/MaybeUndefined";
import { create } from "zustand";

export type PricePlanStore = {
  customerSegment: MaybeNull<CustomerSegmentType>;
  impactViewFilter: ImpactViewFilterType;
  oneTimeDeepLinkRedirectLoading: boolean;
  pricePlanId: MaybeUndefined<string>;
  tab: MaybeUndefined<PricePlanTab>;
};

const defaultState: PricePlanStore = {
  customerSegment: null,
  impactViewFilter: "price-plan-level",
  oneTimeDeepLinkRedirectLoading: true,
  pricePlanId: undefined,
  tab: undefined,
};

const usePricePlanStore = create<PricePlanStore>(() => defaultState);

export default usePricePlanStore;
