import { CSSProperties } from "react";
import { CopyText } from "src/frontend/components/CopyText";
import ProductTagTableCell from "src/frontend/components/ProductTagTableCell";
import Row from "src/frontend/components/Row";
import SortIcon from "src/frontend/components/SortIcon";
import {
  Table,
  TableDataCell,
  TableHeader,
  TableHeaderCell,
} from "src/frontend/components/TableComponents";
import MatchDetailBadge from "src/frontend/components/product-side-panel/MatchDetailBadge";
import { cn } from "src/frontend/components/ui/utils";
import { CompetitorIntelligenceConfigMapSimple } from "src/frontend/constants/UserColumnVisibilityConfig";
import useCompetitorIntelligenceColumnVisibility from "src/frontend/hooks/columnVisibilityHooks/useCompetitorIntelligenceColumnVisibility";
import useBreakpoints from "src/frontend/hooks/useBreakpoints";
import useIsDarkTheme from "src/frontend/hooks/useIsDarkTheme";
import useCompetitorIntelligenceFiltersStore from "src/frontend/stores/useCompetitorIntelligenceFiltersStore";
import useProductDetailSidePanelStore, {
  ProductDetailSidePanelTab,
} from "src/frontend/stores/useProductDetailSidePanelStore";
import GenericTrpcRefetchFunction from "src/frontend/types/GenericTrpcRefetchFunction";
import getBorderRightForColumns from "src/frontend/utils/table/getBorderRightForColumns";
import getStickyColumnStyles from "src/frontend/utils/table/getStickyColumnStyles";
import getTableRowColSpan from "src/frontend/utils/table/getTableRowColSpan";
import { CompetitorMatchWithAveragePricesType } from "src/shared/trpc/common/CompetitorMatchWithAveragePrices";
import { ProductMasterObjectSortKeyType } from "src/shared/trpc/common/ProductMasterSortKey";
import { CompetitorIntelligenceVisibilityFiltersType } from "src/shared/trpc/common/UserCommonVisibilityFilters";
import { PriceTargetType } from "src/shared/trpc/common/enum/PriceTargetType";
import { CompetitorIntelligenceType } from "src/shared/trpc/queries/getCompetitorIntelligenceQuerySchema";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import MaybeNumber from "src/shared/types/maybe/MaybeNumber";
import arrayEmpty from "src/shared/utils/arrays/arrayEmpty";
import arrayNotEmpty from "src/shared/utils/arrays/arrayNotEmpty";
import filterEmptyValues from "src/shared/utils/arrays/filterEmptyValues";
import sortNumerically from "src/shared/utils/arrays/sortNumerically";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";
import getCompetitorPriceFromRecentPriceType from "src/shared/utils/getCompetitorPriceFromRecentPriceType";
import getCompetitorUnits from "src/shared/utils/getCompetitorUnits";
import { getFormattedFinalPricePerUnit } from "src/shared/utils/getFormattedFinalPricePerUnit";
import formatCurrency from "src/shared/utils/numbers/formatCurrency";
import formatNumberRounded from "src/shared/utils/numbers/formatNumberRounded";
import formatPercentage from "src/shared/utils/numbers/formatPercentage";
import numberOrNull from "src/shared/utils/numbers/numberOrNull";

const PIXEL_OFFSET_PER_CHARACTER = 14;

const tagWidthStyles: CSSProperties = {
  width: "auto",
};

const MIN_WIDTH = 100;
const minWidthStyles: CSSProperties = {
  minWidth: MIN_WIDTH,
};

function getCompetitorPrice(
  data: MaybeNull<CompetitorMatchWithAveragePricesType>,
  priceTarget: PriceTargetType,
): MaybeNumber {
  if (data == null) {
    return null;
  }

  switch (priceTarget) {
    case "LIST_PRICE":
      return data.competitor_list_price;
    case "MEMBER_PRICE":
      return data.competitor_member_price;
    case "SUBSCRIBER_PRICE":
      return data.competitor_subscriber_price;
    case "DISCOUNTED_PRICE":
      return data.competitor_discounted_price;
    case "END_CUSTOMER_PRICE":
      return data.competitor_end_customer_price;
    default:
      return assertUnreachable(priceTarget);
  }
}

type CompetitorIntelligenceTableProps = {
  competitorNamesList: string[];
  data: CompetitorIntelligenceType[];
  refetchProducts: GenericTrpcRefetchFunction;
  visibilityFilters: CompetitorIntelligenceVisibilityFiltersType;
};

export default function CompetitorIntelligenceTable({
  competitorNamesList,
  data,
  refetchProducts,
  visibilityFilters,
}: CompetitorIntelligenceTableProps) {
  const isDarkTheme = useIsDarkTheme();
  const breakpointState = useBreakpoints();
  const sortBy = useCompetitorIntelligenceFiltersStore((state) => state.sortBy);
  const sortDirection = useCompetitorIntelligenceFiltersStore(
    (state) => state.sortDirection,
  );
  const recentPriceOption = useCompetitorIntelligenceFiltersStore(
    (state) => state.recentPriceOption,
  );
  const displayPriceTarget = useCompetitorIntelligenceFiltersStore(
    (state) => state.displayPriceTarget,
  );

  const {
    hasCategory: hasCategories,
    hasCost,
    hasDiscountedPrice,
    hasDiscountedPriceContributionMargin,
    hasDiscountedPriceMargin,
    hasFinalPricePerUnit,
    hasInventory,
    hasListPrice,
    hasListPriceContributionMargin,
    hasListPriceMargin,
    hasMemberPrice,
    hasMemberPriceContributionMargin,
    // TODO-[MIKE]: contribution margin - competitor intelligence
    hasMemberPriceMargin,
    hasPriceZone,
    hasProductBrandName,
    hasProductSku,
    hasSubcategory: hasSubcategories,
    hasSubscriberPrice,
    hasSubscriberPriceContributionMargin,
    hasSubscriberPriceMargin,
    hasSupplier,
    hasTags,
  } = useCompetitorIntelligenceColumnVisibility(visibilityFilters);

  const brandColSpan = getTableRowColSpan([hasProductSku, hasPriceZone], 2);

  const [skuWidth, productIdWidth, productNameWidth, priceZoneWidth] = [
    75,
    hasProductSku ? 100 : 0,
    225,
    hasPriceZone ? 120 : 0,
  ];

  const productIdOffset = skuWidth;
  const productNameOffset = skuWidth + productIdWidth;
  const priceZoneOffset = skuWidth + productIdWidth + productNameWidth;
  const stickyColumnStyles = getStickyColumnStyles({
    breakpointState,
    isDarkTheme,
    lineWeight: 1,
  });
  const skuStickyColumnStyles = getStickyColumnStyles({
    breakpointState,
    isDarkTheme,
    lineWeight: 0,
    width: skuWidth,
  });
  const productIdStickyColumnStyles = getStickyColumnStyles({
    breakpointState,
    isDarkTheme,
    lineWeight: 0,
    width: productIdWidth,
  });
  const productNameStickyColumnStyles = getStickyColumnStyles({
    breakpointState,
    isDarkTheme,
    lineWeight: hasPriceZone ? 0 : 1,
    width: productNameWidth,
  });
  const priceZoneStickyColumnStyles = getStickyColumnStyles({
    breakpointState,
    isDarkTheme,
    lineWeight: hasPriceZone ? 1 : 0,
    width: priceZoneWidth,
  });

  const toggleSortKey = (key: ProductMasterObjectSortKeyType) => {
    if (sortBy === key) {
      useCompetitorIntelligenceFiltersStore.setState((state) => {
        return {
          page: 1,
          sortDirection: state.sortDirection === "asc" ? "desc" : "asc",
        };
      });
    } else {
      useCompetitorIntelligenceFiltersStore.setState({
        page: 1,
        sortBy: key,
        sortDirection: "desc",
      });
    }
  };

  const onClickRow = (
    index: number,
    competitorMatchIndex: MaybeNumber,
    tab: ProductDetailSidePanelTab = "Competitor Pricing",
  ) =>
    useProductDetailSidePanelStore.setState({
      competitorMatchIndex,
      competitorNamesList,
      competitorPricingData: data.map((val) => val.competitorPricing),
      isOpen: true,
      productIndex: index,
      products: data.map((val) => val.product),
      tab,
    });

  const sortedCompetitorPrices = sortNumerically(
    filterEmptyValues(
      data.flatMap((val) =>
        val.competitorPricing.map((comp) =>
          getCompetitorPrice(comp, displayPriceTarget),
        ),
      ),
    ).map((val) => Number(val)),
  ).reverse();
  const maxCompetitorPriceLength = arrayNotEmpty(sortedCompetitorPrices)
    ? String(Math.floor(sortedCompetitorPrices[0])).length
    : null;

  const priceCellWidth =
    maxCompetitorPriceLength != null
      ? Math.max(PIXEL_OFFSET_PER_CHARACTER * maxCompetitorPriceLength, 48)
      : "auto";

  const priceKey =
    displayPriceTarget.toLowerCase() as ProductMasterObjectSortKeyType;

  const productDefinitionCols = [
    hasProductBrandName,
    hasCategories,
    hasSubcategories,
    hasSupplier,
    hasTags,
  ];
  const productDefinitionColSpan = getTableRowColSpan(productDefinitionCols);
  const {
    category: hasCategoryBorderRight,
    product_brand_name: hasProductBrandNameBorderRight,
    subcategory: hasSubcategoryBorderRight,
    supplier: hasSupplierBorderRight,
    tags: hasTagsBorderRight,
  } = getBorderRightForColumns(
    CompetitorIntelligenceConfigMapSimple["Product definition"],
    productDefinitionCols,
  );

  const currentPricingCols = [
    hasListPrice,
    hasDiscountedPrice,
    hasMemberPrice,
    hasSubscriberPrice,
    hasInventory,
    hasListPriceMargin,
    hasListPriceContributionMargin,
    hasDiscountedPriceMargin,
    hasDiscountedPriceContributionMargin,
    hasMemberPriceMargin,
    // TODO-[MIKE]: contribution margin - competitor intelligence
    hasMemberPriceContributionMargin,
    hasSubscriberPriceMargin,
    hasSubscriberPriceContributionMargin,
    hasCost,
    hasFinalPricePerUnit,
  ];
  const currentPricingColSpan = getTableRowColSpan(currentPricingCols);
  // TODO-[MIKE]: contribution margin - competitor intelligence
  const {
    cost: hasCostBorderRight,
    discounted_price: hasDiscountedPriceBorderRight,
    discounted_price_contribution_margin:
      hasDiscountedPriceContributionMarginBorderRight,
    discounted_price_margin: hasDiscountedPriceMarginBorderRight,
    final_price_per_unit: hasFinalPricePerUnitBorderRight,
    inventory: hasInventoryBorderRight,
    list_price: hasListPriceBorderRight,
    list_price_contribution_margin: hasListPriceContributionMarginBorderRight,
    list_price_margin: hasListPriceMarginBorderRight,
    member_price: hasMemberPriceBorderRight,
    member_price_contribution_margin:
      hasMemberPriceContributionMarginBorderRight,
    member_price_margin: hasMemberPriceMarginBorderRight,
    subscriber_price: hasSubscriberPriceBorderRight,
    subscriber_price_contribution_margin:
      hasSubscriberPriceContributionMarginBorderRight,
    subscriber_price_margin: hasSubscriberPriceMarginBorderRight,
  } = getBorderRightForColumns(
    CompetitorIntelligenceConfigMapSimple["Current pricing information"],
    currentPricingCols,
  );

  return (
    <Table className="bg-card">
      <TableHeader>
        <tr className="border-b align-top">
          <TableHeaderCell
            colSpan={brandColSpan}
            style={{ ...stickyColumnStyles, left: 0 }}
          >
            <Row className="justify-center">
              <p>My Brand</p>
            </Row>
          </TableHeaderCell>
          {productDefinitionColSpan > 0 && (
            <TableHeaderCell
              borderRight
              className="text-center"
              colSpan={productDefinitionColSpan}
            >
              Product Definition
            </TableHeaderCell>
          )}
          {currentPricingColSpan > 0 && (
            <TableHeaderCell
              borderRight
              className="text-center"
              colSpan={currentPricingColSpan}
            >
              Current Pricing Information
            </TableHeaderCell>
          )}
          {competitorNamesList.map((competitor, index) => {
            return (
              <TableHeaderCell
                borderRight
                colSpan={hasFinalPricePerUnit ? 3 : 2}
                key={`${competitor}-${index}`}
                style={minWidthStyles}
              >
                {competitor}
              </TableHeaderCell>
            );
          })}
        </tr>
        <tr className="border-b">
          <TableHeaderCell
            className="px-2 py-1 text-center hover:cursor-pointer"
            onClick={() => toggleSortKey("sku")}
            style={{ ...skuStickyColumnStyles, left: 0 }}
          >
            <Row className="items-start">
              <p className="pl-1">SKU</p>
              <SortIcon
                direction={sortDirection}
                selectedKey={sortBy}
                sortBy="sku"
              />
            </Row>
          </TableHeaderCell>
          {hasProductSku && (
            <TableHeaderCell
              className="px-2 py-1 text-center hover:cursor-pointer"
              onClick={() => toggleSortKey("product_sku")}
              style={{ ...productIdStickyColumnStyles, left: productIdOffset }}
            >
              <Row className="items-start">
                <p>Product Id</p>
                <SortIcon
                  direction={sortDirection}
                  selectedKey={sortBy}
                  sortBy="product_sku"
                />
              </Row>
            </TableHeaderCell>
          )}
          <TableHeaderCell
            className="px-2 py-1 text-center hover:cursor-pointer"
            onClick={() => toggleSortKey("product_name")}
            style={{
              ...productNameStickyColumnStyles,
              left: productNameOffset,
            }}
          >
            <Row className="items-start">
              <p>Product Name</p>
              <SortIcon
                direction={sortDirection}
                selectedKey={sortBy}
                sortBy="product_name"
              />
            </Row>
          </TableHeaderCell>
          {hasPriceZone && (
            <TableHeaderCell
              className="hover:cursor-pointer"
              onClick={() => toggleSortKey("price_zone")}
              style={{
                ...priceZoneStickyColumnStyles,
                left: priceZoneOffset,
              }}
            >
              <Row className="items-start">
                <p>Price Zone</p>
                <SortIcon
                  direction={sortDirection}
                  selectedKey={sortBy}
                  sortBy="price_zone"
                />
              </Row>
            </TableHeaderCell>
          )}
          {hasProductBrandName && (
            <TableHeaderCell
              borderRight={hasProductBrandNameBorderRight}
              className="hover:cursor-pointer"
              onClick={() => toggleSortKey("product_brand_name")}
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Product Brand Name</p>
                <SortIcon
                  direction={sortDirection}
                  selectedKey={sortBy}
                  sortBy="product_brand_name"
                />
              </Row>
            </TableHeaderCell>
          )}
          {hasCategories && (
            <TableHeaderCell
              borderRight={hasCategoryBorderRight}
              className="hover:cursor-pointer"
              onClick={() => toggleSortKey("categories")}
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Category</p>
                <SortIcon
                  direction={sortDirection}
                  selectedKey={sortBy}
                  sortBy="categories"
                />
              </Row>
            </TableHeaderCell>
          )}
          {hasSubcategories && (
            <TableHeaderCell
              borderRight={hasSubcategoryBorderRight}
              className="hover:cursor-pointer"
              onClick={() => toggleSortKey("sub_categories")}
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Subcategory</p>
                <SortIcon
                  direction={sortDirection}
                  selectedKey={sortBy}
                  sortBy="sub_categories"
                />
              </Row>
            </TableHeaderCell>
          )}
          {hasSupplier && (
            <TableHeaderCell
              borderRight={hasSupplierBorderRight}
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Supplier</p>
              </Row>
            </TableHeaderCell>
          )}
          {hasTags && (
            <TableHeaderCell
              borderRight={hasTagsBorderRight}
              style={tagWidthStyles}
            >
              <Row className="items-start">
                <p>Tags</p>
              </Row>
            </TableHeaderCell>
          )}
          {hasListPrice && (
            <TableHeaderCell
              borderRight={hasListPriceBorderRight}
              className="hover:cursor-pointer"
              onClick={() => toggleSortKey(priceKey)}
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Price</p>
                <SortIcon
                  direction={sortDirection}
                  selectedKey={sortBy}
                  sortBy={priceKey}
                />
              </Row>
            </TableHeaderCell>
          )}
          {hasDiscountedPrice && (
            <TableHeaderCell
              borderRight={hasDiscountedPriceBorderRight}
              className="hover:cursor-pointer"
              onClick={() => toggleSortKey("discounted_price")}
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Discounted Price</p>
                <SortIcon
                  direction={sortDirection}
                  selectedKey={sortBy}
                  sortBy="discounted_price"
                />
              </Row>
            </TableHeaderCell>
          )}
          {hasMemberPrice && (
            <TableHeaderCell
              borderRight={hasMemberPriceBorderRight}
              className="hover:cursor-pointer"
              onClick={() => toggleSortKey("member_price")}
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Member Price</p>
                <SortIcon
                  direction={sortDirection}
                  selectedKey={sortBy}
                  sortBy="member_price"
                />
              </Row>
            </TableHeaderCell>
          )}
          {hasSubscriberPrice && (
            <TableHeaderCell
              borderRight={hasSubscriberPriceBorderRight}
              className="hover:cursor-pointer"
              onClick={() => toggleSortKey("subscriber_price")}
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Subscriber Price</p>
                <SortIcon
                  direction={sortDirection}
                  selectedKey={sortBy}
                  sortBy="subscriber_price"
                />
              </Row>
            </TableHeaderCell>
          )}
          {hasInventory && (
            <TableHeaderCell
              borderRight={hasInventoryBorderRight}
              className="hover:cursor-pointer"
              onClick={() => toggleSortKey("inventory_level")}
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Inventory</p>
                <SortIcon
                  direction={sortDirection}
                  selectedKey={sortBy}
                  sortBy="inventory_level"
                />
              </Row>
            </TableHeaderCell>
          )}
          {hasListPriceMargin && (
            <TableHeaderCell
              borderRight={hasListPriceMarginBorderRight}
              className="hover:cursor-pointer"
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Margin</p>
              </Row>
            </TableHeaderCell>
          )}
          {hasListPriceContributionMargin && (
            <TableHeaderCell
              borderRight={hasListPriceContributionMarginBorderRight}
              className="hover:cursor-pointer"
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Contribution Margin</p>
              </Row>
            </TableHeaderCell>
          )}
          {hasDiscountedPriceMargin && (
            <TableHeaderCell
              borderRight={hasDiscountedPriceMarginBorderRight}
              className="hover:cursor-pointer"
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Discounted Price Margin</p>
              </Row>
            </TableHeaderCell>
          )}
          {hasDiscountedPriceContributionMargin && (
            <TableHeaderCell
              borderRight={hasDiscountedPriceContributionMarginBorderRight}
              className="hover:cursor-pointer"
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Discounted Price Contribution Margin</p>
              </Row>
            </TableHeaderCell>
          )}
          {hasMemberPriceMargin && (
            <TableHeaderCell
              borderRight={hasMemberPriceMarginBorderRight}
              className="hover:cursor-pointer"
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Member Price Margin</p>
              </Row>
            </TableHeaderCell>
          )}
          {/* // TODO-[MIKE]: contribution margin - competitor intelligence */}
          {hasMemberPriceContributionMargin && (
            <TableHeaderCell
              borderRight={hasMemberPriceContributionMarginBorderRight}
              className="hover:cursor-pointer"
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Member Price Contribution Margin</p>
              </Row>
            </TableHeaderCell>
          )}
          {hasSubscriberPriceMargin && (
            <TableHeaderCell
              borderRight={hasSubscriberPriceMarginBorderRight}
              className="hover:cursor-pointer"
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Subscriber Price Margin</p>
              </Row>
            </TableHeaderCell>
          )}
          {hasSubscriberPriceContributionMargin && (
            <TableHeaderCell
              borderRight={hasSubscriberPriceContributionMarginBorderRight}
              className="hover:cursor-pointer"
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Subscriber Price Contribution Margin</p>
              </Row>
            </TableHeaderCell>
          )}
          {hasCost && (
            <TableHeaderCell
              borderRight={hasCostBorderRight}
              className="hover:cursor-pointer"
              onClick={() => toggleSortKey("cpu")}
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Cost Per Unit</p>
                <SortIcon
                  direction={sortDirection}
                  selectedKey={sortBy}
                  sortBy="cpu"
                />
              </Row>
            </TableHeaderCell>
          )}
          {hasFinalPricePerUnit && (
            <TableHeaderCell
              borderRight={hasFinalPricePerUnitBorderRight}
              className="hover:cursor-pointer"
              style={minWidthStyles}
            >
              <Row className="items-start">
                <p>Final Price Per Unit</p>
              </Row>
            </TableHeaderCell>
          )}
          {competitorNamesList.map((competitor, competitorIndex) => {
            return (
              <>
                <TableHeaderCell
                  borderLeft={competitorIndex === 0}
                  className="px-2 py-1"
                  key={`${competitor}-price`}
                  style={{ minWidth: MIN_WIDTH / 2 }}
                >
                  <p>Price</p>
                </TableHeaderCell>
                {hasFinalPricePerUnit && (
                  <TableHeaderCell
                    className="px-2 py-1"
                    key={`${competitor}-final-price-per-unit`}
                    style={{ minWidth: MIN_WIDTH / 2 }}
                  >
                    <p>Final Price Per Unit</p>
                  </TableHeaderCell>
                )}
                <TableHeaderCell
                  borderRight
                  className="px-2 py-1"
                  key={`${competitor}-match-level`}
                  style={{ minWidth: MIN_WIDTH / 2 }}
                >
                  <p>Match Level</p>
                </TableHeaderCell>
              </>
            );
          })}
        </tr>
      </TableHeader>
      <tbody>
        {data.map(({ competitorPricing, product }, dataIndex) => {
          const quantity = numberOrNull(product.int_product_attributes?.weight);
          const count = numberOrNull(product.int_product_attributes?.count);
          const unit =
            (product.int_product_attributes?.weight_unit as
              | string
              | undefined) ?? "";

          let includeBorderTop = false;
          if (dataIndex >= 0) {
            switch (sortBy) {
              case "product_sku":
                includeBorderTop =
                  product.product_sku !==
                  data[dataIndex - 1]?.product.product_sku;
                break;
              case "product_name":
                includeBorderTop =
                  product.product_name !==
                  data[dataIndex - 1]?.product.product_name;
                break;
            }
          }
          return (
            <tr
              className={cn(
                "cursor-pointer last:border-b-0 [&:hover>*]:bg-n-100 [&>*]:duration-150",
                includeBorderTop && "border-t",
              )}
              key={product.id}
              onClick={() => onClickRow(dataIndex, 1, "Competitor Pricing")}
            >
              <TableDataCell
                className="bg-n-0"
                style={{
                  ...skuStickyColumnStyles,
                  left: 0,
                }}
              >
                <CopyText includeTooltip isCode textToCopy={product.sku}>
                  <p className="truncate text-ellipsis text-left text-sm">
                    {product.sku}
                  </p>
                </CopyText>
              </TableDataCell>
              {hasProductSku && (
                <TableDataCell
                  className="bg-n-0"
                  style={{
                    ...productIdStickyColumnStyles,
                    left: productIdOffset,
                  }}
                >
                  <CopyText
                    includeTooltip
                    isCode
                    textToCopy={product.product_sku}
                  >
                    <p className="truncate text-ellipsis text-left text-sm">
                      {product.product_sku}
                    </p>
                  </CopyText>
                </TableDataCell>
              )}
              <TableDataCell
                className="bg-n-0"
                style={{
                  ...productNameStickyColumnStyles,
                  left: productNameOffset,
                }}
              >
                {product.product_name}
              </TableDataCell>
              {hasPriceZone && (
                <TableDataCell
                  maxWidth="max-w-[8ch]"
                  style={{
                    ...priceZoneStickyColumnStyles,
                    left: priceZoneOffset,
                  }}
                >
                  {product.price_zone ?? "--"}
                </TableDataCell>
              )}
              {hasProductBrandName && (
                <TableDataCell
                  borderRight={hasProductBrandNameBorderRight}
                  maxWidth="max-w-[20ch]"
                  style={minWidthStyles}
                >
                  {product.product_brand_name ?? "--"}
                </TableDataCell>
              )}
              {hasCategories && (
                <TableDataCell
                  borderRight={hasCategoryBorderRight}
                  maxWidth="max-w-[8ch]"
                  style={minWidthStyles}
                >
                  {arrayEmpty(product.categories)
                    ? "--"
                    : product.categories.join(", ")}
                </TableDataCell>
              )}
              {hasSubcategories && (
                <TableDataCell
                  borderRight={hasSubcategoryBorderRight}
                  maxWidth="max-w-[8ch]"
                  style={minWidthStyles}
                >
                  {arrayEmpty(product.sub_categories)
                    ? "--"
                    : product.sub_categories.join(", ")}
                </TableDataCell>
              )}
              {hasSupplier && (
                <TableDataCell
                  borderRight={hasSupplierBorderRight}
                  maxWidth="max-w-[8ch]"
                  style={minWidthStyles}
                >
                  {product.supplier ?? "--"}
                </TableDataCell>
              )}
              {hasTags && (
                <TableDataCell
                  borderRight={hasTagsBorderRight}
                  onClick={(e) => e.stopPropagation()}
                  style={tagWidthStyles}
                >
                  <ProductTagTableCell
                    key={product.id}
                    numberOfTagsToShow={3}
                    productId={product.id}
                    productName={product.product_name}
                    refetchFn={refetchProducts}
                    tags={product.tags}
                  />
                </TableDataCell>
              )}
              {hasListPrice && (
                <TableDataCell
                  borderRight={hasListPriceBorderRight}
                  style={minWidthStyles}
                >
                  <Row className="justify-between">
                    <p>{formatCurrency(product.list_price)}</p>
                  </Row>
                </TableDataCell>
              )}
              {hasDiscountedPrice && (
                <TableDataCell
                  borderRight={hasDiscountedPriceBorderRight}
                  style={minWidthStyles}
                >
                  {formatCurrency(product.discounted_price)}
                </TableDataCell>
              )}
              {hasMemberPrice && (
                <TableDataCell
                  borderRight={hasMemberPriceBorderRight}
                  style={minWidthStyles}
                >
                  {formatCurrency(product.member_price)}
                </TableDataCell>
              )}
              {hasSubscriberPrice && (
                <TableDataCell
                  borderRight={hasSubscriberPriceBorderRight}
                  style={minWidthStyles}
                >
                  {formatCurrency(product.subscriber_price)}
                </TableDataCell>
              )}
              {hasInventory && (
                <TableDataCell
                  borderRight={hasInventoryBorderRight}
                  style={minWidthStyles}
                >
                  {formatNumberRounded(product.inventory_level)}
                </TableDataCell>
              )}
              {hasListPriceMargin && (
                <TableDataCell
                  borderRight={hasListPriceMarginBorderRight}
                  style={minWidthStyles}
                >
                  {formatPercentage(product.margin)}
                </TableDataCell>
              )}
              {hasListPriceContributionMargin && (
                <TableDataCell
                  borderRight={hasListPriceContributionMarginBorderRight}
                  style={minWidthStyles}
                >
                  {formatCurrency(product.contribution_margin)}
                </TableDataCell>
              )}
              {hasDiscountedPriceMargin && (
                <TableDataCell
                  borderRight={hasDiscountedPriceMarginBorderRight}
                  style={minWidthStyles}
                >
                  {formatPercentage(product.discounted_price_margin)}
                </TableDataCell>
              )}
              {hasDiscountedPriceContributionMargin && (
                <TableDataCell
                  borderRight={hasDiscountedPriceContributionMarginBorderRight}
                  style={minWidthStyles}
                >
                  {formatCurrency(product.discounted_price_contribution_margin)}
                </TableDataCell>
              )}
              {hasMemberPriceMargin && (
                <TableDataCell
                  borderRight={hasMemberPriceMarginBorderRight}
                  style={minWidthStyles}
                >
                  {formatPercentage(product.member_margin)}
                </TableDataCell>
              )}
              {/* // TODO-[MIKE]: contribution margin - competitor intelligence */}
              {hasMemberPriceContributionMargin && (
                <TableDataCell
                  borderRight={hasMemberPriceContributionMarginBorderRight}
                  style={minWidthStyles}
                >
                  {formatCurrency(product.member_price_contribution_margin)}
                </TableDataCell>
              )}
              {hasSubscriberPriceMargin && (
                <TableDataCell
                  borderRight={hasSubscriberPriceMarginBorderRight}
                  style={minWidthStyles}
                >
                  {formatPercentage(product.subscriber_price_margin)}
                </TableDataCell>
              )}
              {hasSubscriberPriceContributionMargin && (
                <TableDataCell
                  borderRight={hasSubscriberPriceContributionMarginBorderRight}
                  style={minWidthStyles}
                >
                  {formatCurrency(product.subscriber_price_contribution_margin)}
                </TableDataCell>
              )}
              {hasCost && (
                <TableDataCell
                  borderRight={hasCostBorderRight}
                  style={minWidthStyles}
                >
                  {formatCurrency(product.cpu)}
                </TableDataCell>
              )}
              {hasFinalPricePerUnit && (
                <TableDataCell
                  borderRight={hasFinalPricePerUnitBorderRight}
                  style={minWidthStyles}
                >
                  {getFormattedFinalPricePerUnit({
                    count,
                    prices: [
                      product.list_price,
                      product.discounted_price,
                      product.member_price,
                      product.subscriber_price,
                    ],
                    quantity,
                    unit,
                  })}
                </TableDataCell>
              )}
              {competitorPricing.map((competitor, competitorIndex) => {
                const {
                  countValue: competitorCountValue,
                  weightUom: competitorWeightUom,
                  weightValue: competitorWeightValue,
                } = getCompetitorUnits({
                  quantity: competitor?.quantity ?? null,
                  secondaryQuantity: competitor?.secondary_quantity ?? null,
                  secondaryUom:
                    competitor?.secondary_unit_of_measurement ?? null,
                  uom: competitor?.unit_of_measurement ?? null,
                });
                if (competitor == null) {
                  return (
                    <>
                      <TableDataCell
                        borderLeft={competitorIndex === 0}
                        key={`${competitorIndex}-price`}
                        onClick={(e) => {
                          e.stopPropagation();
                          onClickRow(
                            dataIndex,
                            competitorIndex + 1,
                            "Competitor Pricing",
                          );
                        }}
                        style={{ minWidth: MIN_WIDTH / 2 }}
                      >
                        <p>--</p>
                      </TableDataCell>
                      {hasFinalPricePerUnit && (
                        <TableDataCell
                          key={`${competitorIndex}-final-price-per-unit`}
                          onClick={(e) => {
                            e.stopPropagation();
                            onClickRow(
                              dataIndex,
                              competitorIndex + 1,
                              "Competitor Pricing",
                            );
                          }}
                          style={{ minWidth: MIN_WIDTH / 2 }}
                        >
                          <p>--</p>
                        </TableDataCell>
                      )}
                      <TableDataCell
                        borderRight
                        className="text-right"
                        key={`${competitorIndex}-match-level`}
                        onClick={(e) => {
                          e.stopPropagation();
                          onClickRow(
                            dataIndex,
                            competitorIndex + 1,
                            "Competitor Pricing",
                          );
                        }}
                        style={{ minWidth: MIN_WIDTH / 2 }}
                      >
                        <p>--</p>
                      </TableDataCell>
                    </>
                  );
                }

                const inStock = competitor.in_stock;
                const price = getCompetitorPriceFromRecentPriceType(
                  competitor,
                  recentPriceOption,
                  displayPriceTarget,
                );

                return (
                  <>
                    <TableDataCell
                      borderLeft={competitorIndex === 0}
                      key={`${competitor.id}-price`}
                      onClick={(e) => {
                        e.stopPropagation();
                        onClickRow(
                          dataIndex,
                          competitorIndex + 1,
                          "Competitor Pricing",
                        );
                      }}
                      style={{ minWidth: MIN_WIDTH / 2 }}
                    >
                      <Row className="gap-1">
                        <p
                          className={cn(inStock === false && "text-muted")}
                          style={{
                            width: priceCellWidth,
                          }}
                        >
                          {formatCurrency(price)}
                        </p>
                      </Row>
                    </TableDataCell>
                    {hasFinalPricePerUnit && (
                      <TableDataCell
                        key={`${competitor.id}-final-price-per-unit`}
                        onClick={(e) => {
                          e.stopPropagation();
                          onClickRow(
                            dataIndex,
                            competitorIndex + 1,
                            "Competitor Pricing",
                          );
                        }}
                        style={{ minWidth: MIN_WIDTH / 2 }}
                      >
                        <p>
                          {getFormattedFinalPricePerUnit({
                            count: competitorCountValue,
                            prices: [
                              competitor.competitor_list_price,
                              competitor.competitor_discounted_price,
                              competitor.competitor_member_price,
                              competitor.competitor_subscriber_price,
                            ],
                            quantity: competitorWeightValue,
                            unit: competitorWeightUom ?? "",
                          })}
                        </p>
                      </TableDataCell>
                    )}
                    <TableDataCell
                      borderRight
                      key={`${competitor.id}-match-level`}
                      onClick={(e) => {
                        e.stopPropagation();
                        onClickRow(
                          dataIndex,
                          competitorIndex + 1,
                          "Competitor Pricing",
                        );
                      }}
                      style={{ minWidth: MIN_WIDTH / 2 }}
                    >
                      <MatchDetailBadge
                        matchDetails={competitor.match_details}
                        matchLevel={competitor.match_level}
                      />
                    </TableDataCell>
                  </>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}
