import { useState } from "react";
import { ScorecardImpactType } from "src/backend/prisma-utils/getScorecardImpactForBrand";
import Col from "src/frontend/components/Col";
import Row from "src/frontend/components/Row";
import CardWithContent from "src/frontend/components/ui/CardWithContent";
import { Separator } from "src/frontend/components/ui/Separator";
import { Tabs, TabsList, TabsTrigger } from "src/frontend/components/ui/Tabs";
import useBrandStore, { BrandStore } from "src/frontend/stores/useBrandStore";
import len from "src/shared/utils/arrays/len";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";
import formatCurrency from "src/shared/utils/numbers/formatCurrency";

type ScorecardProps = {
  brand_scorecard: ScorecardImpactType;
  company_scorecard: ScorecardImpactType;
};

const SCORECARD_TABS = ["company", "brand"] as const;

type ScorecardTab = (typeof SCORECARD_TABS)[number];

function renderTabTitle(tab: ScorecardTab, brand: BrandStore): string {
  switch (tab) {
    case "brand":
      return `${brand.brand_name} 30 Day Scorecard`;
    case "company":
      return `${brand.company_name} 30 Day Scorecard`;
    default:
      return assertUnreachable(tab);
  }
}

export default function Scorecard({
  brand_scorecard,
  company_scorecard,
}: ScorecardProps) {
  const brand = useBrandStore();
  const [tab, setTab] = useState<ScorecardTab>("company");
  const data = tab === "company" ? company_scorecard : brand_scorecard;
  const includeBrandScorecard = len(brand.company_brand_ids) > 1;
  const TABS = includeBrandScorecard ? SCORECARD_TABS : [SCORECARD_TABS[0]];
  return (
    <CardWithContent cardContentClassName="p-0" className="flex-grow basis-0">
      <Tabs
        defaultValue={tab}
        onValueChange={(val) => setTab(val as ScorecardTab)}
        value={tab}
      >
        <TabsList className="flex border-b">
          {TABS.map((tab) => {
            return (
              <TabsTrigger
                className="w-[248px] flex-1 rounded-t-lg px-2 sm:px-6"
                key={tab}
                value={tab}
              >
                <p className="whitespace-break-spaces text-xs sm:text-base">
                  {renderTabTitle(tab, brand)}
                </p>
              </TabsTrigger>
            );
          })}
        </TabsList>

        <Row className="justify-evenly gap-4 p-6">
          <Col className="items-center gap-1">
            <p className="text-sm">Revenue Impact</p>
            <p className="text-3xl font-bold">
              {formatCurrency(data.revenue_impact, 0)}
            </p>
          </Col>

          <Separator className="h-[44px]" orientation="vertical" />

          <Col className="items-center gap-1">
            <p className="text-sm">Profit Impact</p>
            <p className="text-3xl font-bold">
              {formatCurrency(data.profit_impact, 0)}
            </p>
          </Col>
        </Row>
      </Tabs>
    </CardWithContent>
  );
}
