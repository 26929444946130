import { experiment_status } from "@prisma/client";
import { useEffect, useMemo, useRef, useState } from "react";
import useGetExperimentIDsAndStatusesForForecastQuery from "src/frontend/hooks/queries/useGetExperimentIDsAndStatusesForForecastQuery";
import invariant from "tiny-invariant";

export default function useSelected() {
  const [selected, setSelected] = useState(new Set<string>());

  const availableExperiments = useGetExperimentIDsAndStatusesForForecastQuery();

  const availableExperimentsSet = useMemo(
    () =>
      new Set(
        availableExperiments.data?.map(({ experiment_id }) => experiment_id),
      ),
    [availableExperiments.data],
  );

  const previousAvailableExperimentsSet = useRef<Set<string>>(new Set());

  useEffect(() => {
    const availableExperimentIdsHaveChanged =
      previousAvailableExperimentsSet.current?.size !==
        availableExperimentsSet.size ||
      ![...previousAvailableExperimentsSet.current].every((value) =>
        availableExperimentsSet.has(value),
      );

    if (availableExperimentIdsHaveChanged) {
      previousAvailableExperimentsSet.current = availableExperimentsSet;
      setSelected(new Set());
    }
  }, [availableExperiments.data, availableExperimentsSet]);

  const setSelectedStatus = (
    toUpdate: string[] | string,
    isSelected: boolean,
  ) => {
    const experiments = Array.isArray(toUpdate) ? toUpdate : [toUpdate];

    setSelected((previousSelection) => {
      const newSelection = new Set(previousSelection);

      experiments.forEach((experiment) => {
        invariant(
          availableExperimentsSet.has(experiment),
          "you can't update the status of an experiment that isn't available with the current filters applied",
        );
        if (isSelected) {
          newSelection.add(experiment);
        } else {
          newSelection.delete(experiment);
        }
      });
      return newSelection;
    });
  };

  const areAllSelected =
    availableExperimentsSet.intersection(selected).size ===
    availableExperimentsSet.size;

  const selectedStatusFrequencyMap: Record<experiment_status, number> = {
    [experiment_status.APPROVED]: 0,
    [experiment_status.PENDING]: 0,
    [experiment_status.PREVIEW]: 0,
    [experiment_status.REJECTED]: 0,
    [experiment_status.REVERTED]: 0,
  };

  availableExperiments.data?.forEach((experiment) => {
    if (selected.has(experiment.experiment_id)) {
      selectedStatusFrequencyMap[experiment.experiment_status]++;
    }
  });

  return {
    areAllSelected,
    availableExperimentsSet,
    selected,
    selectedStatusFrequencyMap,
    setSelectedStatus,
  };
}
