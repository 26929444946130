import { DialogClose } from "@radix-ui/react-dialog";
import { ChevronsUpDown, Edit2 } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "src/frontend/components/ui/Accordion";
import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogRoot,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "src/frontend/components/ui/AlertDialog";
import { Badge } from "src/frontend/components/ui/Badge";
import Button from "src/frontend/components/ui/Button";
import Checkbox from "src/frontend/components/ui/Checkbox";
import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandSeparator,
} from "src/frontend/components/ui/Command";
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from "src/frontend/components/ui/Dialog";
import FancyBoxSelectedItemsList from "src/frontend/components/ui/FancyBoxSelectedItemsList";
import { Input } from "src/frontend/components/ui/Input";
import { Label } from "src/frontend/components/ui/Label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/frontend/components/ui/Popover";
import { cn } from "src/frontend/components/ui/utils";
import getFancyBoxItemBadgeStyle from "src/frontend/utils/getFancyBoxItemBadgeStyle";
import { GenericObject } from "src/shared/types/generic/GenericObject";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import { MaybeUndefined } from "src/shared/types/maybe/MaybeUndefined";
import filterEmptyValues from "src/shared/utils/arrays/filterEmptyValues";
import groupBy from "src/shared/utils/arrays/groupBy";

/**
 * Fancy Box Component from: https://craft.mxkaske.dev/post/fancy-box
 * Supports multi-select add/edit/delete and search from a list of items.
 * Source code: https://github.com/mxkaske/mxkaske.dev/blob/main/components/craft/fancy-box.tsx
 */

export type FancyBoxItem = GenericObject & {
  color?: string;
  id?: string;
  isLucaTag?: boolean;
  label: string;
  type?: string;
  value: string;
};

type FancyBoxProps = {
  disableCreateNew?: boolean;
  disableEditing?: boolean;
  disableSearch?: boolean;
  disableSelectedItemsDisplay?: boolean;
  disabled?: boolean;
  editTitle?: string;
  height?: number;
  items: FancyBoxItem[];
  loading?: boolean;
  renderDefaultColorForSelectedItem?: boolean;
  renderSelectedItemLabel?: (item: FancyBoxItem) => JSX.Element;
  searchText?: string;
  selectTitle?: string;
  selectedItems: FancyBoxItem[];
  setItems: React.Dispatch<React.SetStateAction<FancyBoxItem[]>>;
  setSelectedItems: React.Dispatch<React.SetStateAction<FancyBoxItem[]>>;
  setSelectedItemsOverrideFn?: (item: FancyBoxItem) => void;
  truncateOnNthItem?: number;
  width?: number;
};

export default function FancyBox({
  disableCreateNew = false,
  disableEditing = false,
  disableSearch = false,
  disableSelectedItemsDisplay = false,
  disabled = false,
  editTitle = "Edit items",
  height = 245,
  items,
  loading = false,
  renderDefaultColorForSelectedItem,
  renderSelectedItemLabel,
  searchText = "Search...",
  selectTitle = "Select items",
  selectedItems,
  setItems,
  setSelectedItems,
  setSelectedItemsOverrideFn,
  truncateOnNthItem = 2,
  width = 300,
}: FancyBoxProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [openCombobox, setOpenCombobox] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const supportsColor = items.some((item) => item.color != null);

  const createItem = (name: string) => {
    const newItem = {
      color: supportsColor ? "#ffffff" : undefined,
      label: name,
      type: undefined,
      value: name.toLowerCase(),
    };
    setItems((prev) => [...prev, newItem]);
    setSelectedItems((prev) => [...prev, newItem]);
  };

  const toggleItem = (item: FancyBoxItem) => {
    if (setSelectedItemsOverrideFn != null) {
      setSelectedItemsOverrideFn(item);
      return;
    }
    setSelectedItems((currentItems) =>
      currentItems.find((current) => current.value === item.value) == null
        ? currentItems.concat(item)
        : currentItems.filter((l) => l.value !== item.value),
    );
    inputRef?.current?.focus();
  };

  const updateItem = (item: FancyBoxItem, newItem: FancyBoxItem) => {
    setItems((prev) =>
      prev.map((val) => (val.value === item.value ? newItem : val)),
    );
    setSelectedItems((prev) =>
      prev.map((val) => (val.value === item.value ? newItem : val)),
    );
  };

  const deleteItem = (item: FancyBoxItem) => {
    setItems((prev) => prev.filter((val) => val.value !== item.value));
    setSelectedItems((prev) => prev.filter((val) => val.value !== item.value));
  };

  const handleOnClickRemove = (item: FancyBoxItem) => {
    deleteItem(item);
  };

  const onComboboxOpenChange = (value: boolean) => {
    inputRef.current?.blur(); // HACK: otherwise, would scroll automatically to the bottom of page
    setOpenCombobox(value);
  };

  // Hacky way to clear the search input when the combobox is closed. Without
  // the timeout the Popover flashes back into display again briefly.
  useEffect(() => {
    let timeout: MaybeNull<NodeJS.Timeout> = null;
    if (openCombobox === false && inputValue !== "") {
      timeout = setTimeout(() => {
        setInputValue("");
      }, 100);
    }
    return () => {
      if (timeout != null) {
        clearTimeout(timeout);
      }
    };
  }, [inputValue, openCombobox]);

  const itemsGroupedByItem = groupBy(items, "type");
  return (
    <div className="w-full">
      <div className="h-9 w-full" style={{ maxWidth: width }}>
        <Popover onOpenChange={onComboboxOpenChange} open={openCombobox}>
          <PopoverTrigger asChild>
            <Button
              aria-expanded={openCombobox}
              className="w-full justify-between text-foreground"
              disabled={disabled || loading}
              role="combobox"
              style={{ width }}
              variant="basic"
            >
              {loading ? (
                "Loading..."
              ) : (
                <span className="truncate">
                  {selectedItems.length === 0 && selectTitle}
                  {selectedItems.length <= truncateOnNthItem &&
                    selectedItems.map(({ label }) => label).join(", ")}
                  {selectedItems.length > truncateOnNthItem &&
                    `${selectedItems.length} items selected`}
                </span>
              )}
              <ChevronsUpDown className="ml-2 h-3 w-3 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-full px-2 py-0" style={{ width }}>
            <Command loop>
              {!disableSearch && (
                <CommandInput
                  onValueChange={setInputValue}
                  placeholder={searchText}
                  ref={inputRef}
                  value={inputValue}
                />
              )}
              <div className="overflow-y-auto" style={{ maxHeight: height }}>
                <CommandGroup className="w-full">
                  {(itemsGroupedByItem?.undefined ?? []).map((item) => {
                    const isActive = selectedItems.some(
                      (selected) => selected.value === item.value,
                    );
                    return (
                      <FancyBoxCommandItem
                        isActive={isActive}
                        item={item}
                        key={item.value}
                        toggleItem={toggleItem}
                      />
                    );
                  })}
                </CommandGroup>
                {filterEmptyValues(
                  Object.entries(itemsGroupedByItem).map(
                    ([groupType, groupItems]) => {
                      if (groupType === "undefined") {
                        return null;
                      }

                      return (
                        <CommandGroup heading={groupType} key={groupType}>
                          {groupItems.map((item) => {
                            const isActive = selectedItems.some(
                              (selected) => selected.value === item.value,
                            );
                            return (
                              <FancyBoxCommandItem
                                isActive={isActive}
                                item={item}
                                key={item.value}
                                toggleItem={toggleItem}
                              />
                            );
                          })}
                        </CommandGroup>
                      );
                    },
                  ),
                )}
                {!disableCreateNew && (
                  <CommandItemCreate
                    onSelect={() => createItem(inputValue)}
                    {...{ inputValue, items }}
                  />
                )}
              </div>
              {!disableEditing && <CommandSeparator alwaysRender />}
              {!disableEditing && (
                <CommandGroup>
                  <CommandItem
                    className="text-xs text-muted-foreground"
                    onSelect={() => setOpenDialog(true)}
                    value={`:${inputValue}:`} // HACK: that way, the edit button will always be shown
                  >
                    <div className={cn("mr-2 h-4 w-4")} />
                    <Edit2 className="mr-2 h-2.5 w-2.5" />
                    {editTitle}
                  </CommandItem>
                </CommandGroup>
              )}
            </Command>
          </PopoverContent>
        </Popover>
        {!disableEditing && (
          <DialogRoot
            onOpenChange={(open) => {
              if (!open) {
                setOpenCombobox(true);
              }
              setOpenDialog(open);
            }}
            open={openDialog}
          >
            <DialogContent className="flex max-h-[90vh] flex-col">
              <DialogHeader>
                <DialogTitle>{editTitle}</DialogTitle>
                <DialogDescription>
                  Edit or delete existing items.
                </DialogDescription>
              </DialogHeader>
              <div className="-mx-6 flex-1 overflow-scroll px-6 py-2">
                {items.map((item) => {
                  return (
                    <DialogListItem
                      key={item.value}
                      onDelete={() => deleteItem(item)}
                      onSubmit={(e) => {
                        e.preventDefault();
                        const target = e.target as Record<
                          "color" | "name" | "type",
                          { value: string }
                        > &
                          typeof e.target;
                        const newItem = {
                          color: target.color?.value ?? undefined,
                          label: target.name.value,
                          type: target.type.value,
                          value: target.name.value.toLowerCase(),
                        };

                        updateItem(item, newItem);
                      }}
                      {...item}
                    />
                  );
                })}
              </div>
              <DialogFooter className="bg-opacity-40">
                <DialogClose asChild>
                  <Button variant="outline">Close</Button>
                </DialogClose>
              </DialogFooter>
            </DialogContent>
          </DialogRoot>
        )}
      </div>
      {!disableSelectedItemsDisplay && (
        <div className="mt-3 w-full">
          <FancyBoxSelectedItemsList
            className="w-full"
            onClickRemove={handleOnClickRemove}
            renderDefaultColor={renderDefaultColorForSelectedItem}
            renderLabel={renderSelectedItemLabel}
            selectedItems={selectedItems}
          />
        </div>
      )}
    </div>
  );
}

type FancyBoxCommandItemProps = {
  isActive: boolean;
  item: FancyBoxItem;
  toggleItem: (item: FancyBoxItem) => void;
};

function FancyBoxCommandItem({
  isActive,
  item,
  toggleItem,
}: FancyBoxCommandItemProps) {
  return (
    <CommandItem
      className="my-2 mr-2 w-full p-2"
      key={item.value}
      onSelect={() => {
        toggleItem(item);
      }}
      value={item.value}
    >
      <div className="flex-1 truncate">{item.label}</div>
      <Checkbox
        checkboxClassName="mr-2"
        checked={isActive}
        id="fancy-box-checkbox"
        onCheckedChange={() => null}
        size="sm"
      />
    </CommandItem>
  );
}

type CommandItemCreateProps = {
  inputValue: string;
  items: FancyBoxItem[];
  onSelect: () => void;
};

function CommandItemCreate({
  inputValue,
  items,
  onSelect,
}: CommandItemCreateProps) {
  const hasNoFramework = !items
    .map(({ value }) => value)
    .includes(`${inputValue.toLowerCase()}`);

  const shouldRender = inputValue !== "" && hasNoFramework;
  if (!shouldRender) {
    return null;
  }

  // BUG: whenever a space is appended, the Create Button will not be shown.
  return (
    <CommandItem
      className="text-xs text-muted-foreground"
      key={`${inputValue}`}
      onSelect={onSelect}
      value={`${inputValue}`}
    >
      <div className={cn("mr-2 h-4 w-4")} />
      Create new item &quot;{inputValue}&quot;
    </CommandItem>
  );
}

type DialogListItemProps = FancyBoxItem & {
  onDelete: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
};

function DialogListItem({
  color,
  label,
  onDelete,
  onSubmit,
  value,
}: DialogListItemProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [accordionValue, setAccordionValue] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>(label);
  const [colorValue, setColorValue] = useState<MaybeUndefined<string>>(color);
  const disabled = label === inputValue && color === colorValue;

  useEffect(() => {
    if (accordionValue !== "") {
      inputRef.current?.focus();
    }
  }, [accordionValue]);

  return (
    <Accordion
      collapsible
      key={value}
      onValueChange={setAccordionValue}
      type="single"
      value={accordionValue}
    >
      <AccordionItem value={value}>
        <div className="flex items-center justify-between">
          <div>
            <Badge style={getFancyBoxItemBadgeStyle(color)} variant="outline">
              {label}
            </Badge>
          </div>
          <div className="flex items-center gap-4">
            <AccordionTrigger>Edit</AccordionTrigger>
            <AlertDialogRoot>
              <AlertDialogTrigger asChild>
                <Button size="xs" variant="destructive">
                  Delete
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Are you sure sure?</AlertDialogTitle>
                  <AlertDialogDescription>
                    You are about to delete the item{" "}
                    <Badge
                      style={getFancyBoxItemBadgeStyle(color)}
                      variant="outline"
                    >
                      {label}
                    </Badge>{" "}
                    .
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction onClick={onDelete}>
                    Delete
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogRoot>
          </div>
        </div>
        <AccordionContent>
          <form
            className="flex items-end gap-4 px-1"
            onSubmit={(e) => {
              onSubmit(e);
              setAccordionValue("");
            }}
          >
            <div className="grid w-full gap-3">
              <Label htmlFor="name">Item name</Label>
              <Input
                className="h-8"
                id="name"
                onChange={(e) => setInputValue(e.target.value)}
                ref={inputRef}
                value={inputValue}
              />
            </div>
            {color != null && (
              <div className="grid gap-3">
                <Label htmlFor="color">Color</Label>
                <Input
                  className="h-8 px-2 py-1"
                  id="color"
                  onChange={(e) => setColorValue(e.target.value)}
                  type="color"
                  value={colorValue}
                />
              </div>
            )}
            <Button disabled={disabled} size="xs" type="submit">
              Save
            </Button>
          </form>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
