import {
  IconEdit,
  IconGripVertical,
  IconInfoCircle,
  IconTrash,
} from "@tabler/icons-react";
import React from "react";
import Card from "src/frontend/components/Card";
import Row from "src/frontend/components/Row";
import ConstraintBoundsSummary from "src/frontend/components/constraints/ConstraintBoundsSummary";
import SortableContainer from "src/frontend/components/sortable/SortableContainer";
import { SortableItem } from "src/frontend/components/sortable/SortableItem";
import Button from "src/frontend/components/ui/Button";
import Tooltip from "src/frontend/components/ui/Tooltip";
import useBreakpoints from "src/frontend/hooks/useBreakpoints";
import { convertCompetitorMatchType } from "src/frontend/pages/company/rules/ConstraintDialog";
import usePricingRulesGroupConstraintStore from "src/frontend/stores/usePricingRulesGroupConstraintStore";
import formatGenericEnumLabel from "src/frontend/utils/formatGenericEnumLabel";
import { PricingConstraintType } from "src/shared/trpc/common/PricingConstraint";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import arrayNotEmpty from "src/shared/utils/arrays/arrayNotEmpty";
import filterEmptyValues from "src/shared/utils/arrays/filterEmptyValues";

type ConstraintsListProps = {
  constraints: PricingConstraintType[];
  disabled: boolean;
  removeConstraint: (constraint: PricingConstraintType) => void;
  setConstraintDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setConstraintToEdit: React.Dispatch<
    React.SetStateAction<MaybeNull<PricingConstraintType>>
  >;
  setConstraints: React.Dispatch<React.SetStateAction<PricingConstraintType[]>>;
};

export default function ConstraintsList({
  constraints,
  disabled,
  removeConstraint,
  setConstraintDialogOpen,
  setConstraintToEdit,
  setConstraints,
}: ConstraintsListProps) {
  const { isSmall } = useBreakpoints();
  const constraintsExist = arrayNotEmpty(constraints);
  return (
    <div>
      {constraintsExist && (
        <SortableContainer
          className="flex flex-col gap-4"
          items={constraints}
          setItems={setConstraints}
        >
          {constraints.map((constraint, index) => {
            return (
              <SortableItem
                dragDisabled={isSmall || disabled}
                id={constraint.id}
                key={constraint.id}
              >
                <Card className="flex w-full flex-row items-center justify-between gap-2 border p-2">
                  <Row className="gap-2">
                    <IconGripVertical className="text-muted" />

                    <p className="font-semibold">
                      {index + 1}. {formatGenericEnumLabel(constraint.type)}
                    </p>

                    <Row className="justify-between">
                      {constraint.type === "ROUNDING" && (
                        <Tooltip
                          content={
                            <p>
                              Rounding constraints are always applied to final
                              prices after all other rules
                            </p>
                          }
                        >
                          <IconInfoCircle size={16} />
                        </Tooltip>
                      )}
                      {constraint.type === "PRICE_COMPARISON" &&
                        constraint.comparison_price_target !==
                          "PRE_LUCA_PRICE" && (
                          <Tooltip
                            content={
                              <p>
                                Price comparison constraints are calculated when
                                the price plan is generated, unless they
                                reference the Pre Luca Price.
                              </p>
                            }
                          >
                            <IconInfoCircle size={16} />
                          </Tooltip>
                        )}
                    </Row>
                  </Row>

                  <p className="line-clamp-1">
                    <ConstraintBoundsSummary
                      constraint={constraint}
                      hideActualValues
                    />
                  </p>

                  <Row className="justify-end gap-2">
                    <Button
                      disabled={disabled}
                      onClick={() => {
                        setConstraintDialogOpen(true);
                        setConstraintToEdit(constraint);
                        usePricingRulesGroupConstraintStore.setState({
                          ...constraint,
                          lower_bound_input:
                            constraint.lower_bound == null
                              ? ""
                              : String(constraint.lower_bound),
                          pricing_constraint_competitor_match_types:
                            filterEmptyValues(
                              constraint.pricing_constraint_competitor_match_types?.map(
                                convertCompetitorMatchType,
                              ),
                            ),
                          upper_bound_input:
                            constraint.upper_bound == null
                              ? ""
                              : String(constraint.upper_bound),
                        });
                      }}
                      size="icon"
                      variant="ghost"
                    >
                      <IconEdit size={20} strokeWidth={1.5} />
                    </Button>
                    <Button
                      disabled={disabled}
                      onClick={() => {
                        removeConstraint(constraint);
                      }}
                      size="icon"
                      variant="ghost"
                    >
                      <IconTrash size={20} strokeWidth={1.5} />
                    </Button>
                  </Row>
                </Card>
              </SortableItem>
            );
          })}
        </SortableContainer>
      )}
    </div>
  );
}
