import { experiment_status } from "@prisma/client";
import { useEffect, useMemo, useRef } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { CopyText } from "src/frontend/components/CopyText";
import ElasticityBadge from "src/frontend/components/ElasticityBadge";
import { NavigateInterruptPrompt } from "src/frontend/components/NavigateInterruptPrompt";
import ProductTagTableCell from "src/frontend/components/ProductTagTableCell";
import Row from "src/frontend/components/Row";
import {
  Table,
  TableDataCell,
  TableHeader,
  TableHeaderCell,
} from "src/frontend/components/TableComponents";
import Checkbox from "src/frontend/components/ui/Checkbox";
import Spinner from "src/frontend/components/ui/Spinner";
import { cn } from "src/frontend/components/ui/utils";
import { PricePlanConfigMapSimple } from "src/frontend/constants/UserColumnVisibilityConfig";
import usePricePlanColumnVisibility from "src/frontend/hooks/columnVisibilityHooks/usePricePlanColumnVisibility";
import useGetExperimentsForecastQuery from "src/frontend/hooks/queries/useGetExperimentsForecastQuery";
import useBreakpoints from "src/frontend/hooks/useBreakpoints";
import useCurrentlySelectedPricePlan from "src/frontend/hooks/useCurrentlySelectedPricePlan";
import useIsDarkTheme from "src/frontend/hooks/useIsDarkTheme";
import EditPriceCell from "src/frontend/pages/pricing/details/EditPriceCell";
import { ExperimentFilter } from "src/frontend/pages/pricing/details/PricePlanGroupDetail";
import useEditPricePlanStore from "src/frontend/stores/useEditPricePlanStore";
import usePricePlanStore from "src/frontend/stores/usePricePlanStore";
import useProductDetailSidePanelStore, {
  ProductDetailSidePanelTab,
} from "src/frontend/stores/useProductDetailSidePanelStore";
import formatSignedPercent from "src/frontend/utils/formatSignedPercent";
import getBorderRightForColumns from "src/frontend/utils/table/getBorderRightForColumns";
import getStickyColumnStyles from "src/frontend/utils/table/getStickyColumnStyles";
import getTableRowColSpan from "src/frontend/utils/table/getTableRowColSpan";
import { PricePlanVisibilityFiltersType } from "src/shared/trpc/common/UserCommonVisibilityFilters";
import {
  ExperimentSortColumn,
  ExperimentSortColumnEnum,
} from "src/shared/trpc/queries/getExperimentForecastQuerySchema";
import arrayEmpty from "src/shared/utils/arrays/arrayEmpty";
import arrayNotEmpty from "src/shared/utils/arrays/arrayNotEmpty";
import formatCurrencyDelta from "src/shared/utils/formatCurrencyDelta";
import formatDelta from "src/shared/utils/formatDelta";
import formatPriceRange from "src/shared/utils/formatPriceRange";
import formatCurrency from "src/shared/utils/numbers/formatCurrency";
import formatNumberRounded from "src/shared/utils/numbers/formatNumberRounded";
import formatPercentage from "src/shared/utils/numbers/formatPercentage";
import formatPricePercentChange from "src/shared/utils/numbers/formatPricePercentChange";
import getConstraintCountsForExperimentSegment from "../../../../shared/utils/constraints/getConstraintCountsForExperimentSegment";

function StatusBadge({ status }: { status: experiment_status }) {
  const variants: Record<experiment_status, { label: string; style: string }> =
    {
      [experiment_status.PREVIEW]: {
        label: "No Change",
        style: "bg-n-100 text-n-400",
      },
      [experiment_status.PENDING]: {
        label: "Pending",
        style: "bg-n-200 text-n-700",
      },
      [experiment_status.APPROVED]: {
        label: "Approved",
        style: "bg-green-100 text-green-700",
      },
      [experiment_status.REJECTED]: {
        label: "Rejected",
        style: "bg-red-100 text-red-700",
      },
      [experiment_status.REVERTED]: {
        label: "Reverted",
        style: "bg-purple-100 text-purple-700",
      },
    };

  const { label, style } = variants[status];

  return (
    <span className={cn("rounded-lg px-2 py-0.5 text-xs", style)}>{label}</span>
  );
}

function CircularProgress({ max, value }: { max: number; value: number }) {
  return (
    <div className="w-4">
      <CircularProgressbar
        maxValue={max}
        strokeWidth={18}
        styles={buildStyles({
          pathColor: `#61932F`,
          pathTransitionDuration: 0.15,
          strokeLinecap: "round",
          trailColor: "#E0EFD9",
        })}
        value={value}
      />
    </div>
  );
}

type ForecastTableProps = {
  areAllSelected: boolean;
  availableExperimentsSet: Set<string>;
  isLoading: boolean;
  selected: Set<string>;
  setSelectedStatus: (toUpdate: string[] | string, isSelected: boolean) => void;
  visibilityFilters: PricePlanVisibilityFiltersType;
};

export default function ForecastTable({
  areAllSelected,
  availableExperimentsSet,
  isLoading,
  selected,
  setSelectedStatus,
  visibilityFilters,
}: ForecastTableProps) {
  const { customerSegment } = usePricePlanStore();
  const editPricePlanStoreState = useEditPricePlanStore();
  const { groupId } = useParams();
  const pricePlan = useCurrentlySelectedPricePlan();
  const experimentForecastQuery = useGetExperimentsForecastQuery();
  const experiments = useMemo(
    () =>
      experimentForecastQuery.data?.pages.map((page) => page.items).flat() ??
      [],
    [experimentForecastQuery.data],
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const filters =
    searchParams?.get("filters") != null &&
    searchParams.get("filters")!.length > 0
      ? (JSON.parse(searchParams.get("filters")!) as ExperimentFilter[])
      : [];

  const sortColumn = searchParams.get(
    "sortColumn",
  ) as ExperimentSortColumnEnum | null;
  const sortOrder = searchParams.get("sortOrder") as "asc" | "desc" | null;
  const searchQuery = searchParams.get("searchQuery");

  const isSortColumn = (column: ExperimentSortColumnEnum) => {
    if (sortColumn === column) {
      return sortOrder ?? undefined;
    } else {
      return;
    }
  };

  const handleColumnSortClick =
    (column: ExperimentSortColumnEnum) => (sortOrder?: "asc" | "desc") => {
      const sortColumn = searchParams.get("sortColumn");
      if (sortColumn === column && sortOrder == null) {
        searchParams.delete("sortColumn");
        searchParams.delete("sortOrder");
        setSearchParams(searchParams);
      } else if (sortOrder != null) {
        searchParams.set("sortColumn", String(column));
        searchParams.set("sortOrder", sortOrder);
        setSearchParams(searchParams);
      }
    };

  const sentinelRef = useRef<HTMLTableRowElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const firstEntry = entries[0];
        if (
          firstEntry.isIntersecting &&
          experimentForecastQuery.hasNextPage &&
          experimentForecastQuery.isLoading === false &&
          experimentForecastQuery.isFetchingNextPage === false
        ) {
          void experimentForecastQuery.fetchNextPage();
        }
      },
      { threshold: 0.1 },
    );

    const currentSentinel = sentinelRef.current;
    if (currentSentinel) {
      observer.observe(currentSentinel);
    }

    return () => {
      if (currentSentinel) {
        observer.unobserve(currentSentinel);
      }
    };
  }, [
    experimentForecastQuery,
    experimentForecastQuery.isLoading,
    experimentForecastQuery.isFetchingNextPage,
    experimentForecastQuery.fetchNextPage,
    experimentForecastQuery.hasNextPage,
  ]);

  const breakpointState = useBreakpoints();
  const isDarkTheme = useIsDarkTheme();

  // TODO-[MIKE]: contribution margin
  const {
    hasCategory: hasCategories,
    hasConstraints,
    hasCost,
    hasDiscountedPriceChangePercent: hasDiscountedPricePriceChange,
    hasElasticity: hasElasticityData,
    hasExperimentStatus,
    hasInventory,
    hasKvi,
    hasListPriceChangePercent: hasListPricePriceChange,
    hasMemberConstraints,
    hasMemberElasticity: hasMemberElasticityData,
    hasMemberPriceChangePercent: hasMemberPricePriceChange,
    hasMemberPriceRange,
    hasNewDiscountedPercent,
    hasNewDiscountedPrice,
    hasNewDiscountedPriceContributionMargin,
    hasNewDiscountedPriceMargin,
    hasNewListPrice,
    hasNewListPriceContributionMargin,
    hasNewListPriceMargin,
    hasNewMemberDiscountedPercent,
    hasNewMemberPrice,
    hasNewMemberPriceContributionMargin,
    hasNewMemberPriceMargin,
    hasNewSubscriberDiscountedPercent,
    hasNewSubscriberPrice,
    hasNewSubscriberPriceContributionMargin,
    hasNewSubscriberPriceMargin,
    hasPreviousDiscountedPercent,
    hasPreviousDiscountedPrice,
    hasPreviousDiscountedPriceContributionMargin,
    hasPreviousDiscountedPriceMargin,
    hasPreviousListPrice,
    hasPreviousListPriceContributionMargin,
    hasPreviousListPriceMargin,
    hasPreviousMemberDiscountedPercent,
    hasPreviousMemberPrice,
    hasPreviousMemberPriceContributionMargin,
    hasPreviousMemberPriceMargin,
    hasPreviousSubscriberDiscountedPercent,
    hasPreviousSubscriberPrice,
    hasPreviousSubscriberPriceContributionMargin,
    hasPreviousSubscriberPriceMargin,
    hasPriceRange,
    hasPriceZone,
    hasProducer,
    hasProductBrandName,
    hasProductSku,
    hasProductType,
    hasProfit30d,
    hasProfit60d,
    hasProfit90d,
    hasProfit120d,
    hasProfitImpact,
    hasProfitNextThirty: hasNextThirtyProfit,
    hasProfitRank30d,
    hasProfitRank60d,
    hasProfitRank90d,
    hasProfitRank120d,
    hasRevenue30d,
    hasRevenue60d,
    hasRevenue90d,
    hasRevenue120d,
    hasRevenueImpact,
    hasRevenueNextThirty: hasNextThirtyRevenue,
    hasRevenueRank30d,
    hasRevenueRank60d,
    hasRevenueRank90d,
    hasRevenueRank120d,
    hasSubcategory: hasSubcategories,
    hasSubscriberPriceChangePercent: hasSubscriberPricePriceChange,
    hasSupplier,
    hasTags,
    hasUnitsSold30d,
    hasUnitsSold60d,
    hasUnitsSold90d,
    hasUnitsSold120d,
    hasUnitsSoldImpact,
    hasUnitsSoldNextThirty: hasNextThirtyUnitsSold,
    hasUnitsSoldRank30d,
    hasUnitsSoldRank60d,
    hasUnitsSoldRank90d,
    hasUnitsSoldRank120d,
    hasWeight,
    hasWeightLabel,
  } = usePricePlanColumnVisibility(visibilityFilters);

  useEffect(() => {
    if (typeof groupId !== "string") {
      return;
    }

    if (
      editPricePlanStoreState.groupId == null ||
      groupId !== editPricePlanStoreState.groupId
    ) {
      editPricePlanStoreState.setInitialState(experiments, groupId);
    }
  }, [experiments, groupId, editPricePlanStoreState]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => editPricePlanStoreState.reset(), []);

  if (
    experimentForecastQuery.isFetching === false &&
    arrayEmpty(experiments) &&
    searchQuery == null &&
    filters.length === 0
  ) {
    return (
      <div>
        <p>Price plan is still being generated.</p>
      </div>
    );
  }

  const [
    checkboxWidth,
    skuWidth,
    productIdWidth,
    productNameWidth,
    statusWidth,
    priceZoneWidth,
  ] = [
    40,
    100,
    hasProductSku ? 120 : 0,
    225,
    hasExperimentStatus ? 100 : 0,
    hasPriceZone ? 120 : 0,
  ];
  const skuOffset = checkboxWidth;
  const productIdOffset = skuOffset + skuWidth;
  const productNameOffset = productIdOffset + productIdWidth;
  const statusOffset = productNameOffset + productNameWidth;
  const priceZoneOffset = statusOffset + statusWidth;

  const stickyColumnStyles = getStickyColumnStyles({
    breakpointState,
    isDarkTheme,
    lineWeight: 1,
  });
  const checkboxStickyColumnStyles = getStickyColumnStyles({
    breakpointState,
    isDarkTheme,
    lineWeight: 0,
    width: checkboxWidth,
  });
  const skuStickyColumnStyles = getStickyColumnStyles({
    breakpointState,
    isDarkTheme,
    lineWeight: 0,
    width: skuWidth,
  });
  const productIdStickyColumnStyles = getStickyColumnStyles({
    breakpointState,
    isDarkTheme,
    lineWeight: 0,
    width: productIdWidth,
  });
  const productNameStickyColumnStyles = getStickyColumnStyles({
    breakpointState,
    isDarkTheme,
    lineWeight: hasPriceZone || hasExperimentStatus ? 0 : 1,
    width: productNameWidth,
  });
  const statusStickyColumnStyles = getStickyColumnStyles({
    breakpointState,
    isDarkTheme,
    lineWeight: hasPriceZone ? 0 : 1,
    width: statusWidth,
  });
  const priceZoneStickyColumnStyles = getStickyColumnStyles({
    breakpointState,
    isDarkTheme,
    lineWeight: hasPriceZone ? 1 : 0,
    width: priceZoneWidth,
  });

  const hasEdits = arrayNotEmpty(
    editPricePlanStoreState.getEditedPrices(experiments),
  );

  const competitorPricingData = experiments.map(
    (val) => val.competitor_matches,
  );

  const myBrandCols = [hasProductSku, hasExperimentStatus, hasPriceZone];
  const myBrandColSpan = getTableRowColSpan(myBrandCols, 3);

  const productDefinitionCols = [
    hasProductBrandName,
    hasCategories,
    hasSubcategories,
    hasProductType,
    hasKvi,
    hasSupplier,
    hasProducer,
    hasWeight,
    hasWeightLabel,
    hasTags,
  ];
  const productDefinitionColSpan = getTableRowColSpan(productDefinitionCols);
  const {
    category: hasCategoryBorderRight,
    kvi: hasKviBorderRight,
    producer: hasProducerBorderRight,
    product_brand_name: hasProductBrandNameBorderRight,
    product_type: hasProductTypeBorderRight,
    subcategory: hasSubcategoryBorderRight,
    supplier: hasSupplierBorderRight,
    tags: hasTagsBorderRight,
    weight: hasWeightBorderRight,
    weight_label: hasWeightLabelBorderRight,
  } = getBorderRightForColumns(
    PricePlanConfigMapSimple["Product Definition"],
    productDefinitionCols,
  );

  const strategyCols = [
    hasConstraints,
    hasPriceRange,
    hasMemberConstraints,
    hasMemberPriceRange,
    hasElasticityData,
    hasMemberElasticityData,
  ];
  const strategyColSpan = getTableRowColSpan(strategyCols);
  const {
    constraints: constraintsBorderRight,
    elasticity: elasticityBorderRight,
    member_constraints: memberConstraintsBorderRight,
    member_elasticity: memberElasticityBorderRight,
    member_price_range: memberPriceRangeBorderRight,
    price_range: priceRangeBorderRight,
  } = getBorderRightForColumns(PricePlanConfigMapSimple.Strategy, strategyCols);

  const currentPricingCols = [
    hasNewListPrice,
    hasPreviousListPrice,
    hasListPricePriceChange,
    hasNewDiscountedPrice,
    hasPreviousDiscountedPrice,
    hasDiscountedPricePriceChange,
    hasNewMemberPrice,
    hasPreviousMemberPrice,
    hasMemberPricePriceChange,
    hasNewSubscriberPrice,
    hasPreviousSubscriberPrice,
    hasSubscriberPricePriceChange,
    hasInventory,
    hasPreviousDiscountedPercent,
    hasPreviousMemberDiscountedPercent,
    hasPreviousSubscriberDiscountedPercent,
    hasNewDiscountedPercent,
    hasNewMemberDiscountedPercent,
    hasNewSubscriberDiscountedPercent,
  ];
  const currentPricingColSpan = getTableRowColSpan(currentPricingCols);
  const {
    discounted_price_change_percent: hasDiscountedPricePriceChangeBorderRight,
    inventory: hasInventoryBorderRight,
    list_price_change_percent: hasListPricePriceChangeBorderRight,
    member_price_change_percent: hasMemberPricePriceChangeBorderRight,
    new_discounted_percent: hasNewDiscountedPercentBorderRight,
    new_discounted_price: hasNewDiscountedPriceBorderRight,
    new_list_price: hasNewListPriceBorderRight,
    new_member_discounted_percent: hasNewMemberDiscountedPercentBorderRight,
    new_member_price: hasNewMemberPriceBorderRight,
    new_subscriber_discounted_percent:
      hasNewSubscriberDiscountedPercentBorderRight,
    new_subscriber_price: hasNewSubscriberPriceBorderRight,
    previous_discounted_percent: hasPreviousDiscountedPercentBorderRight,
    previous_discounted_price: hasPreviousDiscountedPriceBorderRight,
    previous_list_price: hasPreviousListPriceBorderRight,
    previous_member_discounted_percent:
      hasPreviousMemberDiscountedPercentBorderRight,
    previous_member_price: hasPreviousMemberPriceBorderRight,
    previous_subscriber_discounted_percent:
      hasPreviousSubscriberDiscountedPercentBorderRight,
    previous_subscriber_price: hasPreviousSubscriberPriceBorderRight,
    subscriber_price_change_percent: hasSubscriberPricePriceChangeBorderRight,
  } = getBorderRightForColumns(
    PricePlanConfigMapSimple["Pricing information"],
    currentPricingCols,
  );

  const marginCols = [
    hasNewListPriceMargin,
    hasNewListPriceContributionMargin,
    hasPreviousListPriceMargin,
    hasPreviousListPriceContributionMargin,
    hasNewDiscountedPriceMargin,
    hasPreviousDiscountedPriceMargin,
    // TODO-[MIKE]: contribution margin
    hasNewDiscountedPriceContributionMargin,
    hasPreviousDiscountedPriceContributionMargin,
    hasNewMemberPriceMargin,
    hasNewMemberPriceContributionMargin,
    hasPreviousMemberPriceMargin,
    hasPreviousMemberPriceContributionMargin,
    hasNewSubscriberPriceMargin,
    hasNewSubscriberPriceContributionMargin,
    hasPreviousSubscriberPriceMargin,
    hasPreviousSubscriberPriceContributionMargin,
    hasCost,
  ];
  const marginColSpan = getTableRowColSpan(marginCols);
  const {
    cost: hasCostBorderRight,
    new_discounted_price_contribution_margin:
      hasNewDiscountedPriceContributionMarginBorderRight,
    new_discounted_price_margin: hasNewDiscountedPriceMarginBorderRight,
    new_list_price_contribution_margin:
      hasNewListPriceContributionMarginBorderRight,
    new_list_price_margin: hasNewListPriceMarginBorderRight,
    new_member_price_contribution_margin:
      hasNewMemberPriceContributionMarginBorderRight,
    new_member_price_margin: hasNewMemberPriceMarginBorderRight,
    new_subscriber_price_contribution_margin:
      hasNewSubscriberPriceContributionMarginBorderRight,
    new_subscriber_price_margin: hasNewSubscriberPriceMarginBorderRight,
    previous_discounted_price_contribution_margin:
      hasPreviousDiscountedPriceContributionMarginBorderRight,
    previous_discounted_price_margin:
      hasPreviousDiscountedPriceMarginBorderRight,
    previous_list_price_contribution_margin:
      hasPreviousListPriceContributionMarginBorderRight,
    previous_list_price_margin: hasPreviousListPriceMarginBorderRight,
    previous_member_price_contribution_margin:
      hasPreviousMemberPriceContributionMarginBorderRight,
    previous_member_price_margin: hasPreviousMemberPriceMarginBorderRight,
    previous_subscriber_price_contribution_margin:
      hasPreviousSubscriberPriceContributionMarginBorderRight,
    previous_subscriber_price_margin:
      hasPreviousSubscriberPriceMarginBorderRight,
  } = getBorderRightForColumns(
    PricePlanConfigMapSimple["Margin information"],
    marginCols,
  );

  const lastThirtyCols = [
    hasRevenue30d,
    hasUnitsSold30d,
    hasProfit30d,
    hasRevenueRank30d,
    hasUnitsSoldRank30d,
    hasProfitRank30d,
    hasRevenue60d,
    hasUnitsSold60d,
    hasProfit60d,
    hasRevenueRank60d,
    hasUnitsSoldRank60d,
    hasProfitRank60d,
    hasRevenue90d,
    hasUnitsSold90d,
    hasProfit90d,
    hasRevenueRank90d,
    hasUnitsSoldRank90d,
    hasProfitRank90d,
    hasRevenue120d,
    hasUnitsSold120d,
    hasProfit120d,
    hasRevenueRank120d,
    hasUnitsSoldRank120d,
    hasProfitRank120d,
  ];
  const lastThirtyColSpan = getTableRowColSpan(lastThirtyCols);
  const {
    profit_30d: hasProfit30dBorderRight,
    profit_60d: hasProfit60dBorderRight,
    profit_90d: hasProfit90dBorderRight,
    profit_120d: hasProfit120dBorderRight,
    profit_rank_30d: hasProfitRank30dBorderRight,
    profit_rank_60d: hasProfitRank60dBorderRight,
    profit_rank_90d: hasProfitRank90dBorderRight,
    profit_rank_120d: hasProfitRank120dBorderRight,
    revenue_30d: hasRevenue30dBorderRight,
    revenue_60d: hasRevenue60dBorderRight,
    revenue_90d: hasRevenue90dBorderRight,
    revenue_120d: hasRevenue120dBorderRight,
    revenue_rank_30d: hasRevenueRank30dBorderRight,
    revenue_rank_60d: hasRevenueRank60dBorderRight,
    revenue_rank_90d: hasRevenueRank90dBorderRight,
    revenue_rank_120d: hasRevenueRank120dBorderRight,
    units_sold_30d: hasUnitsSold30dBorderRight,
    units_sold_60d: hasUnitsSold60dBorderRight,
    units_sold_90d: hasUnitsSold90dBorderRight,
    units_sold_120d: hasUnitsSold120dBorderRight,
    units_sold_rank_30d: hasUnitsSoldRank30dBorderRight,
    units_sold_rank_60d: hasUnitsSoldRank60dBorderRight,
    units_sold_rank_90d: hasUnitsSoldRank90dBorderRight,
    units_sold_rank_120d: hasUnitsSoldRank120dBorderRight,
  } = getBorderRightForColumns(
    PricePlanConfigMapSimple["Recent Sales"],
    lastThirtyCols,
  );

  const nextThirtyCols = [
    hasNextThirtyUnitsSold,
    hasNextThirtyRevenue,
    hasNextThirtyProfit,
  ];
  const nextThirtyColSpan = getTableRowColSpan(nextThirtyCols);
  const {
    profit_next_thirty: hasNextThirtyProfitBorderRight,
    revenue_next_thirty: hasNextThirtyRevenueBorderRight,
    units_sold_next_thirty: hasNextThirtyUnitsSoldBorderRight,
  } = getBorderRightForColumns(
    PricePlanConfigMapSimple["30 day forecast"],
    nextThirtyCols,
  );

  const impactCols = [hasUnitsSoldImpact, hasRevenueImpact, hasProfitImpact];
  const impactColSpan = getTableRowColSpan(impactCols);
  const {
    profit_impact: hasProfitImpactBorderRight,
    revenue_impact: hasRevenueImpactBorderRight,
    units_sold_impact: hasUnitsSoldImpactBorderRight,
  } = getBorderRightForColumns(
    PricePlanConfigMapSimple["Estimated Impact"],
    impactCols,
  );

  const isSelectAllCheckboxChecked = (): boolean | "indeterminate" => {
    if (selected.size === 0) {
      return false;
    }
    if (areAllSelected === true) {
      return true;
    }
    return "indeterminate";
  };

  return (
    <div className="relative">
      <NavigateInterruptPrompt
        beforeUnload
        message="Are you sure you want to leave? Your price edits will be discarded."
        when={hasEdits}
      />
      <Table className="max-h-[calc(100vh-125px)] w-full">
        <TableHeader>
          <tr>
            <TableHeaderCell
              className="left-0 border-b text-center"
              colSpan={myBrandColSpan}
              style={stickyColumnStyles}
            >
              My Brand
            </TableHeaderCell>
            {productDefinitionColSpan > 0 && (
              <TableHeaderCell
                borderRight
                className="border-b bg-n-0 px-0 py-1 text-center"
                colSpan={productDefinitionColSpan}
              >
                Product Definition
              </TableHeaderCell>
            )}
            {strategyColSpan > 0 && (
              <TableHeaderCell
                borderRight
                className="border-b bg-n-0 px-0 py-1 text-center"
                colSpan={strategyColSpan}
              >
                Strategy
              </TableHeaderCell>
            )}
            {currentPricingColSpan > 0 && (
              <TableHeaderCell
                borderRight
                className="border-b bg-n-0 px-0 py-1 text-center"
                colSpan={currentPricingColSpan}
              >
                Current Pricing
              </TableHeaderCell>
            )}
            {marginColSpan > 0 && (
              <TableHeaderCell
                borderRight
                className="border-b bg-n-0 px-0 py-1 text-center"
                colSpan={marginColSpan}
              >
                Margin Information
              </TableHeaderCell>
            )}
            {lastThirtyColSpan > 0 && (
              <TableHeaderCell
                borderRight
                className="border-b px-0 py-1 text-center"
                colSpan={lastThirtyColSpan}
              >
                Recent Sales
              </TableHeaderCell>
            )}
            {impactColSpan > 0 && (
              <TableHeaderCell
                borderRight
                className="border-b px-0 py-1 text-center"
                colSpan={impactColSpan}
              >
                Estimated Impact
              </TableHeaderCell>
            )}
            {nextThirtyColSpan > 0 && (
              <TableHeaderCell
                className="border-b px-0 py-1 text-center"
                colSpan={nextThirtyColSpan}
              >
                30 Day Forecast
              </TableHeaderCell>
            )}
          </tr>{" "}
          <tr className="border-b align-top">
            <TableHeaderCell
              className="left-0 pl-4"
              style={checkboxStickyColumnStyles}
            >
              <Checkbox
                checked={isSelectAllCheckboxChecked()}
                id="check-all-experiments"
                onCheckedChange={(_, checked) => {
                  setSelectedStatus([...availableExperimentsSet], checked);
                }}
                size="sm"
              />
            </TableHeaderCell>
            <TableHeaderCell
              isSortable
              onSortClick={handleColumnSortClick("sku")}
              sortAscFirst
              sortOrder={isSortColumn("sku")}
              style={{ ...skuStickyColumnStyles, left: skuOffset }}
            >
              <p>SKU</p>
            </TableHeaderCell>
            {hasProductSku && (
              <TableHeaderCell
                isSortable
                onSortClick={handleColumnSortClick("product_sku")}
                sortAscFirst
                sortOrder={isSortColumn("product_sku")}
                style={{
                  ...productIdStickyColumnStyles,
                  left: productIdOffset,
                }}
              >
                <p>Product ID</p>
              </TableHeaderCell>
            )}
            <TableHeaderCell
              isSortable
              onSortClick={handleColumnSortClick("product")}
              sortAscFirst
              sortOrder={isSortColumn("product")}
              style={{
                ...productNameStickyColumnStyles,
                left: productNameOffset,
              }}
            >
              <p>Product Name</p>
            </TableHeaderCell>
            {hasExperimentStatus && (
              <TableHeaderCell
                style={{
                  ...statusStickyColumnStyles,
                  left: statusOffset,
                }}
              >
                <p>Status</p>
              </TableHeaderCell>
            )}
            {hasPriceZone && (
              <TableHeaderCell
                isSortable
                onSortClick={handleColumnSortClick("price_zone")}
                sortAscFirst
                sortOrder={isSortColumn("price_zone")}
                style={{
                  ...priceZoneStickyColumnStyles,
                  left: priceZoneOffset,
                }}
              >
                <p>Price Zone</p>
              </TableHeaderCell>
            )}
            {hasProductBrandName && (
              <TableHeaderCell
                borderRight={hasProductBrandNameBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("brand")}
                sortAscFirst
                sortOrder={isSortColumn("brand")}
              >
                <p>Brand</p>
              </TableHeaderCell>
            )}
            {hasCategories && (
              <TableHeaderCell
                borderRight={hasCategoryBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("category")}
                sortAscFirst
                sortOrder={isSortColumn("category")}
              >
                <p>Category</p>
              </TableHeaderCell>
            )}
            {hasSubcategories && (
              <TableHeaderCell
                borderRight={hasSubcategoryBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("sub_category")}
                sortAscFirst
                sortOrder={isSortColumn("sub_category")}
              >
                <p>Subcategory</p>
              </TableHeaderCell>
            )}
            {hasProductType && (
              <TableHeaderCell
                borderRight={hasProductTypeBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("product_type")}
                sortAscFirst
                sortOrder={isSortColumn("product_type")}
              >
                <p>Product Type</p>
              </TableHeaderCell>
            )}
            {hasKvi && (
              <TableHeaderCell
                borderRight={hasKviBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("kvi")}
                sortAscFirst
                sortOrder={isSortColumn("kvi")}
              >
                <p>KVI</p>
              </TableHeaderCell>
            )}
            {hasSupplier && (
              <TableHeaderCell
                borderRight={hasSupplierBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("supplier")}
                sortAscFirst
                sortOrder={isSortColumn("supplier")}
              >
                <p>Supplier</p>
              </TableHeaderCell>
            )}
            {hasProducer && (
              <TableHeaderCell
                borderRight={hasProducerBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("producer")}
                sortAscFirst
                sortOrder={isSortColumn("producer")}
              >
                <p>Producer</p>
              </TableHeaderCell>
            )}
            {hasWeight && (
              <TableHeaderCell
                borderRight={hasWeightBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("weight")}
                sortOrder={isSortColumn("weight")}
              >
                <p>Weight</p>
              </TableHeaderCell>
            )}
            {hasWeightLabel && (
              <TableHeaderCell
                borderRight={hasWeightLabelBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("weight_unit")}
                sortAscFirst
                sortOrder={isSortColumn("weight_unit")}
              >
                <p>Weight Label</p>
              </TableHeaderCell>
            )}
            {hasTags && (
              <TableHeaderCell borderRight={hasTagsBorderRight}>
                <p>Tags</p>
              </TableHeaderCell>
            )}
            {hasConstraints && (
              <TableHeaderCell borderRight={constraintsBorderRight}>
                <p>Rules Met</p>
              </TableHeaderCell>
            )}
            {hasPriceRange && (
              <TableHeaderCell borderRight={priceRangeBorderRight}>
                <p>Price Range</p>
              </TableHeaderCell>
            )}
            {hasMemberConstraints && (
              <TableHeaderCell borderRight={memberConstraintsBorderRight}>
                <p>Member Rules Met</p>
              </TableHeaderCell>
            )}
            {hasMemberPriceRange && (
              <TableHeaderCell borderRight={memberPriceRangeBorderRight}>
                <p>Member Price Range</p>
              </TableHeaderCell>
            )}
            {hasElasticityData && (
              <TableHeaderCell
                borderRight={elasticityBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("elasticity")}
                sortOrder={isSortColumn("elasticity")}
              >
                <p>Elasticity</p>
              </TableHeaderCell>
            )}
            {hasMemberElasticityData && (
              <TableHeaderCell
                borderRight={memberElasticityBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("member_elasticity")}
                sortOrder={isSortColumn("member_elasticity")}
              >
                <p>Member Elasticity</p>
              </TableHeaderCell>
            )}
            {hasNewListPrice && (
              <TableHeaderCell
                borderRight={hasNewListPriceBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("new_list_price")}
                sortOrder={isSortColumn("new_list_price")}
              >
                <p>New Price</p>
              </TableHeaderCell>
            )}
            {hasPreviousListPrice && (
              <TableHeaderCell
                borderRight={hasPreviousListPriceBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("previous_list_price")}
                sortOrder={isSortColumn("previous_list_price")}
              >
                <p>Previous Price</p>
              </TableHeaderCell>
            )}
            {hasListPricePriceChange && (
              <TableHeaderCell borderRight={hasListPricePriceChangeBorderRight}>
                <p>Price Percent Change</p>
              </TableHeaderCell>
            )}
            {hasNewDiscountedPrice && (
              <TableHeaderCell borderRight={hasNewDiscountedPriceBorderRight}>
                <p>New Discounted Price</p>
              </TableHeaderCell>
            )}
            {hasPreviousDiscountedPrice && (
              <TableHeaderCell
                borderRight={hasPreviousDiscountedPriceBorderRight}
              >
                <p>Previous Discounted Price</p>
              </TableHeaderCell>
            )}
            {hasDiscountedPricePriceChange && (
              <TableHeaderCell
                borderRight={hasDiscountedPricePriceChangeBorderRight}
              >
                <p>Discounted Price Percent Change</p>
              </TableHeaderCell>
            )}
            {hasNewDiscountedPercent && (
              <TableHeaderCell borderRight={hasNewDiscountedPercentBorderRight}>
                <p>New Discount</p>
              </TableHeaderCell>
            )}
            {hasPreviousDiscountedPercent && (
              <TableHeaderCell
                borderRight={hasPreviousDiscountedPercentBorderRight}
              >
                <p>Previous Discount</p>
              </TableHeaderCell>
            )}
            {hasNewMemberPrice && (
              <TableHeaderCell
                borderRight={hasNewMemberPriceBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("new_member_price")}
                sortOrder={isSortColumn("new_member_price")}
              >
                <p>New Member Price</p>
              </TableHeaderCell>
            )}
            {hasPreviousMemberPrice && (
              <TableHeaderCell
                borderRight={hasPreviousMemberPriceBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("previous_member_price")}
                sortOrder={isSortColumn("previous_member_price")}
              >
                <p>Previous Member Price</p>
              </TableHeaderCell>
            )}
            {hasMemberPricePriceChange && (
              <TableHeaderCell
                borderRight={hasMemberPricePriceChangeBorderRight}
              >
                <p>Member Price Percent Change</p>
              </TableHeaderCell>
            )}
            {hasNewMemberDiscountedPercent && (
              <TableHeaderCell
                borderRight={hasNewMemberDiscountedPercentBorderRight}
              >
                <p>New Member Discount</p>
              </TableHeaderCell>
            )}
            {hasPreviousMemberDiscountedPercent && (
              <TableHeaderCell
                borderRight={hasPreviousMemberDiscountedPercentBorderRight}
              >
                <p>Previous Member Discount</p>
              </TableHeaderCell>
            )}
            {hasNewSubscriberPrice && (
              <TableHeaderCell borderRight={hasNewSubscriberPriceBorderRight}>
                <p>New Subscriber Price</p>
              </TableHeaderCell>
            )}
            {hasPreviousSubscriberPrice && (
              <TableHeaderCell
                borderRight={hasPreviousSubscriberPriceBorderRight}
              >
                <p>Previous Subscriber Price</p>
              </TableHeaderCell>
            )}
            {hasSubscriberPricePriceChange && (
              <TableHeaderCell
                borderRight={hasSubscriberPricePriceChangeBorderRight}
              >
                <p>Subscriber Price Percent Change</p>
              </TableHeaderCell>
            )}
            {hasNewSubscriberDiscountedPercent && (
              <TableHeaderCell
                borderRight={hasNewSubscriberDiscountedPercentBorderRight}
              >
                <p>New Subscriber Discount</p>
              </TableHeaderCell>
            )}
            {hasPreviousSubscriberDiscountedPercent && (
              <TableHeaderCell
                borderRight={hasPreviousSubscriberDiscountedPercentBorderRight}
              >
                <p>Previous Subscriber Discount</p>
              </TableHeaderCell>
            )}
            {hasInventory && (
              <TableHeaderCell
                borderRight={hasInventoryBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("inventory")}
                sortOrder={isSortColumn("inventory")}
              >
                <p>Inventory</p>
              </TableHeaderCell>
            )}
            {hasNewListPriceMargin && (
              <TableHeaderCell borderRight={hasNewListPriceMarginBorderRight}>
                <p>New Margin</p>
              </TableHeaderCell>
            )}
            {hasNewListPriceContributionMargin && (
              <TableHeaderCell
                borderRight={hasNewListPriceContributionMarginBorderRight}
              >
                <p>New Contribution Margin</p>
              </TableHeaderCell>
            )}
            {hasPreviousListPriceMargin && (
              <TableHeaderCell
                borderRight={hasPreviousListPriceMarginBorderRight}
              >
                <p>Previous Margin</p>
              </TableHeaderCell>
            )}
            {hasPreviousListPriceContributionMargin && (
              <TableHeaderCell
                borderRight={hasPreviousListPriceContributionMarginBorderRight}
              >
                <p>Previous Contribution Margin</p>
              </TableHeaderCell>
            )}
            {hasNewDiscountedPriceMargin && (
              <TableHeaderCell
                borderRight={hasNewDiscountedPriceMarginBorderRight}
              >
                <p>New Discounted Price Margin</p>
              </TableHeaderCell>
            )}
            {hasNewDiscountedPriceContributionMargin && (
              <TableHeaderCell
                borderRight={hasNewDiscountedPriceContributionMarginBorderRight}
              >
                <p>New Discounted Price Contribution Margin</p>
              </TableHeaderCell>
            )}
            {hasPreviousDiscountedPriceMargin && (
              <TableHeaderCell
                borderRight={hasPreviousDiscountedPriceMarginBorderRight}
              >
                <p>Previous Discounted Price Margin</p>
              </TableHeaderCell>
            )}
            {/* // TODO-[MIKE]: contribution margin */}
            {hasPreviousDiscountedPriceContributionMargin && (
              <TableHeaderCell
                borderRight={
                  hasPreviousDiscountedPriceContributionMarginBorderRight
                }
              >
                <p>Previous Discounted Price Contribution Margin</p>
              </TableHeaderCell>
            )}
            {hasNewMemberPriceMargin && (
              <TableHeaderCell borderRight={hasNewMemberPriceMarginBorderRight}>
                <p>New Member Price Margin</p>
              </TableHeaderCell>
            )}
            {hasNewMemberPriceContributionMargin && (
              <TableHeaderCell
                borderRight={hasNewMemberPriceContributionMarginBorderRight}
              >
                <p>New Member Price Contribution Margin</p>
              </TableHeaderCell>
            )}
            {hasPreviousMemberPriceMargin && (
              <TableHeaderCell
                borderRight={hasPreviousMemberPriceMarginBorderRight}
              >
                <p>Previous Member Price Margin</p>
              </TableHeaderCell>
            )}
            {hasPreviousMemberPriceContributionMargin && (
              <TableHeaderCell
                borderRight={
                  hasPreviousMemberPriceContributionMarginBorderRight
                }
              >
                <p>Previous Member Price Contribution Margin</p>
              </TableHeaderCell>
            )}
            {hasNewSubscriberPriceMargin && (
              <TableHeaderCell
                borderRight={hasNewSubscriberPriceMarginBorderRight}
              >
                <p>New Subscriber Price Margin</p>
              </TableHeaderCell>
            )}
            {hasNewSubscriberPriceContributionMargin && (
              <TableHeaderCell
                borderRight={hasNewSubscriberPriceContributionMarginBorderRight}
              >
                <p>New Subscriber Price Contribution Margin</p>
              </TableHeaderCell>
            )}
            {hasPreviousSubscriberPriceMargin && (
              <TableHeaderCell
                borderRight={hasPreviousSubscriberPriceMarginBorderRight}
              >
                <p>Previous Subscriber Price Margin</p>
              </TableHeaderCell>
            )}
            {hasPreviousSubscriberPriceContributionMargin && (
              <TableHeaderCell
                borderRight={
                  hasPreviousSubscriberPriceContributionMarginBorderRight
                }
              >
                <p>Previous Subscriber Price Contribution Margin</p>
              </TableHeaderCell>
            )}
            {hasCost && (
              <TableHeaderCell
                borderRight={hasCostBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("cost")}
                sortOrder={isSortColumn("cost")}
              >
                <p>Cost Per Unit</p>
              </TableHeaderCell>
            )}
            {hasRevenue30d && (
              <TableHeaderCell
                borderRight={hasRevenue30dBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("revenue_30d")}
                sortOrder={isSortColumn("revenue_30d")}
              >
                <p>30D Revenue</p>
              </TableHeaderCell>
            )}
            {hasUnitsSold30d && (
              <TableHeaderCell
                borderRight={hasUnitsSold30dBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("units_sold_30d")}
                sortOrder={isSortColumn("units_sold_30d")}
              >
                <p>30D Units Sold</p>
              </TableHeaderCell>
            )}
            {hasProfit30d && (
              <TableHeaderCell
                borderRight={hasProfit30dBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("profit_30d")}
                sortOrder={isSortColumn("profit_30d")}
              >
                <p>30D Profit</p>
              </TableHeaderCell>
            )}
            {hasRevenueRank30d && (
              <TableHeaderCell
                borderRight={hasRevenueRank30dBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("revenue_rank_30d")}
                sortOrder={isSortColumn("revenue_rank_30d")}
              >
                <p>30D Revenue Rank</p>
              </TableHeaderCell>
            )}
            {hasUnitsSoldRank30d && (
              <TableHeaderCell
                borderRight={hasUnitsSoldRank30dBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("units_sold_rank_30d")}
                sortOrder={isSortColumn("units_sold_rank_30d")}
              >
                <p>30D Units Sold Rank</p>
              </TableHeaderCell>
            )}
            {hasProfitRank30d && (
              <TableHeaderCell
                borderRight={hasProfitRank30dBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("profit_rank_30d")}
                sortOrder={isSortColumn("profit_rank_30d")}
              >
                <p>30D Profit Rank</p>
              </TableHeaderCell>
            )}
            {hasRevenue60d && (
              <TableHeaderCell
                borderRight={hasRevenue60dBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("revenue_60d")}
                sortOrder={isSortColumn("revenue_60d")}
              >
                <p>60D Revenue</p>
              </TableHeaderCell>
            )}
            {hasUnitsSold60d && (
              <TableHeaderCell
                borderRight={hasUnitsSold60dBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("units_sold_60d")}
                sortOrder={isSortColumn("units_sold_60d")}
              >
                <p>60D Units Sold</p>
              </TableHeaderCell>
            )}
            {hasProfit60d && (
              <TableHeaderCell
                borderRight={hasProfit60dBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("profit_60d")}
                sortOrder={isSortColumn("profit_60d")}
              >
                <p>60D Profit</p>
              </TableHeaderCell>
            )}
            {hasRevenueRank60d && (
              <TableHeaderCell
                borderRight={hasRevenueRank60dBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("revenue_rank_60d")}
                sortOrder={isSortColumn("revenue_rank_60d")}
              >
                <p>60D Revenue Rank</p>
              </TableHeaderCell>
            )}
            {hasUnitsSoldRank60d && (
              <TableHeaderCell
                borderRight={hasUnitsSoldRank60dBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("units_sold_rank_60d")}
                sortOrder={isSortColumn("units_sold_rank_60d")}
              >
                <p>60D Units Sold Rank</p>
              </TableHeaderCell>
            )}
            {hasProfitRank60d && (
              <TableHeaderCell
                borderRight={hasProfitRank60dBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("profit_rank_60d")}
                sortOrder={isSortColumn("profit_rank_60d")}
              >
                <p>60D Profit Rank</p>
              </TableHeaderCell>
            )}
            {hasRevenue90d && (
              <TableHeaderCell
                borderRight={hasRevenue90dBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("revenue_90d")}
                sortOrder={isSortColumn("revenue_90d")}
              >
                <p>90D Revenue</p>
              </TableHeaderCell>
            )}
            {hasUnitsSold90d && (
              <TableHeaderCell
                borderRight={hasUnitsSold90dBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("units_sold_90d")}
                sortOrder={isSortColumn("units_sold_90d")}
              >
                <p>90D Units Sold</p>
              </TableHeaderCell>
            )}
            {hasProfit90d && (
              <TableHeaderCell
                borderRight={hasProfit90dBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("profit_90d")}
                sortOrder={isSortColumn("profit_90d")}
              >
                <p>90D Profit</p>
              </TableHeaderCell>
            )}
            {hasRevenueRank90d && (
              <TableHeaderCell
                borderRight={hasRevenueRank90dBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("revenue_rank_30d")}
                sortOrder={isSortColumn("revenue_rank_30d")}
              >
                <p>90D Revenue Rank</p>
              </TableHeaderCell>
            )}
            {hasUnitsSoldRank90d && (
              <TableHeaderCell
                borderRight={hasUnitsSoldRank90dBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("units_sold_rank_60d")}
                sortOrder={isSortColumn("units_sold_rank_60d")}
              >
                <p>90D Units Sold Rank</p>
              </TableHeaderCell>
            )}
            {hasProfitRank90d && (
              <TableHeaderCell
                borderRight={hasProfitRank90dBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("profit_rank_90d")}
                sortOrder={isSortColumn("profit_rank_90d")}
              >
                <p>90D Profit Rank</p>
              </TableHeaderCell>
            )}
            {hasRevenue120d && (
              <TableHeaderCell
                borderRight={hasRevenue120dBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("revenue_120d")}
                sortOrder={isSortColumn("revenue_120d")}
              >
                <p>120D Revenue</p>
              </TableHeaderCell>
            )}
            {hasUnitsSold120d && (
              <TableHeaderCell
                borderRight={hasUnitsSold120dBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("units_sold_120d")}
                sortOrder={isSortColumn("units_sold_120d")}
              >
                <p>120D Units Sold</p>
              </TableHeaderCell>
            )}
            {hasProfit120d && (
              <TableHeaderCell
                borderRight={hasProfit120dBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("profit_120d")}
                sortOrder={isSortColumn("profit_120d")}
              >
                <p>120D Profit</p>
              </TableHeaderCell>
            )}
            {hasRevenueRank120d && (
              <TableHeaderCell
                borderRight={hasRevenueRank120dBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("revenue_rank_120d")}
                sortOrder={isSortColumn("revenue_rank_120d")}
              >
                <p>120D Revenue Rank</p>
              </TableHeaderCell>
            )}
            {hasUnitsSoldRank120d && (
              <TableHeaderCell
                borderRight={hasUnitsSoldRank120dBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("units_sold_rank_120d")}
                sortOrder={isSortColumn("units_sold_rank_120d")}
              >
                <p>120D Units Sold Rank</p>
              </TableHeaderCell>
            )}
            {hasProfitRank120d && (
              <TableHeaderCell
                borderRight={hasProfitRank120dBorderRight}
                isSortable
                onSortClick={handleColumnSortClick("profit_rank_120d")}
                sortOrder={isSortColumn("profit_rank_120d")}
              >
                <p>120D Profit Rank</p>
              </TableHeaderCell>
            )}
            {hasUnitsSoldImpact && (
              <TableHeaderCell
                borderRight={hasUnitsSoldImpactBorderRight}
                style={{ minWidth: 125 }}
              >
                <p>Units Sold Impact</p>
              </TableHeaderCell>
            )}
            {hasRevenueImpact && (
              <TableHeaderCell
                borderRight={hasRevenueImpactBorderRight}
                style={{ minWidth: 125 }}
              >
                <p>Revenue Impact</p>
              </TableHeaderCell>
            )}
            {hasProfitImpact && (
              <TableHeaderCell
                borderRight={hasProfitImpactBorderRight}
                style={{ minWidth: 125 }}
              >
                <p>Profit Impact</p>
              </TableHeaderCell>
            )}
            {hasNextThirtyUnitsSold && (
              <TableHeaderCell borderRight={hasNextThirtyUnitsSoldBorderRight}>
                <p>Units sold</p>
              </TableHeaderCell>
            )}
            {hasNextThirtyRevenue && (
              <TableHeaderCell borderRight={hasNextThirtyRevenueBorderRight}>
                <p>Revenue</p>
              </TableHeaderCell>
            )}
            {hasNextThirtyProfit && (
              <TableHeaderCell borderRight={hasNextThirtyProfitBorderRight}>
                <p>Total Profit</p>
              </TableHeaderCell>
            )}
          </tr>
        </TableHeader>
        <tbody>
          {experiments.map((experiment, experimentIndex) => {
            const opportunitySegment = experiment.opportunity_metrics.find(
              (val) => val.customer_segment === customerSegment,
            );
            const resultsMetrics = experiment.results_metrics;
            const impactSegment = Array.isArray(resultsMetrics)
              ? resultsMetrics.find(
                  (val) => val.customer_segment === customerSegment,
                )
              : undefined;

            const {
              constraintsCount: listPriceConstraintsCount,
              constraintsMetCount: listPriceConstraintsMetCount,
            } = getConstraintCountsForExperimentSegment(
              experiment,
              "LIST_PRICE",
            );

            const {
              constraintsCount: memberPriceConstraintsCount,
              constraintsMetCount: memberPriceConstraintsMetCount,
            } = getConstraintCountsForExperimentSegment(
              experiment,
              "MEMBER_PRICE",
            );

            const openSidePanel = (
              tab: ProductDetailSidePanelTab = "Product",
            ) => {
              useProductDetailSidePanelStore.setState({
                competitorMatchIndex: 1,
                competitorNamesList: experiment.competitor_names_list,
                competitorPricingData,
                experimentModelingPrice:
                  pricePlan?.objective?.modeling_price ?? "LIST_PRICE",
                isOpen: true,
                productIndex: experimentIndex,
                products: experiments,
                tab,
              });
            };

            const priceRange = formatPriceRange(
              experiment.list_price_min,
              experiment.list_price_max,
            );
            const memberPriceRange = formatPriceRange(
              experiment.member_price_min,
              experiment.member_price_max,
            );

            let includeBorderTop = false;
            if (experimentIndex > 0) {
              switch (sortColumn) {
                case ExperimentSortColumn.Enum.product:
                  includeBorderTop =
                    experiment.product_name !==
                    experiments[experimentIndex - 1].product_name;
                  break;
                case ExperimentSortColumn.Enum.product_sku:
                  includeBorderTop =
                    experiment.product_sku !==
                    experiments[experimentIndex - 1].product_sku;
                  break;
              }
            }

            return (
              <tr
                className={cn(
                  "cursor-pointer last:border-b-0 [&:hover>*]:bg-n-100 [&>*]:duration-150",
                  includeBorderTop && "border-t",
                )}
                key={experiment.experiment_id}
                onClick={() => openSidePanel()}
                ref={
                  experiments.length === experimentIndex + 1
                    ? sentinelRef
                    : null
                }
              >
                <TableDataCell
                  className="left-0 bg-n-0 pl-4"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  style={checkboxStickyColumnStyles}
                >
                  <Checkbox
                    checked={selected.has(experiment.experiment_id)}
                    id={experiment.experiment_id}
                    onCheckedChange={(_, checked) => {
                      setSelectedStatus(experiment.experiment_id, checked);
                    }}
                    size="sm"
                  />
                </TableDataCell>
                <TableDataCell
                  className="bg-n-0"
                  style={{ ...skuStickyColumnStyles, left: skuOffset }}
                >
                  <CopyText includeTooltip isCode textToCopy={experiment.sku}>
                    <p className="truncate text-ellipsis text-left text-sm">
                      {experiment.sku}
                    </p>
                  </CopyText>
                </TableDataCell>
                {hasProductSku && (
                  <TableDataCell
                    className="bg-n-0"
                    style={{
                      ...productIdStickyColumnStyles,
                      left: productIdOffset,
                    }}
                  >
                    <CopyText
                      includeTooltip
                      isCode
                      textToCopy={experiment.product_sku}
                    >
                      <p className="truncate text-ellipsis text-left text-sm">
                        {experiment.product_sku}
                      </p>
                    </CopyText>
                  </TableDataCell>
                )}
                <TableDataCell
                  className="bg-n-0"
                  style={{
                    ...productNameStickyColumnStyles,
                    left: productNameOffset,
                  }}
                >
                  {experiment.product_name}
                </TableDataCell>
                {hasExperimentStatus && (
                  <TableDataCell
                    maxWidth="max-w-[12ch]"
                    style={{
                      ...statusStickyColumnStyles,
                      left: statusOffset,
                    }}
                  >
                    <StatusBadge status={experiment.experiment_status} />
                  </TableDataCell>
                )}
                {hasPriceZone && (
                  <TableDataCell
                    maxWidth="max-w-[12ch]"
                    style={{
                      ...priceZoneStickyColumnStyles,
                      left: priceZoneOffset,
                    }}
                  >
                    {experiment.price_zone ?? "--"}
                  </TableDataCell>
                )}
                {hasProductBrandName && (
                  <TableDataCell
                    borderRight={hasProductBrandNameBorderRight}
                    maxWidth="max-w-[12ch]"
                  >
                    {experiment.product_brand_name ?? "--"}
                  </TableDataCell>
                )}
                {hasCategories && (
                  <TableDataCell
                    borderRight={hasCategoryBorderRight}
                    maxWidth="max-w-[12ch]"
                  >
                    {arrayEmpty(experiment.categories)
                      ? "--"
                      : experiment.categories.join(", ")}
                  </TableDataCell>
                )}
                {hasSubcategories && (
                  <TableDataCell
                    borderRight={hasSubcategoryBorderRight}
                    maxWidth="max-w-[12ch]"
                  >
                    {arrayEmpty(experiment.sub_categories)
                      ? "--"
                      : experiment.sub_categories.join(", ")}
                  </TableDataCell>
                )}
                {hasProductType && (
                  <TableDataCell
                    borderRight={hasProductTypeBorderRight}
                    maxWidth="max-w-[12ch]"
                  >
                    {experiment?.int_product_attributes?.product_type ?? "--"}
                  </TableDataCell>
                )}
                {hasKvi && (
                  <TableDataCell
                    borderRight={hasKviBorderRight}
                    maxWidth="max-w-[12ch]"
                  >
                    {experiment?.int_product_attributes?.kvi ?? "--"}
                  </TableDataCell>
                )}

                {hasSupplier && (
                  <TableDataCell
                    borderRight={hasSupplierBorderRight}
                    maxWidth="max-w-[15ch]"
                  >
                    {experiment.supplier ?? "--"}
                  </TableDataCell>
                )}
                {hasProducer && (
                  <TableDataCell
                    borderRight={hasProducerBorderRight}
                    maxWidth="max-w-[12ch]"
                  >
                    {experiment?.int_product_attributes?.producer_name?.length
                      ? experiment?.int_product_attributes?.producer_name
                      : "--"}
                  </TableDataCell>
                )}
                {hasWeight && (
                  <TableDataCell
                    borderRight={hasWeightBorderRight}
                    maxWidth="max-w-[12ch]"
                  >
                    {experiment?.int_product_attributes?.weight
                      ? experiment?.int_product_attributes?.weight
                      : "--"}
                  </TableDataCell>
                )}
                {hasWeightLabel && (
                  <TableDataCell
                    borderRight={hasWeightLabelBorderRight}
                    maxWidth="max-w-[12ch]"
                  >
                    {experiment?.int_product_attributes?.weight_unit?.length
                      ? experiment?.int_product_attributes?.weight_unit
                      : "--"}
                  </TableDataCell>
                )}
                {hasTags && (
                  <TableDataCell
                    borderRight={hasTagsBorderRight}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <ProductTagTableCell
                      hideAddTag
                      key={experiment.experiment_id}
                      numberOfTagsToShow={1}
                      productId={experiment.product_id}
                      productName={experiment.product_name}
                      refetchFn={experimentForecastQuery.refetch}
                      tags={experiment.tags}
                    />
                  </TableDataCell>
                )}
                {hasConstraints && (
                  <TableDataCell
                    borderRight={constraintsBorderRight}
                    onClick={(e) => {
                      e.stopPropagation();
                      openSidePanel("Constraints");
                    }}
                  >
                    <Row className="gap-2">
                      <CircularProgress
                        max={listPriceConstraintsCount}
                        value={listPriceConstraintsMetCount}
                      />
                      <p className="max-w-[275px] truncate whitespace-nowrap pl-1 text-left text-sm">
                        {listPriceConstraintsMetCount} out of{" "}
                        {listPriceConstraintsCount}
                      </p>
                    </Row>
                  </TableDataCell>
                )}
                {hasPriceRange && (
                  <TableDataCell
                    borderRight={priceRangeBorderRight}
                    onClick={(e) => {
                      e.stopPropagation();
                      openSidePanel("Constraints");
                    }}
                  >
                    <p className="whitespace-nowrap">{priceRange}</p>
                  </TableDataCell>
                )}
                {hasMemberConstraints && (
                  <TableDataCell
                    borderRight={memberConstraintsBorderRight}
                    onClick={(e) => {
                      e.stopPropagation();
                      openSidePanel("Constraints");
                    }}
                  >
                    <Row className="gap-2">
                      <CircularProgress
                        max={memberPriceConstraintsCount}
                        value={memberPriceConstraintsMetCount}
                      />
                      <p className="max-w-[275px] truncate whitespace-nowrap pl-1 text-left text-sm">
                        {memberPriceConstraintsMetCount} out of{" "}
                        {memberPriceConstraintsCount}
                      </p>
                    </Row>
                  </TableDataCell>
                )}
                {hasMemberPriceRange && (
                  <TableDataCell
                    borderRight={memberPriceRangeBorderRight}
                    onClick={(e) => {
                      e.stopPropagation();
                      openSidePanel("Constraints");
                    }}
                  >
                    <p className="whitespace-nowrap">{memberPriceRange}</p>
                  </TableDataCell>
                )}
                {hasElasticityData && (
                  <TableDataCell borderRight={elasticityBorderRight}>
                    <ElasticityBadge elasticity={experiment.elasticity} />
                  </TableDataCell>
                )}
                {hasMemberElasticityData && (
                  <TableDataCell borderRight={memberElasticityBorderRight}>
                    <ElasticityBadge
                      elasticity={experiment.member_elasticity}
                    />
                  </TableDataCell>
                )}
                {hasNewListPrice && (
                  <EditPriceCell
                    borderRight={hasNewListPriceBorderRight}
                    experiment={experiment}
                    priceField="treatment_list_price"
                  />
                )}
                {hasPreviousListPrice && (
                  <TableDataCell
                    borderRight={hasPreviousListPriceBorderRight}
                    className="font-bold"
                  >
                    <p>{formatCurrency(experiment.control_list_price)}</p>
                  </TableDataCell>
                )}
                {hasListPricePriceChange && (
                  <TableDataCell
                    borderRight={hasListPricePriceChangeBorderRight}
                  >
                    <p>
                      {formatPricePercentChange(
                        experiment.treatment_list_price,
                        experiment.control_list_price,
                      )}
                    </p>
                  </TableDataCell>
                )}
                {hasNewDiscountedPrice && (
                  <EditPriceCell
                    borderRight={hasNewDiscountedPriceBorderRight}
                    experiment={experiment}
                    priceField="treatment_discounted_price"
                  />
                )}
                {hasPreviousDiscountedPrice && (
                  <TableDataCell
                    borderRight={hasPreviousDiscountedPriceBorderRight}
                    className="font-bold"
                  >
                    <p>{formatCurrency(experiment.control_discounted_price)}</p>
                  </TableDataCell>
                )}
                {hasDiscountedPricePriceChange && (
                  <TableDataCell
                    borderRight={hasDiscountedPricePriceChangeBorderRight}
                  >
                    <p>
                      {formatPricePercentChange(
                        experiment.treatment_discounted_price,
                        experiment.control_discounted_price,
                      )}
                    </p>
                  </TableDataCell>
                )}
                {hasNewDiscountedPercent && (
                  <TableDataCell
                    borderRight={hasNewDiscountedPercentBorderRight}
                  >
                    <p>
                      {formatPricePercentChange(
                        experiment.treatment_discounted_price,
                        experiment.treatment_list_price,
                        true,
                      )}
                    </p>
                  </TableDataCell>
                )}
                {hasPreviousDiscountedPercent && (
                  <TableDataCell
                    borderRight={hasPreviousDiscountedPercentBorderRight}
                  >
                    <p>
                      {formatPricePercentChange(
                        experiment.control_discounted_price,
                        experiment.control_list_price,
                        true,
                      )}
                    </p>
                  </TableDataCell>
                )}
                {hasNewMemberPrice && (
                  <EditPriceCell
                    borderRight={hasNewMemberPriceBorderRight}
                    experiment={experiment}
                    priceField="treatment_member_price"
                  />
                )}
                {hasPreviousMemberPrice && (
                  <TableDataCell
                    borderRight={hasPreviousMemberPriceBorderRight}
                    className="font-bold"
                  >
                    <p>{formatCurrency(experiment.control_member_price)}</p>
                  </TableDataCell>
                )}
                {hasMemberPricePriceChange && (
                  <TableDataCell
                    borderRight={hasMemberPricePriceChangeBorderRight}
                  >
                    <p>
                      {formatPricePercentChange(
                        experiment.treatment_member_price,
                        experiment.control_member_price,
                      )}
                    </p>
                  </TableDataCell>
                )}
                {hasNewMemberDiscountedPercent && (
                  <TableDataCell
                    borderRight={hasNewMemberDiscountedPercentBorderRight}
                  >
                    <p>
                      {formatPricePercentChange(
                        experiment.treatment_member_price,
                        experiment.treatment_list_price,
                        true,
                      )}
                    </p>
                  </TableDataCell>
                )}
                {hasPreviousMemberDiscountedPercent && (
                  <TableDataCell
                    borderRight={hasPreviousMemberDiscountedPercentBorderRight}
                  >
                    <p>
                      {formatPricePercentChange(
                        experiment.control_member_price,
                        experiment.control_list_price,
                        true,
                      )}
                    </p>
                  </TableDataCell>
                )}
                {hasNewSubscriberPrice && (
                  <EditPriceCell
                    borderRight={hasNewDiscountedPriceBorderRight}
                    experiment={experiment}
                    priceField="treatment_subscriber_price"
                  />
                )}
                {hasPreviousSubscriberPrice && (
                  <TableDataCell
                    borderRight={hasPreviousSubscriberPriceBorderRight}
                    className="font-bold"
                  >
                    <p>{formatCurrency(experiment.control_subscriber_price)}</p>
                  </TableDataCell>
                )}
                {hasSubscriberPricePriceChange && (
                  <TableDataCell
                    borderRight={hasSubscriberPricePriceChangeBorderRight}
                  >
                    <p>
                      {formatPricePercentChange(
                        experiment.treatment_subscriber_price,
                        experiment.control_subscriber_price,
                      )}
                    </p>
                  </TableDataCell>
                )}
                {hasNewSubscriberDiscountedPercent && (
                  <TableDataCell
                    borderRight={hasNewSubscriberDiscountedPercentBorderRight}
                  >
                    <p>
                      {formatPricePercentChange(
                        experiment.treatment_subscriber_price,
                        experiment.treatment_list_price,
                        true,
                      )}
                    </p>
                  </TableDataCell>
                )}
                {hasPreviousSubscriberDiscountedPercent && (
                  <TableDataCell
                    borderRight={
                      hasPreviousSubscriberDiscountedPercentBorderRight
                    }
                  >
                    <p>
                      {formatPricePercentChange(
                        experiment.control_subscriber_price,
                        experiment.control_list_price,
                        true,
                      )}
                    </p>
                  </TableDataCell>
                )}
                {hasInventory && (
                  <TableDataCell borderRight={hasInventoryBorderRight}>
                    {formatNumberRounded(experiment.inventory_level)}
                  </TableDataCell>
                )}
                {hasNewListPriceMargin && (
                  <TableDataCell borderRight={hasNewListPriceMarginBorderRight}>
                    <p>{formatPercentage(experiment.treatment_price_margin)}</p>
                  </TableDataCell>
                )}
                {hasNewListPriceContributionMargin && (
                  <TableDataCell
                    borderRight={hasNewListPriceContributionMarginBorderRight}
                  >
                    <p>
                      {formatCurrency(
                        experiment.treatment_list_price_contribution_margin,
                      )}
                    </p>
                  </TableDataCell>
                )}
                {hasPreviousListPriceMargin && (
                  <TableDataCell
                    borderRight={hasPreviousListPriceMarginBorderRight}
                  >
                    <p>{formatPercentage(experiment.control_price_margin)}</p>
                  </TableDataCell>
                )}
                {hasPreviousListPriceContributionMargin && (
                  <TableDataCell
                    borderRight={
                      hasPreviousListPriceContributionMarginBorderRight
                    }
                  >
                    <p>
                      {formatCurrency(
                        experiment.control_price_contribution_margin,
                      )}
                    </p>
                  </TableDataCell>
                )}
                {hasNewDiscountedPriceMargin && (
                  <TableDataCell
                    borderRight={hasNewDiscountedPriceMarginBorderRight}
                  >
                    <p>
                      {formatPercentage(
                        experiment.treatment_discounted_price_margin,
                      )}
                    </p>
                  </TableDataCell>
                )}
                {hasNewDiscountedPriceContributionMargin && (
                  <TableDataCell
                    borderRight={
                      hasNewDiscountedPriceContributionMarginBorderRight
                    }
                  >
                    <p>
                      {formatCurrency(
                        experiment.treatment_discounted_price_contribution_margin,
                      )}
                    </p>
                  </TableDataCell>
                )}
                {hasPreviousDiscountedPriceMargin && (
                  <TableDataCell
                    borderRight={hasPreviousDiscountedPriceMarginBorderRight}
                  >
                    <p>
                      {formatPercentage(
                        experiment.control_discounted_price_margin,
                      )}
                    </p>
                  </TableDataCell>
                )}
                {/* // TODO-[MIKE]: contribution margin */}
                {hasPreviousDiscountedPriceContributionMargin && (
                  <TableDataCell
                    borderRight={
                      hasPreviousDiscountedPriceContributionMarginBorderRight
                    }
                  >
                    <p>
                      {formatCurrency(
                        experiment.control_discounted_price_contribution_margin,
                      )}
                    </p>
                  </TableDataCell>
                )}
                {hasNewMemberPriceMargin && (
                  <TableDataCell
                    borderRight={hasNewMemberPriceMarginBorderRight}
                  >
                    <p>
                      {formatPercentage(
                        experiment.treatment_member_price_margin,
                      )}
                    </p>
                  </TableDataCell>
                )}
                {hasNewMemberPriceContributionMargin && (
                  <TableDataCell
                    borderRight={hasNewMemberPriceContributionMarginBorderRight}
                  >
                    <p>
                      {formatCurrency(
                        experiment.treatment_member_price_contribution_margin,
                      )}
                    </p>
                  </TableDataCell>
                )}
                {hasPreviousMemberPriceMargin && (
                  <TableDataCell
                    borderRight={hasPreviousMemberPriceMarginBorderRight}
                  >
                    <p>
                      {formatPercentage(experiment.control_member_price_margin)}
                    </p>
                  </TableDataCell>
                )}
                {hasPreviousMemberPriceContributionMargin && (
                  <TableDataCell
                    borderRight={
                      hasPreviousMemberPriceContributionMarginBorderRight
                    }
                  >
                    <p>
                      {formatCurrency(
                        experiment.control_discounted_price_contribution_margin,
                      )}
                    </p>
                  </TableDataCell>
                )}
                {hasNewSubscriberPriceMargin && (
                  <TableDataCell
                    borderRight={hasNewSubscriberPriceMarginBorderRight}
                  >
                    <p>
                      {formatPercentage(
                        experiment.treatment_subscriber_price_margin,
                      )}
                    </p>
                  </TableDataCell>
                )}
                {hasNewSubscriberPriceContributionMargin && (
                  <TableDataCell
                    borderRight={
                      hasNewSubscriberPriceContributionMarginBorderRight
                    }
                  >
                    <p>
                      {formatCurrency(
                        experiment.treatment_subscriber_price_contribution_margin,
                      )}
                    </p>
                  </TableDataCell>
                )}
                {hasPreviousSubscriberPriceMargin && (
                  <TableDataCell
                    borderRight={hasPreviousSubscriberPriceMarginBorderRight}
                  >
                    <p>
                      {formatPercentage(
                        experiment.control_subscriber_price_margin,
                      )}
                    </p>
                  </TableDataCell>
                )}
                {hasPreviousSubscriberPriceContributionMargin && (
                  <TableDataCell
                    borderRight={
                      hasPreviousSubscriberPriceContributionMarginBorderRight
                    }
                  >
                    <p>
                      {formatCurrency(
                        experiment.control_subscriber_price_contribution_margin,
                      )}
                    </p>
                  </TableDataCell>
                )}
                {hasCost && (
                  <TableDataCell borderRight={hasCostBorderRight}>
                    <p>{formatCurrency(experiment.cpu_current)}</p>
                  </TableDataCell>
                )}
                {hasRevenue30d && (
                  <TableDataCell borderRight={hasRevenue30dBorderRight}>
                    <p>{formatCurrency(experiment?.revenue_30d)}</p>
                  </TableDataCell>
                )}
                {hasUnitsSold30d && (
                  <TableDataCell borderRight={hasUnitsSold30dBorderRight}>
                    <p>{formatNumberRounded(experiment?.units_sold_30d)}</p>
                  </TableDataCell>
                )}
                {hasProfit30d && (
                  <TableDataCell borderRight={hasProfit30dBorderRight}>
                    <p>{formatCurrency(experiment?.profit_30d)}</p>
                  </TableDataCell>
                )}
                {hasRevenueRank30d && (
                  <TableDataCell borderRight={hasRevenueRank30dBorderRight}>
                    <p>{formatNumberRounded(experiment?.revenue_rank_30d)}</p>
                  </TableDataCell>
                )}
                {hasUnitsSoldRank30d && (
                  <TableDataCell borderRight={hasUnitsSoldRank30dBorderRight}>
                    <p>
                      {formatNumberRounded(experiment?.units_sold_rank_30d)}
                    </p>
                  </TableDataCell>
                )}
                {hasProfitRank30d && (
                  <TableDataCell borderRight={hasProfitRank30dBorderRight}>
                    <p>{formatNumberRounded(experiment?.profit_rank_30d)}</p>
                  </TableDataCell>
                )}
                {hasRevenue60d && (
                  <TableDataCell borderRight={hasRevenue60dBorderRight}>
                    <p>{formatCurrency(experiment?.revenue_60d)}</p>
                  </TableDataCell>
                )}
                {hasUnitsSold60d && (
                  <TableDataCell borderRight={hasUnitsSold60dBorderRight}>
                    <p>{formatNumberRounded(experiment?.units_sold_60d)}</p>
                  </TableDataCell>
                )}
                {hasProfit60d && (
                  <TableDataCell borderRight={hasProfit60dBorderRight}>
                    <p>{formatCurrency(experiment?.profit_60d)}</p>
                  </TableDataCell>
                )}
                {hasRevenueRank60d && (
                  <TableDataCell borderRight={hasRevenueRank60dBorderRight}>
                    <p>{formatNumberRounded(experiment?.revenue_rank_60d)}</p>
                  </TableDataCell>
                )}
                {hasUnitsSoldRank60d && (
                  <TableDataCell borderRight={hasUnitsSoldRank60dBorderRight}>
                    <p>
                      {formatNumberRounded(experiment?.units_sold_rank_60d)}
                    </p>
                  </TableDataCell>
                )}
                {hasProfitRank60d && (
                  <TableDataCell borderRight={hasProfitRank60dBorderRight}>
                    <p>{formatNumberRounded(experiment?.profit_rank_60d)}</p>
                  </TableDataCell>
                )}
                {hasRevenue90d && (
                  <TableDataCell borderRight={hasRevenue90dBorderRight}>
                    <p>{formatCurrency(experiment?.revenue_90d)}</p>
                  </TableDataCell>
                )}
                {hasUnitsSold90d && (
                  <TableDataCell borderRight={hasUnitsSold90dBorderRight}>
                    <p>{formatNumberRounded(experiment?.units_sold_90d)}</p>
                  </TableDataCell>
                )}
                {hasProfit90d && (
                  <TableDataCell borderRight={hasProfit90dBorderRight}>
                    <p>{formatCurrency(experiment?.profit_90d)}</p>
                  </TableDataCell>
                )}
                {hasRevenueRank90d && (
                  <TableDataCell borderRight={hasRevenueRank90dBorderRight}>
                    <p>{formatNumberRounded(experiment?.revenue_rank_90d)}</p>
                  </TableDataCell>
                )}
                {hasUnitsSoldRank90d && (
                  <TableDataCell borderRight={hasUnitsSoldRank90dBorderRight}>
                    <p>
                      {formatNumberRounded(experiment?.units_sold_rank_90d)}
                    </p>
                  </TableDataCell>
                )}
                {hasProfitRank90d && (
                  <TableDataCell borderRight={hasProfitRank90dBorderRight}>
                    <p>{formatNumberRounded(experiment?.profit_rank_90d)}</p>
                  </TableDataCell>
                )}
                {hasRevenue120d && (
                  <TableDataCell borderRight={hasRevenue120dBorderRight}>
                    <p>{formatCurrency(experiment?.revenue_120d)}</p>
                  </TableDataCell>
                )}
                {hasUnitsSold120d && (
                  <TableDataCell borderRight={hasUnitsSold120dBorderRight}>
                    <p>{formatNumberRounded(experiment?.units_sold_120d)}</p>
                  </TableDataCell>
                )}
                {hasProfit120d && (
                  <TableDataCell borderRight={hasProfit120dBorderRight}>
                    <p>{formatCurrency(experiment?.profit_120d)}</p>
                  </TableDataCell>
                )}
                {hasRevenueRank120d && (
                  <TableDataCell borderRight={hasRevenueRank120dBorderRight}>
                    <p>{formatNumberRounded(experiment?.revenue_rank_120d)}</p>
                  </TableDataCell>
                )}
                {hasUnitsSoldRank120d && (
                  <TableDataCell borderRight={hasUnitsSoldRank120dBorderRight}>
                    <p>
                      {formatNumberRounded(experiment?.units_sold_rank_120d)}
                    </p>
                  </TableDataCell>
                )}
                {hasProfitRank120d && (
                  <TableDataCell borderRight={hasProfitRank120dBorderRight}>
                    <p>{formatNumberRounded(experiment?.profit_rank_120d)}</p>
                  </TableDataCell>
                )}

                {hasUnitsSoldImpact && (
                  <TableDataCell borderRight={hasUnitsSoldImpactBorderRight}>
                    {formatDelta(
                      impactSegment?.units_sold_change ?? null,
                      formatNumberRounded(
                        impactSegment?.units_sold_change ?? null,
                      ),
                    )}
                    (
                    {formatSignedPercent(
                      impactSegment?.units_sold_change_percent ?? null,
                    )}
                    )
                  </TableDataCell>
                )}
                {hasRevenueImpact && (
                  <TableDataCell borderRight={hasRevenueImpactBorderRight}>
                    {formatCurrencyDelta(impactSegment?.revenue_change ?? null)}{" "}
                    (
                    {formatSignedPercent(
                      impactSegment?.revenue_change_percent ?? null,
                    )}
                    )
                  </TableDataCell>
                )}
                {hasProfitImpact && (
                  <TableDataCell borderRight={hasProfitImpactBorderRight}>
                    {formatCurrencyDelta(impactSegment?.profit_change ?? null)}{" "}
                    (
                    {formatSignedPercent(
                      impactSegment?.profit_change_percent ?? null,
                    )}
                    )
                  </TableDataCell>
                )}

                {hasNextThirtyUnitsSold && (
                  <TableDataCell
                    borderRight={hasNextThirtyUnitsSoldBorderRight}
                  >
                    <p>{opportunitySegment?.units_sold_next_thirty ?? "--"}</p>
                  </TableDataCell>
                )}
                {hasNextThirtyRevenue && (
                  <TableDataCell borderRight={hasNextThirtyRevenueBorderRight}>
                    <p>
                      {formatCurrency(opportunitySegment?.revenue_next_thirty)}
                    </p>
                  </TableDataCell>
                )}
                {hasNextThirtyProfit && (
                  <TableDataCell borderRight={hasNextThirtyProfitBorderRight}>
                    <p>
                      {formatCurrency(opportunitySegment?.profit_next_thirty)}
                    </p>
                  </TableDataCell>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="relative left-0 z-50 -mt-24 flex justify-center">
        {experimentForecastQuery.isFetchingNextPage && (
          <div className="rounded-full bg-n-0/90 p-4 shadow backdrop-blur-sm">
            <Spinner />
          </div>
        )}
      </div>
      {isLoading === false &&
        experimentForecastQuery.isLoading === false &&
        experiments.length === 0 && (
          <div className="absolute left-0 top-0 z-10 flex h-full min-h-[200px] w-full items-center justify-center rounded-3xl bg-white bg-opacity-80 dark:bg-black dark:bg-opacity-20">
            <div className="flex w-full justify-center">No results found.</div>
          </div>
        )}
      {isLoading ||
        (experimentForecastQuery.isLoading &&
          experimentForecastQuery.isFetchingNextPage === false && (
            <div className="absolute left-0 top-0 z-10 flex h-full min-h-[200px] w-full items-center justify-center rounded-3xl bg-white bg-opacity-80 dark:bg-black dark:bg-opacity-20">
              <Spinner />
            </div>
          ))}
    </div>
  );
}
