import axios, { AxiosRequestConfig } from "axios";
import getApiRequestHeaders from "src/frontend/utils/getApiRequestHeaders";
import getExpressApiUrlFrontend from "src/frontend/utils/getExpressApiUrlFrontend";
import logClientError from "src/frontend/utils/logClientError";
import {
  DownloadCompetitorIntelligenceCsvPayloadType,
  DownloadCompetitorIntelligenceCsvResponse,
  DownloadCompetitorIntelligenceCsvResponseType,
} from "src/shared/api/downloadCompetitorIntelligenceCsvApiSchema";
import {
  DownloadNewPricePlanCsvApiPayloadType,
  DownloadNewPricePlanCsvApiResponseType,
  DownloadNewPricePlanCsvApiResponse,
} from "src/shared/api/downloadNewPricePlanCsvApiSchema";
import {
  DownloadProductCatalogCsvPayloadType,
  DownloadProductCatalogCsvResponse,
  DownloadProductCatalogCsvResponseType,
} from "src/shared/api/downloadProductCatalogCsvApiSchema";
import { DownloadResultsPricePlanCsvApiPayloadType } from "src/shared/api/downloadResultsPricePlanCsvApiSchema";
import {
  PlaceholderApiRequestPayloadType,
  PlaceholderApiResponse,
  PlaceholderApiResponseType,
} from "src/shared/api/placeholderApiSchema";
import SentryErrorEvent from "src/shared/utils/sentryErrorUtils";

class ApiClient {
  placeholderApi = async (
    payload: PlaceholderApiRequestPayloadType,
  ): Promise<PlaceholderApiResponseType> => {
    try {
      const url = this.getUrl("placeholder");
      const config = await this.getRequestConfig();
      const response = await axios.post<PlaceholderApiResponseType>(
        url,
        payload,
        config,
      );
      return PlaceholderApiResponse.parse(response.data);
    } catch (err: any) {
      logClientError(err, SentryErrorEvent.ClientApiError, { extra: payload });
      throw err;
    }
  };

  downloadNewPricePlanCsv = async (
    payload: DownloadNewPricePlanCsvApiPayloadType,
  ): Promise<DownloadNewPricePlanCsvApiResponseType> => {
    try {
      const url = this.getUrl("download/new-price-plan");
      const config = await this.getRequestConfig();
      const response = await axios.post<DownloadNewPricePlanCsvApiResponseType>(
        url,
        payload,
        config,
      );
      return DownloadNewPricePlanCsvApiResponse.parse(response.data);
    } catch (err: any) {
      logClientError(err, SentryErrorEvent.ClientApiError, { extra: payload });
      throw err;
    }
  };

  downloadResultsPricePlanCsv = async (
    payload: DownloadResultsPricePlanCsvApiPayloadType,
  ): Promise<DownloadNewPricePlanCsvApiResponseType> => {
    try {
      const url = this.getUrl("download/results-price-plan");
      const config = await this.getRequestConfig();
      const response = await axios.post<DownloadNewPricePlanCsvApiResponseType>(
        url,
        payload,
        config,
      );
      return DownloadNewPricePlanCsvApiResponse.parse(response.data);
    } catch (err: any) {
      logClientError(err, SentryErrorEvent.ClientApiError, { extra: payload });
      throw err;
    }
  };

  downloadProductCatalogCsv = async (
    payload: DownloadProductCatalogCsvPayloadType,
  ): Promise<DownloadProductCatalogCsvResponseType> => {
    try {
      const url = this.getUrl("download/product-catalog");
      const config = await this.getRequestConfig();
      const response = await axios.post<DownloadProductCatalogCsvResponseType>(
        url,
        payload,
        config,
      );
      return DownloadProductCatalogCsvResponse.parse(response.data);
    } catch (err: any) {
      logClientError(err, SentryErrorEvent.ClientApiError, { extra: payload });
      throw err;
    }
  };

  downloadCompetitorIntelligenceCsv = async (
    payload: DownloadCompetitorIntelligenceCsvPayloadType,
  ): Promise<DownloadCompetitorIntelligenceCsvResponseType> => {
    try {
      const url = this.getUrl("download/competitor-intelligence");
      const config = await this.getRequestConfig();
      const response =
        await axios.post<DownloadCompetitorIntelligenceCsvResponseType>(
          url,
          payload,
          config,
        );
      return DownloadCompetitorIntelligenceCsvResponse.parse(response.data);
    } catch (err: any) {
      logClientError(err, SentryErrorEvent.ClientApiError, { extra: payload });
      throw err;
    }
  };

  private getUrl = (path: string): string => {
    const base = getExpressApiUrlFrontend();
    return `${base}/${path}`;
  };

  private getRequestConfig = async (): Promise<AxiosRequestConfig> => {
    return { headers: await getApiRequestHeaders() };
  };
}

const apiClient = new ApiClient();

export default apiClient;
