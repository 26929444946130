import { useParams } from "react-router";
import TrpcClient from "src/frontend/api/TrpcClient";
import useIsBrandInitialized from "src/frontend/hooks/useIsBrandInitialized";

export default function useGetBasicPricingStrategyByIdQuery() {
  const { pricingStrategyId } = useParams();
  const { enabled } = useIsBrandInitialized();
  return TrpcClient.internal.getBasicPricingStrategyById.useQuery(
    {
      pricingStrategyId: pricingStrategyId!,
    },
    {
      enabled: enabled && pricingStrategyId != null,
    },
  );
}
