import { useQueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@trpc/react-query";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  InternalTRPCInput,
  InternalTRPCOutput,
} from "src/backend/internal-api/internalTrpcRouter";
import TrpcClient from "src/frontend/api/TrpcClient";
import Code from "src/frontend/components/Code";
import { CopyText } from "src/frontend/components/CopyText";
import EditText from "src/frontend/components/EditText";
import PageTitle from "src/frontend/components/PageTitle";
import Row from "src/frontend/components/Row";
import { Badge } from "src/frontend/components/ui/Badge";
import { Skeleton } from "src/frontend/components/ui/Skeleton";
import useToast from "src/frontend/components/ui/useToast";
import useGetPricePlanSummaryQuery from "src/frontend/hooks/queries/useGetPricePlanSummaryQuery";
import formatGenericEnumLabel from "src/frontend/utils/formatGenericEnumLabel";
import formatDate from "src/shared/utils/dates/formatDate";
import invariant from "tiny-invariant";

export default function PricePlanName() {
  const pricePlanSummaryQuery = useGetPricePlanSummaryQuery();
  const pricePlan = pricePlanSummaryQuery.data?.price_plan;

  const queryKey = getQueryKey(
    TrpcClient.internal.getPricePlanSummary,
    { pricePlanId: pricePlan?.id },
    "query",
  );

  type GetPricePlanSummaryOutput = InternalTRPCOutput["getPricePlanSummary"];
  type EditPricePlanInput = InternalTRPCInput["editPricePlan"];

  const editPricePlanMutation = TrpcClient.internal.editPricePlan.useMutation({
    onError: (err, input, context) => {
      t.errorToast("Failed to save edits.");
      queryClient.setQueryData<GetPricePlanSummaryOutput>(
        queryKey,
        context?.prevData,
      );
    },
    onMutate: async (input: EditPricePlanInput) => {
      await queryClient.cancelQueries(queryKey);
      const prevData =
        queryClient.getQueryData<GetPricePlanSummaryOutput>(queryKey);
      queryClient.setQueryData<GetPricePlanSummaryOutput>(queryKey, (prev) => {
        if (prev?.price_plan == null) {
          return prev;
        }

        return {
          ...prev,
          price_plan: {
            ...prev.price_plan,
            title: input.title,
          },
        };
      });
      return { prevData };
    },
    onSettled: () => {
      void queryClient.invalidateQueries([queryKey]);
    },
  });

  const t = useToast();
  const queryClient = useQueryClient();
  const { devFeaturesEnabled } = useFlags();

  const handleEditPricePlanTitle = (title: string) => {
    invariant(pricePlan != null);

    editPricePlanMutation.mutate({
      pricePlanId: pricePlan.id,
      title,
    });
  };

  const publishDate = pricePlan?.published_on;
  const launchDate = pricePlan?.start_time;

  const showPublishDate = publishDate != null && launchDate == null;
  const showLaunchDate = launchDate != null;

  return pricePlanSummaryQuery.isLoading || pricePlan == null ? (
    <Skeleton className="h-8 w-[300px]" />
  ) : (
    <PageTitle>
      <Row className="gap-2">
        <Badge className="font-normal">
          {formatGenericEnumLabel(pricePlan.status)}
        </Badge>
        <p className="text-sm font-normal italic text-neutral-400">
          {showPublishDate && (
            <p className="text-sm">
              Published on {formatDate(publishDate, "MM/DD/YYYY h:mma")}
            </p>
          )}
          {showLaunchDate && (
            <p className="text-sm">
              Launched on {formatDate(launchDate, "MM/DD/YYYY h:mma")}
            </p>
          )}
        </p>
      </Row>

      <EditText
        className="[&>p]:text-lg"
        disableEditing={pricePlan == null || pricePlan.generation_in_progress}
        disabled={pricePlan == null || pricePlan.generation_in_progress}
        isSaving={editPricePlanMutation.isLoading}
        onSave={(title) => handleEditPricePlanTitle(title)}
        placeholder="Edit price plan title"
        value={pricePlan.title}
      />
      <Row className="my-1 gap-2">
        {devFeaturesEnabled && pricePlan?.id != null && (
          <CopyText
            className="hover:cursor-pointer"
            isCode
            textToCopy={pricePlan.id}
          >
            <p className="text-xs font-semibold">
              <Code>{pricePlan.id}</Code>
            </p>
          </CopyText>
        )}
      </Row>
    </PageTitle>
  );
}
