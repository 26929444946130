import { IconTrash } from "@tabler/icons-react";
import { Rocket } from "lucide-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { CreateOrUpdatePricingRulesGroupInputType } from "src/backend/internal-api/mutations/createOrUpdatePricingRulesGroupMutation";
import { GetBrandProductCategoriesQueryResponseType } from "src/backend/internal-api/queries/getBrandProductCategoriesQuery";
import { GetsTagsQueryResponseType } from "src/backend/internal-api/queries/getTagsQuery";
import TrpcClient from "src/frontend/api/TrpcClient";
import Card from "src/frontend/components/Card";
import Col from "src/frontend/components/Col";
import EditText from "src/frontend/components/EditText";
import Header from "src/frontend/components/Header";
import LightLabel from "src/frontend/components/LightLabel";
import { NavigateInterruptPrompt } from "src/frontend/components/NavigateInterruptPrompt";
import PageContainer from "src/frontend/components/PageContainer";
import PageTitle from "src/frontend/components/PageTitle";
import ProductCategoryFilter from "src/frontend/components/ProductCategoryFilter";
import Row from "src/frontend/components/Row";
import SelectedProductFiltersList from "src/frontend/components/SelectedProductFiltersList";
import ErrorLabel from "src/frontend/components/error/ErrorLabel";
import ErrorPage from "src/frontend/components/error/ErrorPage";
import CreateUpdatePricingStrategyLoadingSkeleton from "src/frontend/components/skeletons/CreateUpdatePricingStrategyLoadingSkeleton";
import Button from "src/frontend/components/ui/Button";
import Checkbox from "src/frontend/components/ui/Checkbox";
import { FancyBoxItem } from "src/frontend/components/ui/FancyBox";
import { Prompt } from "src/frontend/components/ui/Prompt";
import { Switch } from "src/frontend/components/ui/Switch";
import useToast from "src/frontend/components/ui/useToast";
import ALL_SKUS_FANCY_BOX_ITEM from "src/frontend/constants/AllSkusFancyBoxItem";
import ALL_SKUS_SELECT_VALUE from "src/frontend/constants/AllSkusSelectValue";
import useGetBasicPricingStrategyByIdQuery from "src/frontend/hooks/queries/useGetBasicPricingStrategyByIdQuery";
import useGetBrandProductCategoriesQuery from "src/frontend/hooks/queries/useGetBrandProductCategoriesQuery";
import useGetCombinedPricingStrategySkuCountQuery from "src/frontend/hooks/queries/useGetCombinedPricingStrategySkuCountQuery";
import useGetCompetitorBrandNamesQuery from "src/frontend/hooks/queries/useGetCompetitorBrandNamesQuery";
import useGetPricingRuleGroupByIdQuery from "src/frontend/hooks/queries/useGetPricingRuleGroupByIdQuery";
import useGetPricingStrategyByIdQuery from "src/frontend/hooks/queries/useGetPricingStrategyByIdQuery";
import useTagsQuery from "src/frontend/hooks/queries/useTagsQuery";
import useBreakpoints from "src/frontend/hooks/useBreakpoints";
import AddSkusDialog from "src/frontend/pages/company/rules/AddSkusDialog";
import ConstraintDialog from "src/frontend/pages/company/rules/ConstraintDialog";
import ConstraintsList from "src/frontend/pages/company/rules/ConstraintsList";
import SkusCountLabel from "src/frontend/pages/company/rules/SkusCountLabel";
import useCreatePricingStrategyStore, {
  CreateUpdatePricingStrategyInput,
} from "src/frontend/stores/useCreatePricingStrategyStore";
import usePricingRulesGroupConstraintStore from "src/frontend/stores/usePricingRulesGroupConstraintStore";
import getCategoryFiltersFromBrandCategoriesAndTags from "src/frontend/utils/getCategoryFiltersFromBrandCategoriesAndTags";
import getCustomSkusFancyBoxItem, {
  CUSTOM_SKUS_SELECT_VALUE,
} from "src/frontend/utils/getCustomSkusFancyBoxItem";
import getInitialCategoryFilters from "src/frontend/utils/getInitialCategoryFilters";
import parseImpactedSkusSelection from "src/frontend/utils/parseImpactedSkusSelection";
import toastTrpcErrorResponse from "src/frontend/utils/toastTrpcErrorResponse";
import { PricingConstraintType } from "src/shared/trpc/common/PricingConstraint";
import { PricingRulesGroupType } from "src/shared/trpc/common/PricingRulesGroup";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import arrayNotEmpty from "src/shared/utils/arrays/arrayNotEmpty";
import sortByProperty from "src/shared/utils/arrays/sortByProperty";
import deepEquals from "src/shared/utils/objects/deepEquals";
import uuid from "src/shared/utils/uuid";
import isImpactedSkusValid from "src/shared/validation/isImpactedSkusValid";
import isStrategyNameValid from "src/shared/validation/isStrategyNameValid";

function getInitialSelectedSkus(strategy?: PricingRulesGroupType) {
  const {
    impacted_categories = [],
    impacted_price_zones = [],
    impacted_product_brand_names = [],
    impacted_sub_categories = [],
    impacted_suppliers = [],
    impacted_tags = [],
  } = strategy ?? {};
  return getInitialCategoryFilters({
    categories: impacted_categories,
    priceZones: impacted_price_zones,
    productBrandNames: impacted_product_brand_names,
    rulesGroup: strategy,
    subCategories: impacted_sub_categories,
    suppliers: impacted_suppliers,
    tags: impacted_tags,
  });
}

type InnerProps = {
  pricingStrategyId: string;
  productCategories: GetBrandProductCategoriesQueryResponseType;
  rulesGroup?: PricingRulesGroupType;
  tags: GetsTagsQueryResponseType;
};

function Inner({
  pricingStrategyId,
  productCategories,
  rulesGroup,
  tags,
}: InnerProps) {
  const navigate = useNavigate();
  const { isSmall } = useBreakpoints();
  const { errorToast, successToast } = useToast();
  const [showErrors, setShowErrors] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isPromptOpen, setIsPromptOpen] = useState(false);
  const [constraintV2DialogOpen, setConstraintV2DialogOpen] = useState(false);
  const [configurationStepOpened, setConfigurationStepOpened] = useState(
    rulesGroup != null,
  );

  const initialImpactedSkusOptions =
    getCategoryFiltersFromBrandCategoriesAndTags(
      tags,
      productCategories,
      rulesGroup,
      true,
    );
  const initialSelectedSkus = getInitialSelectedSkus(rulesGroup);
  const initialStrategyInput: CreateUpdatePricingStrategyInput = useMemo(() => {
    return {
      applyAndConditionForCategory:
        rulesGroup?.applyAndConditionForCategory ?? false,
      applyAndConditionForFilters:
        rulesGroup?.applyAndConditionForFilters ?? false,
      applyAndConditionForPriceZone:
        rulesGroup?.applyAndConditionForPriceZone ?? false,
      applyAndConditionForProductBrandName:
        rulesGroup?.applyAndConditionForProductBrandName ?? false,
      applyAndConditionForSubCategory:
        rulesGroup?.applyAndConditionForSubCategory ?? false,
      applyAndConditionForSupplier:
        rulesGroup?.applyAndConditionForSupplier ?? false,
      applyAndConditionForTags: rulesGroup?.applyAndConditionForTags ?? false,
      constraints: rulesGroup?.constraints ?? [],
      customSkusList: rulesGroup?.impacted_skus ?? [],
      excludeSkus: rulesGroup?.exclude_skus ?? false,
      impactedSkus: initialSelectedSkus,
      impactedSkusOptions: initialImpactedSkusOptions,
      isActive: rulesGroup?.is_active ?? true,
      strategyName: rulesGroup?.name ?? "",
    };
  }, [rulesGroup, initialImpactedSkusOptions, initialSelectedSkus]);

  const state = useCreatePricingStrategyStore();
  const resetStore = useCreatePricingStrategyStore((store) => store.reset);

  useEffect(() => {
    resetStore();
    useCreatePricingStrategyStore.setState(initialStrategyInput);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rulesGroup]);

  const {
    constraints,
    customSkusList,
    excludeSkus,
    impactedSkus,
    impactedSkusOptions,
    isActive,
    strategyName,
  } = state;

  const selectedSkusToDisplay = arrayNotEmpty(customSkusList)
    ? impactedSkus.concat([getCustomSkusFancyBoxItem(customSkusList.length)])
    : impactedSkus;

  const formTouched = !deepEquals(initialStrategyInput, state);

  const pricingStrategyQuery = useGetPricingStrategyByIdQuery();
  const pricingRuleGroupQuery = useGetPricingRuleGroupByIdQuery();

  const skuCountQuery =
    useGetCombinedPricingStrategySkuCountQuery(pricingStrategyId);

  const createOrUpdatePricingStrategyMutation =
    TrpcClient.internal.createOrUpdatePricingRulesGroup.useMutation();

  const deletePricingStrategyMutation =
    TrpcClient.internal.deletePricingRulesGroup.useMutation();

  useEffect(() => {
    if (excludeSkus === true) {
      useCreatePricingStrategyStore.setState({ constraints: [] });
    }
  }, [excludeSkus]);

  const setSelectedItemsOverrideFn = (item: FancyBoxItem) => {
    if (item.value === ALL_SKUS_SELECT_VALUE) {
      useCreatePricingStrategyStore.setState({
        customSkusList: [],
        impactedSkus: [ALL_SKUS_FANCY_BOX_ITEM],
      });
    } else {
      useCreatePricingStrategyStore.setState((current) => {
        const currentItems = current.impactedSkus.filter(
          (item) => item.value !== ALL_SKUS_SELECT_VALUE,
        );
        const impactedSkus =
          currentItems.find((current) => current.value === item.value) == null
            ? currentItems.concat(item)
            : currentItems.filter((l) => l.value !== item.value);
        return {
          ...current,
          impactedSkus,
        };
      });
    }
  };

  const maybeOpenConfigurationStep = useCallback(() => {
    if (!configurationStepOpened) {
      if (strategyName !== "" && arrayNotEmpty(impactedSkus)) {
        setConfigurationStepOpened(true);
      }
    }
  }, [strategyName, impactedSkus, configurationStepOpened]);

  useEffect(() => {
    maybeOpenConfigurationStep();
  }, [impactedSkus, maybeOpenConfigurationStep]);

  const handleRemoveItem = (item: FancyBoxItem) => {
    if (item.value === CUSTOM_SKUS_SELECT_VALUE) {
      useCreatePricingStrategyStore.setState({ customSkusList: [] });
      return;
    }

    useCreatePricingStrategyStore.setState((current) => {
      const currentItems = current.impactedSkus;
      const newImpactedSkus = !currentItems.includes(item)
        ? currentItems.concat(item)
        : currentItems.filter((l) => l.value !== item.value);
      return {
        ...current,
        impactedSkus: newImpactedSkus,
      };
    });
  };

  const addConstraintV2 = (constraint: PricingConstraintType) => {
    state.setConstraints((current) =>
      current
        .concat({ ...constraint, id: uuid() })
        .map((val, index) => ({ ...val, priority: index })),
    );
  };

  const removeConstraintV2 = (constraint: PricingConstraintType) => {
    state.setConstraints((current) =>
      current.filter((val) => val.id !== constraint.id),
    );
  };

  const editConstraintV2 = (constraint: PricingConstraintType) => {
    state.setConstraints((current) =>
      current.map((val) => {
        if (val.id === constraint.id) {
          return constraint;
        } else {
          return val;
        }
      }),
    );
  };

  const validatePricingStrategy =
    (): MaybeNull<CreateOrUpdatePricingRulesGroupInputType> => {
      const {
        allSkus,
        impactedCategories,
        impactedPriceZones,
        impactedProductBrandNames,
        impactedSubCategories,
        impactedSuppliers,
        impactedTags,
        includeEmptyCategory,
        includeEmptyPriceZone,
        includeEmptyProductBrandName,
        includeEmptySubCategory,
        includeEmptySupplier,
        includeUntaggedSkus,
      } = parseImpactedSkusSelection(impactedSkus);

      if (!isStrategyNameValid(strategyName)) {
        setShowErrors(true);
        return null;
      }

      const anyExclusionFilterEnabled =
        includeEmptyCategory ||
        includeEmptyProductBrandName ||
        includeEmptySubCategory ||
        includeEmptySupplier ||
        includeUntaggedSkus;
      if (
        !isImpactedSkusValid(selectedSkusToDisplay, anyExclusionFilterEnabled)
      ) {
        setShowErrors(true);
        return null;
      }

      const impacted_skus = customSkusList;

      // Reset the constraint priority based on the order in the list.
      const constraintsInputPriorityOrdered = constraints.map(
        (constraint, index) => {
          return {
            ...constraint,
            priority: index,
          };
        },
      );

      const input: CreateOrUpdatePricingRulesGroupInputType = {
        ...rulesGroup,
        all_skus: allSkus,
        apply_and_condition_for_category: state.applyAndConditionForCategory,
        apply_and_condition_for_filters: state.applyAndConditionForFilters,
        apply_and_condition_for_price_zone: state.applyAndConditionForPriceZone,
        apply_and_condition_for_product_brand_name:
          state.applyAndConditionForProductBrandName,
        apply_and_condition_for_subcategory:
          state.applyAndConditionForSubCategory,
        apply_and_condition_for_supplier: state.applyAndConditionForSupplier,
        apply_and_condition_for_tags: state.applyAndConditionForTags,
        constraints: constraintsInputPriorityOrdered,
        exclude_skus: excludeSkus,
        impacted_categories: impactedCategories,
        impacted_price_zones: impactedPriceZones,
        impacted_product_brand_names: impactedProductBrandNames,
        impacted_skus,
        impacted_sub_categories: impactedSubCategories,
        impacted_suppliers: impactedSuppliers,
        impacted_tag_ids: impactedTags,
        include_empty_category: includeEmptyCategory,
        include_empty_price_zone: includeEmptyPriceZone,
        include_empty_product_brand_name: includeEmptyProductBrandName,
        include_empty_sub_category: includeEmptySubCategory,
        include_empty_supplier: includeEmptySupplier,
        include_untagged_skus: includeUntaggedSkus,
        is_active: isActive,
        name: strategyName,
        pricing_strategy_id: pricingStrategyId,
      };

      setShowErrors(false);
      return input;
    };

  const onSuccess = async (verb: string) => {
    void skuCountQuery.refetch();
    void pricingStrategyQuery.refetch();
    await pricingRuleGroupQuery.refetch();
    setSubmitting(false);
    setIsPromptOpen(false);
    successToast(`Pricing strategy ${verb}d successfully.`);
    navigate(`/company-settings/strategy/${pricingStrategyId}`);
  };

  const handleSavePricingStrategy = () => {
    const input = validatePricingStrategy();
    if (input == null) {
      errorToast("Please fix the errors to continue.");
      return;
    }

    const verb = rulesGroup == null ? "create" : "update";
    setSubmitting(true);
    createOrUpdatePricingStrategyMutation.mutate(input, {
      onError: () => {
        setSubmitting(false);
        errorToast(`Failed to ${verb} pricing strategy.`);
      },
      onSuccess: (response) => {
        if (response.status === "success") {
          void onSuccess(verb);
        } else {
          errorToast(`Failed to ${verb} pricing strategy.`);
        }
      },
    });
  };

  const handleDeletePricingStrategy = (pricingStrategyId: string) => {
    setSubmitting(true);
    deletePricingStrategyMutation.mutate(
      { pricingRulesGroupId: pricingStrategyId },
      {
        onError: (err) => {
          setSubmitting(false);
          toastTrpcErrorResponse(
            errorToast,
            "Failed to delete pricing strategy.",
            err,
          );
        },
        onSuccess: () => {
          void onSuccess("delete");
        },
      },
    );
  };

  const mutationLoading =
    submitting ||
    deletePricingStrategyMutation.isLoading ||
    createOrUpdatePricingStrategyMutation.isLoading;

  const width = isSmall ? 225 : 320;

  const resetConstraintDialog = usePricingRulesGroupConstraintStore(
    (state) => state.reset,
  );

  const [constraintToEdit, setConstraintToEdit] =
    useState<MaybeNull<PricingConstraintType>>(null);

  return (
    <PageContainer enforceMaxWidth>
      <NavigateInterruptPrompt
        beforeUnload
        message="Are you sure you want to leave? Your changes will be discarded."
        when={formTouched && !submitting}
      />
      <Header
        backTo={`/company-settings/strategy/${pricingStrategyId}`}
        className="mb-6"
        disabled={mutationLoading}
        leftNode={
          <PageTitle>
            <EditText
              className="mb-1 mr-1"
              disableEditing={mutationLoading}
              disabled={mutationLoading}
              errorMessage={
                showErrors && !isStrategyNameValid(strategyName)
                  ? "Name is required."
                  : undefined
              }
              isSaving={mutationLoading}
              onSave={(title) => {
                useCreatePricingStrategyStore.setState({
                  strategyName: title,
                });
              }}
              placeholder="Rules Group Name"
              value={strategyName}
            />
          </PageTitle>
        }
        rightNode={
          <Row className="gap-2">
            <Switch
              checked={isActive}
              className="mr-2 h-9 w-56"
              disabled={mutationLoading}
              id="strategy-is-active-switch"
              label={isActive ? "Rules are live" : "Rule are inactive"}
              onCheckedChange={(isActive) =>
                useCreatePricingStrategyStore.setState({ isActive })
              }
            />
            <Button
              disabled={!formTouched || mutationLoading}
              onClick={handleSavePricingStrategy}
            >
              <Rocket className="mr-4" size={20} strokeWidth={3} />
              Save
            </Button>
            {rulesGroup != null && (
              <Prompt
                description="This will delete the current pricing rules and all defined rules."
                loading={mutationLoading}
                onConfirm={() => handleDeletePricingStrategy(rulesGroup.id)}
                onOpenChange={setIsPromptOpen}
                open={isPromptOpen}
                title="Delete Pricing Rules"
                trigger={
                  <Button
                    disabled={mutationLoading}
                    onClick={() => setIsPromptOpen(true)}
                    variant="basic"
                  >
                    <IconTrash size={20} strokeWidth={1.5} />
                  </Button>
                }
              />
            )}
          </Row>
        }
      />

      <Col className="gap-8">
        <Col className="gap-2">
          <p className="text-sm font-bold uppercase leading-tight tracking-widest">
            Step 1: Target
          </p>

          <Card className="p-4">
            <LightLabel>Impacted SKUs</LightLabel>
            <Row>
              <ProductCategoryFilter
                height={350}
                items={impactedSkusOptions}
                selectTitle="Select product groups"
                selectedItems={impactedSkus}
                setSelectedItems={state.setImpactedSkus}
                setSelectedItemsOverrideFn={setSelectedItemsOverrideFn}
                width={width}
              />
              <AddSkusDialog
                disabled={mutationLoading}
                onAddSkus={(skus) => {
                  useCreatePricingStrategyStore.setState({
                    customSkusList: skus,
                  });
                  if (arrayNotEmpty(skus)) {
                    useCreatePricingStrategyStore.setState((current) => {
                      const currentItems = current.impactedSkus;
                      return {
                        impactedSkus: currentItems.filter(
                          (item) => item.value !== ALL_SKUS_SELECT_VALUE,
                        ),
                      };
                    });
                  }
                }}
                skuValues={customSkusList}
              />
            </Row>
            {showErrors && !isImpactedSkusValid(selectedSkusToDisplay) && (
              <ErrorLabel>Impacted SKUs selection is required.</ErrorLabel>
            )}
            <Row className="my-3 w-full items-start">
              <SkusCountLabel
                conditionFilters={{
                  applyAndConditionForCategory:
                    state.applyAndConditionForCategory,
                  applyAndConditionForFilters:
                    state.applyAndConditionForFilters,
                  applyAndConditionForPriceZone:
                    state.applyAndConditionForPriceZone,
                  applyAndConditionForProductBrandName:
                    state.applyAndConditionForProductBrandName,
                  applyAndConditionForSubCategory:
                    state.applyAndConditionForSubCategory,
                  applyAndConditionForSupplier:
                    state.applyAndConditionForSupplier,
                  applyAndConditionForTags: state.applyAndConditionForTags,
                }}
                customSkusList={customSkusList}
                impactedSkus={impactedSkus}
              />
              <SelectedProductFiltersList
                applyAndConditionForCategory={
                  state.applyAndConditionForCategory
                }
                applyAndConditionForFilters={state.applyAndConditionForFilters}
                applyAndConditionForPriceZone={
                  state.applyAndConditionForPriceZone
                }
                applyAndConditionForProductBrandName={
                  state.applyAndConditionForProductBrandName
                }
                applyAndConditionForSubCategory={
                  state.applyAndConditionForSubCategory
                }
                applyAndConditionForSupplier={
                  state.applyAndConditionForSupplier
                }
                applyAndConditionForTags={state.applyAndConditionForTags}
                className="ml-1"
                onClickRemove={handleRemoveItem}
                selectedItems={sortByProperty(selectedSkusToDisplay, "label")}
                showClearFiltersButton
                toggleCondition={(item, state) => {
                  useCreatePricingStrategyStore.setState({ [item]: state });
                }}
              />
            </Row>
          </Card>
        </Col>
        <Col className="gap-2">
          <Row className="justify-between">
            <Col className="gap-1">
              <p className="text-sm font-bold uppercase leading-tight tracking-widest">
                Step 2: Rules
              </p>

              <Checkbox
                checked={excludeSkus}
                id="exclude-from-price-changes-checkbox"
                label="Exclude from price changes"
                onCheckedChange={() => {
                  useCreatePricingStrategyStore.setState({
                    excludeSkus: !excludeSkus,
                  });
                }}
              />
            </Col>

            <ConstraintDialog
              constraintToEdit={constraintToEdit}
              disabled={excludeSkus || mutationLoading}
              key={JSON.stringify(constraintToEdit)}
              onOpenChange={(open) => {
                setConstraintV2DialogOpen(open);
                if (!open) {
                  resetConstraintDialog();
                  setConstraintToEdit(null);
                }
              }}
              onSave={(data: PricingConstraintType) => {
                if (constraintToEdit == null) {
                  addConstraintV2(data);
                } else {
                  editConstraintV2(data);
                }
              }}
              open={constraintV2DialogOpen}
            />
          </Row>
          <ConstraintsList
            constraints={constraints}
            disabled={excludeSkus || mutationLoading}
            removeConstraint={removeConstraintV2}
            setConstraintDialogOpen={setConstraintV2DialogOpen}
            setConstraintToEdit={setConstraintToEdit}
            setConstraints={state.setConstraints}
          />
        </Col>
      </Col>
    </PageContainer>
  );
}

export default function CreateUpdatePricingRulesGroup() {
  const { pricingStrategyId } = useParams();
  const pricingStrategyQuery = useGetBasicPricingStrategyByIdQuery();
  const getBrandProductCategoriesQuery = useGetBrandProductCategoriesQuery();
  const tagsQuery = useTagsQuery();
  useGetCompetitorBrandNamesQuery();

  const strategy = pricingStrategyQuery.data;
  const rulesGroup = useGetPricingRuleGroupByIdQuery();

  if (
    tagsQuery.isLoading ||
    pricingStrategyQuery.isLoading ||
    getBrandProductCategoriesQuery.isLoading ||
    rulesGroup.isLoading
  ) {
    return (
      <CreateUpdatePricingStrategyLoadingSkeleton
        pricingStrategyId={pricingStrategyId ?? null}
      />
    );
  }
  if (
    pricingStrategyId == null ||
    strategy == null ||
    tagsQuery.isError ||
    pricingStrategyQuery.isError ||
    getBrandProductCategoriesQuery.isError ||
    rulesGroup.isError
  ) {
    return <ErrorPage label="Failed to load pricing strategy." />;
  }

  return (
    <Inner
      pricingStrategyId={pricingStrategyId}
      productCategories={getBrandProductCategoriesQuery.data}
      rulesGroup={rulesGroup.data ?? undefined}
      tags={tagsQuery.data}
    />
  );
}
