import {
  IconDotsVertical,
  IconEdit,
  IconGripVertical,
  IconPlus,
} from "@tabler/icons-react";
import { ChevronDownIcon, Edit3Icon } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GetPricingStrategiesQueryResponseType } from "src/backend/internal-api/queries/getPricingStrategiesQuery";
import TrpcClient from "src/frontend/api/TrpcClient";
import Card from "src/frontend/components/Card";
import Col from "src/frontend/components/Col";
import EditText from "src/frontend/components/EditText";
import {
  PricingRulesGroupModals,
  PricingStrategyModals,
} from "src/frontend/components/GlobalModalsContainer";
import Header from "src/frontend/components/Header";
import ObjectiveTradeoffSummary from "src/frontend/components/ObjectiveTradeoffSummary";
import PageContainer from "src/frontend/components/PageContainer";
import PageTitle from "src/frontend/components/PageTitle";
import Row from "src/frontend/components/Row";
import ConstraintBound from "src/frontend/components/constraints/ConstraintBound";
import ErrorPage from "src/frontend/components/error/ErrorPage";
import SortableContainer from "src/frontend/components/sortable/SortableContainer";
import { SortableItem } from "src/frontend/components/sortable/SortableItem";
import { Badge } from "src/frontend/components/ui/Badge";
import Button from "src/frontend/components/ui/Button";
import { Skeleton } from "src/frontend/components/ui/Skeleton";
import Spinner from "src/frontend/components/ui/Spinner";
import Tooltip from "src/frontend/components/ui/Tooltip";
import useToast from "src/frontend/components/ui/useToast";
import { cn } from "src/frontend/components/ui/utils";
import useGetPricingStrategiesQuery from "src/frontend/hooks/queries/useGetPricingStrategiesQuery";
import useGetPricingStrategyByIdQuery from "src/frontend/hooks/queries/useGetPricingStrategyByIdQuery";
import PricingRulesGroupDropdownMenu from "src/frontend/pages/company/rules/PricingRulesGroupDropdownMenu";
import PricingRulesGroupIsActiveToggle from "src/frontend/pages/company/rules/PricingRulesGroupIsActiveToggle";
import StrategyDropdownMenu from "src/frontend/pages/company/strategy/StrategyDropdownMenu";
import StrategyObjectiveDialog from "src/frontend/pages/company/strategy/StrategyObjectiveDialog";
import useStrategyObjectiveStore from "src/frontend/stores/useStrategyObjectiveStore";
import formatGenericEnumLabel from "src/frontend/utils/formatGenericEnumLabel";
import openLinkInNewTab from "src/frontend/utils/openLinkInNewTab";
import DOCUMENTATION_WEBSITE from "src/shared/constants/DocumentationWebsite";
import { PricingRulesGroupType } from "src/shared/trpc/common/PricingRulesGroup";
import { PriceBoundType } from "src/shared/trpc/common/enum/PriceBoundType";
import { Maybe } from "src/shared/types/maybe/Maybe";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import MaybeNumber from "src/shared/types/maybe/MaybeNumber";
import len from "src/shared/utils/arrays/len";
import range from "src/shared/utils/arrays/range";
import formatNumberRounded from "src/shared/utils/numbers/formatNumberRounded";
import deepEquals from "src/shared/utils/objects/deepEquals";
import invariant from "tiny-invariant";

function PricingStrategyLoadingSkeleton() {
  return (
    <PageContainer>
      <Skeleton className="h-8 w-36" />
      <Col className="mt-6 justify-between">
        <Skeleton className="h-12 w-72" />
        <Skeleton className="mt-4 h-16 w-full" />
      </Col>
      <Col className="mt-6 justify-between">
        <Skeleton className="mb-4 h-12 w-72" />
        {range(3).map((val) => (
          <Skeleton className="mt-4 h-12 w-full" key={val} />
        ))}
      </Col>
    </PageContainer>
  );
}

type PricingRuleGroupProps = {
  group: PricingRulesGroupType;
  index: number;
  isDragDisabled: boolean;
  isFrozen: boolean;
  loading: boolean;
  pricingStrategyId: string;
};

const PricingRuleGroup = React.memo(function PricingRuleGroup({
  group,
  index,
  isDragDisabled,
  isFrozen,
  loading,
  pricingStrategyId,
}: PricingRuleGroupProps) {
  const impactedSkusCountQuery =
    TrpcClient.internal.getImpactSkusCount.useQuery(
      {
        allSkus: group.all_skus ?? false,
        applyAndConditionForCategory: group.applyAndConditionForCategory,
        applyAndConditionForFilters: group.applyAndConditionForFilters,
        applyAndConditionForPriceZone: group.applyAndConditionForPriceZone,
        applyAndConditionForProductBrandName:
          group.applyAndConditionForProductBrandName,
        applyAndConditionForSubCategory: group.applyAndConditionForSubCategory,
        applyAndConditionForSupplier: group.applyAndConditionForSupplier,
        applyAndConditionForTags: group.applyAndConditionForTags,
        brandId: group.brand_id,
        categories: group.impacted_categories,
        includeEmptyCategory: group.include_empty_category,
        includeEmptyPriceZone: group.include_empty_price_zone,
        includeEmptyProductBrandName: group.include_empty_product_brand_name,
        includeEmptySubCategory: group.include_empty_sub_category,
        includeEmptySupplier: group.include_empty_supplier,
        includeUntaggedSkus: group.include_untagged_skus,
        priceZones: group.impacted_price_zones,
        productBrandNames: group.impacted_product_brand_names,
        skusList: group.impacted_skus,
        subCategories: group.impacted_sub_categories,
        suppliers: group.impacted_suppliers,
        tagIds: group.impacted_tags.map((tag) => tag.id),
      },
      {
        enabled: !loading,
      },
    );
  const currentPriority = index + 1;
  return (
    <SortableItem
      className="mb-4"
      dragDisabled={isDragDisabled}
      id={group.id}
      key={group.id}
    >
      <Card className="flex w-full flex-row justify-between gap-2 border p-2">
        <Row className="gap-4">
          <IconGripVertical className="text-muted" />
          <p className="font-semibold">
            {currentPriority}. {group.name}
          </p>
        </Row>
        <Row className="gap-2">
          <Badge
            className="flex w-[115px] items-center justify-center text-sm"
            variant="muted"
          >
            {impactedSkusCountQuery.isLoading ? (
              <Spinner size={6} />
            ) : (
              <p>{impactedSkusCountQuery.data?.count} SKUs</p>
            )}
          </Badge>
          <PricingRulesGroupIsActiveToggle
            pricingStrategyId={pricingStrategyId}
            rulesGroup={group}
            trigger={
              <Button disabled={isFrozen} variant="invisible">
                <Badge
                  className="inline w-[65px] text-sm"
                  variant={group.is_active ? "default" : "destructive"}
                >
                  {group.is_active ? "Live" : "Inactive"}
                </Badge>
              </Button>
            }
          />
          <PricingRulesGroupDropdownMenu
            pricingRulesGroupId={group.id}
            trigger={
              <Button className="px-3 py-0" variant="ghost">
                <IconDotsVertical size={20} strokeWidth={1.5} />
              </Button>
            }
          />
          <Link
            to={`/company-settings/strategy/rules/${pricingStrategyId}/${group.id}`}
          >
            <Button className="px-3 py-0" variant="ghost">
              <IconEdit size={20} strokeWidth={1.5} />
            </Button>
          </Link>
        </Row>
      </Card>
    </SortableItem>
  );
});

type CombinedSkuCountLabelProps = {
  skuCount?: MaybeNumber;
};

function CombinedSkuCountLabel({ skuCount }: CombinedSkuCountLabelProps) {
  const count = formatNumberRounded(skuCount);
  return <p className="text-sm">{count} SKUs included in strategy</p>;
}

type MarginBoundsSummaryProps = {
  boundType: Maybe<PriceBoundType>;
  lowerBound: Maybe<number>;
  upperBound: Maybe<number>;
};

function MarginBoundsSummary({
  boundType,
  lowerBound,
  upperBound,
}: MarginBoundsSummaryProps) {
  if (lowerBound == null && upperBound != null) {
    return (
      <p className="text-sm">
        Target margin less than{" "}
        <ConstraintBound bound={upperBound ?? null} type={boundType ?? null} />
      </p>
    );
  }

  if (upperBound == null && lowerBound != null) {
    return (
      <p className="text-sm">
        Target margin greater than{" "}
        <ConstraintBound bound={lowerBound ?? null} type={boundType ?? null} />
      </p>
    );
  }

  return (
    <p className="text-sm">
      Target margin from{" "}
      <ConstraintBound bound={lowerBound ?? null} type={boundType ?? null} /> to{" "}
      <ConstraintBound bound={upperBound ?? null} type={boundType ?? null} />
    </p>
  );
}

type InnerProps = {
  strategy: MaybeNull<GetPricingStrategiesQueryResponseType>;
};

function Inner({ strategy }: InnerProps) {
  const isCreate = strategy == null;
  const {
    id: pricingStrategyId = null,
    objective = null,
    rulesGroups: pricingRulesGroups = [],
  } = strategy ?? {};
  const t = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [previousRulesGroups, setPreviousRulesGroups] =
    useState(pricingRulesGroups);
  const [rulesGroups, setRulesGroups] = useState(pricingRulesGroups);
  const strategiesRearranged = !deepEquals(pricingRulesGroups, rulesGroups);
  const [objectiveDialogOpen, setObjectiveDialogOpen] = useState(false);
  const resetObjectiveStoreState = useStrategyObjectiveStore(
    (state) => state.reset,
  );

  useEffect(() => {
    if (!deepEquals(pricingRulesGroups, previousRulesGroups)) {
      setRulesGroups(pricingRulesGroups);
      setPreviousRulesGroups(rulesGroups);
    }
  }, [rulesGroups, pricingRulesGroups, previousRulesGroups]);

  const pricingStrategiesQuery = useGetPricingStrategiesQuery();
  const pricingStrategyByIdQuery = useGetPricingStrategyByIdQuery();
  const updatePricingRulesGroupMutation =
    TrpcClient.internal.updatePricingRulesGroup.useMutation();
  const updatePricingStrategyNameMutation =
    TrpcClient.internal.updatePricingStrategyName.useMutation();
  const updatePricingRulesGroupIsActiveMutation =
    TrpcClient.internal.updatePricingRulesGroupIsActive.useMutation();

  const onSuccess = async (toastMessage: string) => {
    void pricingStrategiesQuery.refetch();
    await pricingStrategyByIdQuery.refetch();
    setIsLoading(false);
    t.successToast(toastMessage);
  };

  const handleSavePricingStrategies = () => {
    const strategiesWithPriority = rulesGroups.map((strategy, index) => {
      return {
        ...strategy,
        priority: index,
      };
    });
    setIsLoading(true);
    updatePricingRulesGroupMutation.mutate(
      {
        pricingRulesGroups: strategiesWithPriority,
      },
      {
        onError: () => {
          setIsLoading(false);
          t.errorToast("Failed to save pricing rules.");
        },
        onSuccess: () => {
          void onSuccess("Pricing rules saved successfully.");
        },
      },
    );
  };

  const handleSavePricingStrategyName = (name: string) => {
    setIsLoading(true);
    invariant(pricingStrategyId != null);
    updatePricingStrategyNameMutation.mutate(
      {
        name,
        pricingStrategyId,
      },
      {
        onError: () => {
          setIsLoading(false);
          t.errorToast("Failed to save pricing strategy name.");
        },
        onSuccess: () => {
          void onSuccess("Pricing strategy name updated successfully.");
        },
      },
    );
  };

  const loading =
    isLoading ||
    updatePricingRulesGroupMutation.isLoading ||
    updatePricingStrategyNameMutation.isLoading ||
    updatePricingRulesGroupIsActiveMutation.isLoading;

  const canAddProductRules = !isCreate;

  return (
    <PageContainer>
      <PricingStrategyModals />
      <PricingRulesGroupModals />
      <Header
        backTo={strategy?.is_frozen ? undefined : "/company-settings/strategy"}
        disabled={loading}
        leftNode={
          <Col className="relative">
            <Row className="gap-4">
              <PageTitle>
                <EditText
                  className="mb-1 mr-1"
                  disableEditing={strategy == null || strategy.is_frozen}
                  disabled={strategy == null || strategy.is_frozen}
                  isSaving={loading}
                  onSave={handleSavePricingStrategyName}
                  placeholder="Edit strategy title"
                  value={strategy?.name ?? "Strategy"}
                />
              </PageTitle>
              {strategy?.is_frozen && (
                <Tooltip
                  content={
                    <p>
                      This strategy is associated with a price plan and cannot
                      be changed.
                    </p>
                  }
                  side="right"
                >
                  <Badge className="font-bold">STRATEGY FROZEN</Badge>
                </Tooltip>
              )}
            </Row>
            <div className="whitespace-nowrap">
              <CombinedSkuCountLabel skuCount={strategy?.skus_count} />
            </div>
          </Col>
        }
        rightNode={
          <Row className="gap-2">
            {pricingStrategyId != null && (
              <StrategyDropdownMenu
                disabled={strategy?.is_frozen}
                pricingStrategyId={pricingStrategyId}
                trigger={
                  <Button disabled={strategy?.is_frozen} variant="basic">
                    Actions <ChevronDownIcon className="ml-2" size={16} />
                  </Button>
                }
              />
            )}
            <Button
              onClick={() =>
                openLinkInNewTab(
                  `${DOCUMENTATION_WEBSITE}/goals-and-settings/new-strategy`,
                )
              }
              variant="basic"
            >
              Questions?
            </Button>
          </Row>
        }
      />
      <p className="mb-2 text-sm font-bold uppercase leading-tight tracking-widest">
        Step 1: Objective
      </p>
      {objective == null ? (
        <div className="my-4 w-[300px]">
          <Button
            onClick={() => {
              resetObjectiveStoreState();
              setObjectiveDialogOpen(true);
            }}
          >
            Define objective
          </Button>
        </div>
      ) : (
        <Card className="relative flex w-full flex-col gap-2 border p-4">
          <Row className="justify-between">
            <p className="font-semibold">
              Objective: Optimize {formatGenericEnumLabel(objective.type)}
            </p>
            <Row className="gap-6">
              <Col>
                {objective.tradeoff != null && (
                  <ObjectiveTradeoffSummary
                    objectiveType={objective.type}
                    tradeoff={objective.tradeoff}
                  />
                )}
                {objective.constraint_type === "MARGIN" && (
                  <MarginBoundsSummary
                    boundType={objective.bound_type}
                    lowerBound={objective.lower_bound}
                    upperBound={objective.upper_bound}
                  />
                )}
              </Col>
              <Edit3Icon
                className={cn(
                  "hover:cursor-pointer",
                  strategy?.is_frozen && "hover:cursor-not-allowed",
                )}
                onClick={() => {
                  if (strategy?.is_frozen) {
                    return;
                  }

                  useStrategyObjectiveStore.setState({
                    ...objective,
                    bound_type: objective.bound_type ?? "PERCENT",
                    constraint_type: objective.constraint_type ?? "MARGIN",
                    lower_bound_input:
                      objective.lower_bound == null
                        ? ""
                        : String(objective.lower_bound),
                    upper_bound_input:
                      objective.upper_bound == null
                        ? ""
                        : String(objective.upper_bound),
                  });
                  setObjectiveDialogOpen(true);
                }}
                opacity={0.85}
                size={16}
                strokeWidth={1.5}
              />
            </Row>
          </Row>
        </Card>
      )}
      <StrategyObjectiveDialog
        onOpenChange={setObjectiveDialogOpen}
        open={objectiveDialogOpen}
      />
      <Row className="my-4 justify-between">
        <p className="mb-2 mt-5 text-sm font-bold uppercase leading-tight tracking-widest">
          Step 2: Rule Groups
        </p>
        {canAddProductRules && (
          <Row className="gap-2">
            <Tooltip
              content={<p>Drag the product rules to re-rank their priority</p>}
              side="left"
            >
              <Button
                disabled={!strategiesRearranged || loading}
                onClick={handleSavePricingStrategies}
                variant="basic"
              >
                Update Priority Order
              </Button>
            </Tooltip>
            <Button
              disabled={strategiesRearranged || loading || strategy?.is_frozen}
              onClick={() => {
                if (canAddProductRules) {
                  navigate(
                    `/company-settings/strategy/rules/create/${pricingStrategyId}`,
                  );
                }
              }}
              variant="basic"
            >
              <IconPlus className="pr-2" /> Add Rule Group
            </Button>
          </Row>
        )}
      </Row>
      <SortableContainer items={rulesGroups} setItems={setRulesGroups}>
        {rulesGroups.map((group, index) => (
          <PricingRuleGroup
            group={group}
            index={index}
            isDragDisabled={
              (len(rulesGroups) === 1 || loading || strategy?.is_frozen) ??
              false
            }
            isFrozen={strategy?.is_frozen ?? false}
            key={group.id}
            loading={loading}
            pricingStrategyId={pricingStrategyId ?? ""}
          />
        ))}
        {!canAddProductRules && (
          <p>Please define an objective before adding rules.</p>
        )}
      </SortableContainer>
    </PageContainer>
  );
}

export default function CreateUpdatePricingStrategy() {
  const { pricingStrategyId } = useParams();
  const pricingStrategyQuery = useGetPricingStrategyByIdQuery();

  if (pricingStrategyId == null) {
    return <Inner strategy={null} />;
  }

  if (pricingStrategyQuery.isLoading) {
    return <PricingStrategyLoadingSkeleton />;
  }

  if (pricingStrategyQuery.isError) {
    return <ErrorPage label="Failed to load pricing strategies." />;
  }

  const strategy = pricingStrategyQuery.data;
  if (strategy == null) {
    return <ErrorPage label="No strategy could be found." />;
  }

  return <Inner strategy={strategy} />;
}
