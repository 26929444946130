import {
  IconChevronRight,
  IconCircleArrowDown,
  IconCircleArrowUp,
} from "@tabler/icons-react";
import { Link } from "react-router-dom";
import Code from "src/frontend/components/Code";
import Row from "src/frontend/components/Row";
import LucaContextMenu, {
  AdminMenuActionItem,
} from "src/frontend/components/admin/LucaContextMenu";
import useToast from "src/frontend/components/ui/useToast";
import { cn } from "src/frontend/components/ui/utils";
import useBrandId from "src/frontend/hooks/useBrandId";
import GroupSummaryLabel from "src/frontend/pages/pricing/GroupSummaryLabel";
import usePricePlanStore from "src/frontend/stores/usePricePlanStore";
import copyTextToClipboard from "src/frontend/utils/copyTextToClipboard";
import formatSignedPercent from "src/frontend/utils/formatSignedPercent";
import { GetPricePlanGroupsQueryResponseType } from "src/shared/trpc/queries/getPricePlanGroupsQuerySchema";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";

type PricePlanGroupCardProps = {
  group: GetPricePlanGroupsQueryResponseType;
  onClick: () => void;
};

export default function PricePlanGroupCard({
  group,
  onClick,
}: PricePlanGroupCardProps) {
  const { successToast } = useToast();
  const pricePlanId = usePricePlanStore((state) => state.pricePlanId);
  const brandId = useBrandId();

  const adminActions: MaybeNull<AdminMenuActionItem>[] = [
    {
      fn: async () => {
        if (pricePlanId != null) {
          await copyTextToClipboard(pricePlanId);
          successToast(
            <span>
              Copied <Code>{pricePlanId}</Code>.
            </span>,
          );
        }
      },
      label: (
        <span>
          Copy <Code>price_plan_id</Code>
        </span>
      ),
    },
    {
      fn: async () => {
        await copyTextToClipboard(group.group_id);
        successToast(
          <span>
            Copied <Code>{group.group_id}</Code>.
          </span>,
        );
      },
      label: (
        <span>
          Copy <Code>group_id</Code>
        </span>
      ),
    },
    {
      fn: async () => {
        if (brandId != null) {
          await copyTextToClipboard(brandId);
          successToast(
            <span>
              Copied <Code>{brandId}</Code>.
            </span>,
          );
        }
      },
      label: (
        <span>
          Copy <Code>brand_id</Code>
        </span>
      ),
    },
  ];

  const devActions: MaybeNull<AdminMenuActionItem>[] = [];

  return (
    <Link
      className="contents border-solid [&>*:first-child]:rounded-l-2xl [&>*:first-child]:border-l [&>*:first-child]:pl-4 [&>*:last-child]:rounded-r-2xl [&>*:last-child]:border-r [&>*:last-child]:px-6 [&>*]:flex [&>*]:cursor-pointer [&>*]:items-center [&>*]:justify-center [&>*]:border-b [&>*]:border-t [&>*]:px-8 [&>*]:py-6 [&>*]:hover:bg-n-50"
      onClick={onClick}
      state={{ opportunity: group }}
      to={group.group_id}
    >
      <LucaContextMenu adminActions={adminActions} devActions={devActions}>
        <p className="">{group.title}</p>
      </LucaContextMenu>

      {group.price_change_percent != null ? (
        <Row className="gap-1">
          <span
            className={cn(
              group.price_change_percent >= 0
                ? "text-blue-500"
                : "text-fuchsia-600",
            )}
          >
            {group.price_change_percent >= 0 ? (
              <IconCircleArrowUp size={16} />
            ) : (
              <IconCircleArrowDown size={16} />
            )}
          </span>
          <p className="text-sm font-bold">
            {formatSignedPercent(group.price_change_percent)}
          </p>
        </Row>
      ) : (
        <p className="text-sm">--</p>
      )}

      <GroupSummaryLabel group={group} />

      <p>{group.impacted_skus}</p>

      <p className="text-n-400">
        <IconChevronRight size={24} />
      </p>
    </Link>
  );
}
