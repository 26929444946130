/* eslint-disable sort-keys-fix/sort-keys-fix */
import {
  CompetitorIntelligenceKeys,
  PricePlanKeys,
  ProductCatalogKeys,
  VisibilityFilterKeyUnion,
} from "src/shared/trpc/common/UserCommonVisibilityFilters";

export type ColumnConfig<T extends string[]> = Record<
  string,
  (T[number] | { filter: T[number]; label: string })[]
>;

type SimpleConfig<T extends string[]> = Record<string, T>;

function simplifyConfig<T extends string[]>(
  complexMap: ColumnConfig<T>,
): SimpleConfig<T> {
  return Object.entries(complexMap).reduce<SimpleConfig<T>>(
    (acc, [key, value]) => {
      acc[key] = value.map((val) => {
        if (typeof val === "string") {
          return val;
        } else {
          return val.filter;
        }
      }) as T;
      return acc;
    },
    {},
  );
}

export type VisibilityConfigType = ColumnConfig<VisibilityFilterKeyUnion[]>;

export const ProductCatalogConfigMap: ColumnConfig<ProductCatalogKeys> = {
  "My Brand": [{ filter: "product_sku", label: "Product Id" }, "price_zone"],
  "Product definition": [
    "product_brand_name",
    "category",
    "subcategory",
    "supplier",
    "tags",
  ],
  "Current pricing information": [
    { filter: "list_price", label: "Price" },
    "discounted_price",
    "member_price",
    "subscriber_price",
    "inventory",
    { filter: "list_price_margin", label: "Margin" },
    { filter: "list_price_contribution_margin", label: "Contribution Margin" },
    "discounted_price_margin",
    "discounted_price_contribution_margin",
    "member_price_margin",
    // TODO-[MIKE]: contribution margin - catalog
    "member_price_contribution_margin",
    "subscriber_price_margin",
    "subscriber_price_contribution_margin",
    { filter: "cost", label: "Cost Per Unit" },
  ],
  "Last 30 days": [
    { filter: "last_30_final_price", label: "Avg Final Price Paid" },
    { filter: "last_30_units_sold", label: "Units Sold" },
    { filter: "last_30_revenue", label: "Revenue" },
    { filter: "last_30_profit", label: "Profit" },
  ],
  Misc: ["last_price_plan"],
};

export const ProductCatalogConfigMapSimple: SimpleConfig<ProductCatalogKeys> =
  simplifyConfig(ProductCatalogConfigMap);

export const PricePlanConfigMap: ColumnConfig<PricePlanKeys> = {
  "My Brand": [
    { filter: "product_sku", label: "Product Id" },
    { filter: "experiment_status", label: "Status" },
    "price_zone",
  ],
  "Product Definition": [
    { filter: "product_brand_name", label: "Brand" },
    "category",
    "subcategory",
    "product_type",
    "kvi",
    "supplier",
    "producer",
    "weight",
    "weight_label",
    "tags",
  ],
  Strategy: [
    { filter: "constraints", label: "Rules Met" },
    "price_range",
    { filter: "member_constraints", label: "Member Rules Met" },
    "member_price_range",
    "elasticity",
    "member_elasticity",
  ],
  "Pricing information": [
    { filter: "new_list_price", label: "New Price" },
    { filter: "previous_list_price", label: "Previous Price" },
    { filter: "list_price_change_percent", label: "Price Percent Change" },
    "new_discounted_price",
    "previous_discounted_price",
    {
      filter: "discounted_price_change_percent",
      label: "Discounted Price Percent Change",
    },
    { filter: "new_discounted_percent", label: "New Discount" },
    { filter: "previous_discounted_percent", label: "Previous Discount" },
    "new_member_price",
    "previous_member_price",
    {
      filter: "member_price_change_percent",
      label: "Member Price Percent Change",
    },
    { filter: "new_member_discounted_percent", label: "New Member Discount" },
    {
      filter: "previous_member_discounted_percent",
      label: "Previous Member Discount",
    },
    "new_subscriber_price",
    "previous_subscriber_price",
    "subscriber_price_change_percent",
    {
      filter: "new_subscriber_discounted_percent",
      label: "New Subscriber Discount",
    },
    {
      filter: "previous_subscriber_discounted_percent",
      label: "Previous Subscriber Discount",
    },
    "inventory",
  ],
  "Margin information": [
    { filter: "new_list_price_margin", label: "New Margin" },
    {
      filter: "new_list_price_contribution_margin",
      label: "New Contribution Margin",
    },
    { filter: "previous_list_price_margin", label: "Previous Margin" },
    {
      filter: "previous_list_price_contribution_margin",
      label: "Previous Contribution Margin",
    },
    "new_discounted_price_margin",
    "new_discounted_price_contribution_margin",
    "previous_discounted_price_margin",
    "previous_discounted_price_contribution_margin",
    // TODO-[MIKE]: contribution margin - price plan
    "new_member_price_margin",
    "new_member_price_contribution_margin",
    "previous_member_price_margin",
    "previous_member_price_contribution_margin",
    "new_subscriber_price_margin",
    "new_subscriber_price_contribution_margin",
    "previous_subscriber_price_margin",
    "previous_subscriber_price_contribution_margin",
    { filter: "cost", label: "Cost Per Unit" },
  ],
  "Recent Sales": [
    { filter: "revenue_30d", label: "30D Revenue" },
    { filter: "units_sold_30d", label: "30D Units Sold" },
    { filter: "profit_30d", label: "30D Profit" },
    { filter: "revenue_rank_30d", label: "30D Revenue Rank" },
    { filter: "units_sold_rank_30d", label: "30D Units Sold Rank" },
    { filter: "profit_rank_30d", label: "30D Profit Rank" },
    { filter: "revenue_60d", label: "60D Revenue" },
    { filter: "units_sold_60d", label: "60D Units Sold" },
    { filter: "profit_60d", label: "60D Profit" },
    { filter: "revenue_rank_60d", label: "60D Revenue Rank" },
    { filter: "units_sold_rank_60d", label: "60D Units Sold Rank" },
    { filter: "profit_rank_60d", label: "60D Profit Rank" },
    { filter: "revenue_90d", label: "90D Revenue" },
    { filter: "units_sold_90d", label: "90D Units Sold" },
    { filter: "profit_90d", label: "90D Profit" },
    { filter: "revenue_rank_90d", label: "90D Revenue Rank" },
    { filter: "units_sold_rank_90d", label: "90D Units Sold Rank" },
    { filter: "profit_rank_90d", label: "90D Profit Rank" },
    { filter: "revenue_120d", label: "120D Revenue" },
    { filter: "units_sold_120d", label: "120D Units Sold" },
    { filter: "profit_120d", label: "120D Profit" },
    { filter: "revenue_rank_120d", label: "120D Revenue Rank" },
    { filter: "units_sold_rank_120d", label: "120D Units Sold Rank" },
    { filter: "profit_rank_120d", label: "120D Profit Rank" },
  ],
  "Estimated Impact": ["units_sold_impact", "revenue_impact", "profit_impact"],
  "30 day forecast": [
    { filter: "units_sold_next_thirty", label: "Units Sold" },
    { filter: "revenue_next_thirty", label: "Revenue" },
    { filter: "profit_next_thirty", label: "Total Profit" },
  ],
};

export const PricePlanConfigMapSimple: SimpleConfig<PricePlanKeys> =
  simplifyConfig(PricePlanConfigMap);

export const CompetitorIntelligenceConfigMap: ColumnConfig<CompetitorIntelligenceKeys> =
  {
    "My Brand": [{ filter: "product_sku", label: "Product Id" }, "price_zone"],
    "Product definition": [
      "product_brand_name",
      "category",
      "subcategory",
      "supplier",
      "tags",
    ],
    "Current pricing information": [
      { filter: "list_price", label: "Price" },
      "discounted_price",
      "member_price",
      "subscriber_price",
      "inventory",
      { filter: "list_price_margin", label: "Margin" },
      {
        filter: "list_price_contribution_margin",
        label: "Contribution Margin",
      },
      { filter: "discounted_price_margin", label: "Discounted Price Margin" },
      {
        filter: "discounted_price_contribution_margin",
        label: "Discounted Price Contribution Margin",
      },
      { filter: "member_price_margin", label: "Member Price Margin" },
      // TODO-[MIKE]: contribution margin - competitor intelligence
      {
        filter: "member_price_contribution_margin",
        label: "Member Price Contribution Margin",
      },
      { filter: "subscriber_price_margin", label: "Subscriber Price Margin" },
      {
        filter: "subscriber_price_contribution_margin",
        label: "Subscriber Price Contribution Margin",
      },
      { filter: "cost", label: "Cost Per Unit" },
      { filter: "final_price_per_unit", label: "Final Price Per Unit" },
    ],
  };

export const CompetitorIntelligenceConfigMapSimple: SimpleConfig<CompetitorIntelligenceKeys> =
  simplifyConfig(CompetitorIntelligenceConfigMap);

export type UserColumnVisibilityDropdownConfig =
  | typeof CompetitorIntelligenceConfigMap
  | typeof PricePlanConfigMap
  | typeof ProductCatalogConfigMap;

const USER_COLUMN_VISIBILITY_CONFIG = {
  ProductCatalogConfigMap,
  PricePlanConfigMap,
  CompetitorIntelligenceConfigMap,
};

export default USER_COLUMN_VISIBILITY_CONFIG;
