import TrpcClient from "src/frontend/api/TrpcClient";
import useIsBrandInitialized from "src/frontend/hooks/useIsBrandInitialized";

export default function useGetPriceParityGroupsQuery({
  page,
  pageSize,
  search,
}: {
  page: number;
  pageSize: number;
  search?: string;
}) {
  const { brandId, enabled } = useIsBrandInitialized();
  return TrpcClient.internal.getPriceParityGroups.useQuery(
    {
      brandId,
      page,
      pageSize,
      search,
    },
    {
      enabled,
    },
  );
}
