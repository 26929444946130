import { pricing_constraint, Prisma } from "@prisma/client";
import { CompetitorPriceHistoryQueryResult } from "src/backend/prisma-utils/getCompetitorPriceHistoryForBrand";
import logServerError from "src/backend/sentry/logServerError";
import PrismaType from "src/backend/types/PrismaType";
import {
  MinOrMax,
  PriceEngineContext,
  ProductContext,
} from "src/backend/utils/price-rules-engine/calculateExperimentPriceRanges";
import postProcessConstraint from "src/backend/utils/price-rules-engine/postProcessConstraint";
import toPrismaDecimal from "src/backend/utils/toPrismaDecimal";
import { MatchDetails } from "src/shared/trpc/common/CompetitorPricingObject";
import { ExperimentPriceBoundsType } from "src/shared/trpc/common/ExperimentPriceBounds";
import { PricingConstraintType } from "src/shared/trpc/common/PricingConstraint";
import { CompetitorPriceLookbackPeriodType } from "src/shared/trpc/common/enum/CompetitorPriceLookbackPeriod";
import { PriceBoundType } from "src/shared/trpc/common/enum/PriceBoundType";
import { PriceComparisonTargetType } from "src/shared/trpc/common/enum/PriceComparisonTargetType";
import { PriceTargetType } from "src/shared/trpc/common/enum/PriceTargetType";
import Decimal from "src/shared/types/Decimal";
import MaybeDecimal from "src/shared/types/maybe/MaybeDecimal";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import MaybeNumber from "src/shared/types/maybe/MaybeNumber";
import MaybeString from "src/shared/types/maybe/MaybeString";
import arrayEmpty from "src/shared/utils/arrays/arrayEmpty";
import filterEmptyValues from "src/shared/utils/arrays/filterEmptyValues";
import sortNumerically from "src/shared/utils/arrays/sortNumerically";
import { assertUnreachable } from "src/shared/utils/assertUnreachable";
import isLaikaBrand from "src/shared/utils/brands/isLaikaBrand";
import avg from "src/shared/utils/math/avg";
import calculateUOMMatchPrice, {
  ProductUOMType,
} from "src/shared/utils/numbers/calculateUOMMatchPrice";
import isNumber from "src/shared/utils/numbers/isNumber";
import { getPrimaryMatchType } from "src/shared/utils/primaryMatchType";
import SentryErrorEvent from "src/shared/utils/sentryErrorUtils";
import jsonStringify from "src/shared/utils/strings/jsonStringify";
import invariant from "tiny-invariant";

const ONE = toPrismaDecimal(1);
const ONE_HUNDRED = toPrismaDecimal(100);
const DEFAULT_LOWER_BOUND = toPrismaDecimal(0);
const DEFAULT_UPPER_BOUND = toPrismaDecimal(Infinity);

function calculatePriceFromCostAndMarginAmount(
  product_context: ProductContext,
  cost: Decimal,
  margin: Decimal,
): Decimal {
  if (isLaikaBrand(product_context.brand_id)) {
    const left = cost.plus(margin);
    const right = ONE_HUNDRED.plus(product_context.vat).dividedBy(ONE_HUNDRED);
    return left.times(right);
  } else {
    return cost.plus(margin);
  }
}

function calculatePriceFromCostAndMarginPercent(
  product_context: ProductContext,
  cost: Decimal,
  margin: Decimal,
): Decimal {
  if (isLaikaBrand(product_context.brand_id)) {
    const numerator = cost.times(ONE_HUNDRED.plus(product_context.vat));
    const denominator = ONE_HUNDRED.minus(
      margin.times(product_context.vat).dividedBy(ONE_HUNDRED),
    );
    return numerator.dividedBy(denominator);
  } else {
    const denominator = ONE.minus(margin.dividedBy(100));
    return cost.dividedBy(denominator);
  }
}

function calculateBoundValue(
  value: Decimal,
  bound_type: PriceBoundType,
  price: Decimal,
): Decimal {
  switch (bound_type) {
    case "AMOUNT":
      return price.plus(value);
    case "PERCENT":
      const amount = value.dividedBy(100).times(price);
      return price.plus(amount);
    default:
      return assertUnreachable(bound_type);
  }
}

function calculateMarginBoundValue(
  product_context: ProductContext,
  cost: Decimal,
  value: Decimal,
  bound_type: PriceBoundType,
): Decimal {
  switch (bound_type) {
    case "AMOUNT":
      return calculatePriceFromCostAndMarginAmount(
        product_context,
        cost,
        value,
      );
    case "PERCENT":
      return calculatePriceFromCostAndMarginPercent(
        product_context,
        cost,
        value,
      );
    default:
      return assertUnreachable(bound_type);
  }
}

function getReferencePrice(
  product_context: ProductContext,
  price_target_type: PriceTargetType,
): MaybeNull<Decimal> {
  let price: MaybeNull<Decimal> = null;
  switch (price_target_type) {
    case "LIST_PRICE":
      price = product_context.initial_list_price;
      break;
    case "MEMBER_PRICE":
      price = product_context.initial_member_price;
      break;
    case "SUBSCRIBER_PRICE":
      price = product_context.initial_subscriber_price;
      break;
    case "DISCOUNTED_PRICE":
      price = product_context.initial_discounted_price;
      break;
    case "END_CUSTOMER_PRICE":
      invariant(false, "End Customer Price not supported");
    default:
      assertUnreachable(price_target_type);
  }

  return price;
}

type PreLucaProductPricesType = {
  pre_luca_discounted_price: MaybeNumber;
  pre_luca_list_price: MaybeNumber;
  pre_luca_member_price: MaybeNumber;
  pre_luca_subscriber_price: MaybeNumber;
};

export function getPreLucaReferencePrice(
  pre_luca_product_prices: PreLucaProductPricesType,
  price_target_type: PriceTargetType,
): MaybeNumber {
  let price: MaybeNumber = null;
  switch (price_target_type) {
    case "LIST_PRICE":
      price = pre_luca_product_prices.pre_luca_list_price;
      break;
    case "MEMBER_PRICE":
      price = pre_luca_product_prices.pre_luca_member_price;
      break;
    case "SUBSCRIBER_PRICE":
      price = pre_luca_product_prices.pre_luca_subscriber_price;
      break;
    case "DISCOUNTED_PRICE":
      price = pre_luca_product_prices.pre_luca_discounted_price;
    case "END_CUSTOMER_PRICE":
      invariant(false, "End Customer Price not supported");
    default:
      assertUnreachable(price_target_type);
  }

  invariant(price != null, "Pre-luca reference price cannot be null.");
  return price;
}

export function getPriceComparisonReferencePrice({
  experiment,
  pre_luca_product_prices,
  price_comparison_target_type,
  price_target_type,
}: {
  experiment?: {
    treatment_discounted_price: MaybeNumber;
    treatment_list_price: MaybeNumber;
    treatment_member_price: MaybeNumber;
    treatment_subscriber_price: MaybeNumber;
  };
  pre_luca_product_prices: {
    pre_luca_discounted_price: MaybeNumber;
    pre_luca_list_price: MaybeNumber;
    pre_luca_member_price: MaybeNumber;
    pre_luca_subscriber_price: MaybeNumber;
  };
  price_comparison_target_type: PriceComparisonTargetType;
  price_target_type: PriceTargetType;
}): MaybeNumber {
  let price: MaybeNumber = null;
  switch (price_comparison_target_type) {
    case "PRE_LUCA_PRICE":
      price = getPreLucaReferencePrice(
        pre_luca_product_prices,
        price_target_type,
      );
      break;
    case "LIST_PRICE":
      price = experiment?.treatment_list_price ?? null;
      break;
    case "MEMBER_PRICE":
      price = experiment?.treatment_member_price ?? null;
      break;
    case "SUBSCRIBER_PRICE":
      price = experiment?.treatment_subscriber_price ?? null;
      break;
    case "DISCOUNTED_PRICE":
      price = experiment?.treatment_discounted_price ?? null;
      break;
    case "END_CUSTOMER_PRICE":
      invariant(false, "End Customer Price not supported");
    default:
      assertUnreachable(price_comparison_target_type);
  }

  return price;
}
type CompetitorAveragePriceKeysType = {
  avg_last_14_days_discounted_price: MaybeNumber;
  avg_last_14_days_list_price: MaybeNumber;
  avg_last_14_days_member_price: MaybeNumber;
  avg_last_14_days_subscriber_price: MaybeNumber;
  avg_last_1_day_discounted_price: MaybeNumber;
  avg_last_1_day_list_price: MaybeNumber;
  avg_last_1_day_member_price: MaybeNumber;
  avg_last_1_day_subscriber_price: MaybeNumber;
  avg_last_30_days_discounted_price: MaybeNumber;
  avg_last_30_days_list_price: MaybeNumber;
  avg_last_30_days_member_price: MaybeNumber;
  avg_last_30_days_subscriber_price: MaybeNumber;
  avg_last_7_days_discounted_price: MaybeNumber;
  avg_last_7_days_list_price: MaybeNumber;
  avg_last_7_days_member_price: MaybeNumber;
  avg_last_7_days_subscriber_price: MaybeNumber;
};

export function getCompetitorReferencePrice(
  competitor_intelligence: CompetitorIntelligenceForReferencePriceCalculation,
  price_target_type: PriceTargetType,
  competitor_price_lookback_period: CompetitorPriceLookbackPeriodType,
): MaybeNumber {
  const lookback = competitor_price_lookback_period.toLowerCase();
  const price_target = price_target_type.toLowerCase();
  const key =
    `avg_${lookback}_${price_target}` as keyof CompetitorAveragePriceKeysType;
  invariant(
    key in competitor_intelligence,
    `competitor_intelligence key '${key}' is invalid for object: ${jsonStringify(
      competitor_intelligence,
    )}`,
  );
  const result = competitor_intelligence[key];
  if (result == null) {
    return result;
  }

  invariant(isNumber(result) && typeof result === "number");
  return result;
}

function getPriceBoundsKey(
  price_target_type: PriceTargetType,
  min_or_max: MinOrMax,
): keyof ExperimentPriceBoundsType {
  const key_prefix = price_target_type.toLowerCase();
  const key = `${key_prefix}_${min_or_max}`;
  return key as keyof ExperimentPriceBoundsType;
}

function applyLowerBoundConstraint(
  bounds: ExperimentPriceBoundsType,
  prior_lower_bound: Decimal,
  prior_upper_bound: Decimal,
  current_lower_bound: Decimal,
  price_target_type: PriceTargetType,
  constraint: pricing_constraint,
): void {
  let lower_bound: MaybeDecimal = null;
  if (
    current_lower_bound.greaterThanOrEqualTo(prior_lower_bound) &&
    current_lower_bound.lessThanOrEqualTo(prior_upper_bound)
  ) {
    lower_bound = current_lower_bound;
  } else {
    lower_bound = prior_lower_bound;
  }

  const key = getPriceBoundsKey(price_target_type, "min");
  const final_value = Prisma.Decimal.max(0, lower_bound);
  const calculated_final_value = Prisma.Decimal.max(0, current_lower_bound);

  bounds[key] = final_value.toNumber();
  constraint.lower_bound_value = final_value.toNumber();
  constraint.lower_bound_calculated = calculated_final_value.toNumber();
}

function applyUpperBoundConstraint(
  bounds: ExperimentPriceBoundsType,
  prior_lower_bound: Decimal,
  prior_upper_bound: Decimal,
  current_upper_bound: Decimal,
  price_target_type: PriceTargetType,
  constraint: pricing_constraint,
): void {
  let upper_bound: MaybeDecimal = null;
  if (
    current_upper_bound.lessThanOrEqualTo(prior_upper_bound) &&
    current_upper_bound.greaterThanOrEqualTo(prior_lower_bound)
  ) {
    upper_bound = current_upper_bound;
  } else {
    upper_bound = prior_upper_bound;
  }

  const key = getPriceBoundsKey(price_target_type, "max");
  const final_value = Prisma.Decimal.max(0, upper_bound);
  const calculated_final_value = Prisma.Decimal.max(0, current_upper_bound);

  bounds[key] = final_value.toNumber();
  constraint.upper_bound_value = final_value.toNumber();
  constraint.upper_bound_calculated = calculated_final_value.toNumber();
}

type CompetitorIntelligenceForReferencePriceCalculation =
  CompetitorAveragePriceKeysType & {
    competitor_name: MaybeString;
    match_details: PrismaType.JsonValue;
    match_level: MaybeNumber;
    quantity: MaybeNumber;
    secondary_quantity: MaybeNumber;
    secondary_unit_of_measurement: MaybeString;
    unit_of_measurement: MaybeString;
  };

export function getCompetitorReferencePriceValue(
  competitor_intelligence: CompetitorIntelligenceForReferencePriceCalculation[],
  constraint: PricingConstraintType,
  product: ProductUOMType,
): MaybeDecimal {
  const competitor_prices = competitor_intelligence
    .filter((competitor) => {
      if (competitor.competitor_name == null) {
        return false;
      }

      const isDesiredCompetitor = constraint.competitors.includes(
        competitor.competitor_name,
      );

      if (!isDesiredCompetitor) {
        return false;
      }

      const match_details = MatchDetails.parse(competitor.match_details);
      const matchLevelFilters =
        constraint.pricing_constraint_competitor_match_types;

      for (const matchLevelFilter of matchLevelFilters) {
        const matchLevelFilterWithoutNulls = Object.fromEntries(
          Object.entries(matchLevelFilter).map(([key, value]) => [
            key,
            value ?? true,
          ]),
        );

        const filterType = getPrimaryMatchType(matchLevelFilterWithoutNulls);
        const matchType = getPrimaryMatchType(
          match_details,
          competitor.match_level,
        );

        if (filterType === matchType) {
          return true;
        }
      }

      return false;
    })
    .map((competitor) => {
      invariant(
        constraint.competitor_price_target_type != null,
        "competitor_price_target_type must not be null.",
      );
      invariant(
        constraint.competitor_price_lookback_period != null,
        "lookback period must not be null",
      );
      const reference_price = getCompetitorReferencePrice(
        competitor,
        constraint.competitor_price_target_type,
        constraint.competitor_price_lookback_period,
      );
      const match_details = MatchDetails.parse(competitor.match_details);
      const matchType = getPrimaryMatchType(
        match_details,
        competitor.match_level,
      );

      if (
        matchType === "DIFFERENT_SIZE" ||
        matchType === "DIFFERENT_FLAVOR_AND_SIZE"
      ) {
        return calculateUOMMatchPrice({
          competitor,
          product,
          reference_price,
        });
      }
      return reference_price;
    });
  const sorted_prices = filterEmptyValues(sortNumerically(competitor_prices));
  if (arrayEmpty(sorted_prices)) {
    constraint.competitor_price_calculation_error =
      "No competitor prices found for this SKU.";
    constraint.constraint_met = false;
    return null;
  }

  invariant(
    constraint.competitor_price_comparison_type != null,
    "competitor_price_comparison_type must not be null.",
  );

  sorted_prices.forEach(
    (price) => invariant(isNumber(price)),
    "competitor prices must be valid numbers.",
  );

  const lowest_price = Math.min(...sorted_prices);
  const highest_price = Math.max(...sorted_prices);
  const average_price = avg(sorted_prices);

  switch (constraint.competitor_price_comparison_type) {
    case "AVERAGE":
      return toPrismaDecimal(average_price);
    case "LOWEST_OF":
      invariant(isNumber(lowest_price));
      return toPrismaDecimal(lowest_price);
    case "HIGHEST_OF":
      invariant(isNumber(highest_price));
      return toPrismaDecimal(highest_price);
    default:
      return assertUnreachable(constraint.competitor_price_comparison_type);
  }
}

export default function applyConstraint(
  ctx: PriceEngineContext,
  previous_constraint: MaybeNull<pricing_constraint>,
  constraint: PricingConstraintType,
  competitor_intelligence: CompetitorPriceHistoryQueryResult[],
): void {
  const { bound_type, lower_bound, price_target_type, type, upper_bound } =
    constraint;

  let lower_bound_value: Decimal = Prisma.Decimal.max(
    DEFAULT_LOWER_BOUND,
    lower_bound == null
      ? DEFAULT_LOWER_BOUND
      : previous_constraint?.lower_bound_value ?? DEFAULT_LOWER_BOUND,
  );
  let upper_bound_value: Decimal = Prisma.Decimal.min(
    DEFAULT_UPPER_BOUND,
    upper_bound == null
      ? DEFAULT_UPPER_BOUND
      : previous_constraint?.upper_bound_value ?? DEFAULT_UPPER_BOUND,
  );

  const prior_lower_bound_value =
    previous_constraint?.lower_bound_value != null
      ? toPrismaDecimal(previous_constraint?.lower_bound_value)
      : DEFAULT_LOWER_BOUND;
  const prior_upper_bound_value =
    previous_constraint?.upper_bound_value != null
      ? toPrismaDecimal(previous_constraint?.upper_bound_value)
      : DEFAULT_UPPER_BOUND;

  const reference_price = getReferencePrice(
    ctx.product_context,
    price_target_type,
  );

  if (reference_price != null) {
    constraint.reference_price_calculated = reference_price.toNumber();
    switch (type) {
      case "AVERAGE_MARGIN":
      case "ROUNDING":
        throw new Error(
          `${type} is unsupported for price constraint min/max bounds calculations.`,
        );
      case "PRICE_COMPARISON":
        invariant(
          constraint.comparison_price_target != null,
          "comparison_price_target cannot be null.",
        );
        const price_comparison_reference_price =
          getPriceComparisonReferencePrice({
            experiment: ctx.experiment,
            pre_luca_product_prices: {
              pre_luca_discounted_price:
                ctx.product_context.pre_luca_discounted_price?.toNumber() ??
                null,
              pre_luca_list_price:
                ctx.product_context.pre_luca_list_price?.toNumber() ?? null,
              pre_luca_member_price:
                ctx.product_context.pre_luca_member_price?.toNumber() ?? null,
              pre_luca_subscriber_price:
                ctx.product_context.pre_luca_subscriber_price?.toNumber() ??
                null,
            },
            price_comparison_target_type: constraint.comparison_price_target,
            price_target_type: constraint.price_target_type,
          });

        if (price_comparison_reference_price == null) {
          constraint.reference_price_calculated = null;
          constraint.comparison_price_calculation_error =
            "Price comparison reference price could not be calculated.";
          break;
        }

        constraint.comparison_price_calculation_error = null;

        constraint.reference_price_calculated =
          price_comparison_reference_price;

        if (lower_bound != null) {
          invariant(bound_type != null, "bound_type must not be null.");
          lower_bound_value = calculateBoundValue(
            toPrismaDecimal(lower_bound),
            bound_type,
            toPrismaDecimal(price_comparison_reference_price),
          );
        }

        if (upper_bound != null) {
          invariant(bound_type != null, "bound_type must not be null.");
          upper_bound_value = calculateBoundValue(
            toPrismaDecimal(upper_bound),
            bound_type,
            toPrismaDecimal(price_comparison_reference_price),
          );
        }

        break;
      case "PRICE_CHANGE": {
        if (lower_bound != null) {
          invariant(bound_type != null, "bound_type must not be null.");
          lower_bound_value = calculateBoundValue(
            toPrismaDecimal(lower_bound),
            bound_type,
            reference_price,
          );
        }

        if (upper_bound != null) {
          invariant(bound_type != null, "bound_type must not be null.");
          upper_bound_value = calculateBoundValue(
            toPrismaDecimal(upper_bound),
            bound_type,
            reference_price,
          );
        }

        break;
      }
      case "MARGIN": {
        const cost = ctx.product_context.cost ?? toPrismaDecimal(0.01);
        if (cost == null) {
          throw new Error("Missing cost data for product");
        }

        if (lower_bound != null) {
          invariant(bound_type != null, "bound_type must not be null.");
          lower_bound_value = calculateMarginBoundValue(
            ctx.product_context,
            cost,
            toPrismaDecimal(lower_bound),
            bound_type,
          );
        }

        if (upper_bound != null) {
          invariant(bound_type != null, "bound_type must not be null.");
          upper_bound_value = calculateMarginBoundValue(
            ctx.product_context,
            cost,
            toPrismaDecimal(upper_bound),
            bound_type,
          );
        }
        break;
      }
      case "COMPETITOR_PRICE": {
        let reference_price: MaybeDecimal = null;
        try {
          reference_price = getCompetitorReferencePriceValue(
            competitor_intelligence,
            constraint,
            ctx.product_context,
          );
        } catch (err: any) {
          const msg = err.message ?? "Failed to calculate competitor price.";
          constraint.competitor_price_calculation_error = msg;
          constraint.constraint_met = false;
          logServerError(
            new Error(msg),
            SentryErrorEvent.PriceRulesEngineError,
            {
              extra: {
                competitor_intelligence,
                constraint,
                ctx,
                previous_constraint,
              },
            },
          );
        }

        if (reference_price != null) {
          constraint.competitor_price_calculated = reference_price.toNumber();
        } else {
          constraint.competitor_price_calculated = null;
          constraint.constraint_met = false;

          constraint.competitor_price_calculation_error = (
            (constraint.competitor_price_calculation_error ?? "") +
            " Calculated reference_price is null."
          ).trim();
        }

        if (lower_bound != null && reference_price != null) {
          invariant(bound_type != null, "bound_type must not be null.");
          lower_bound_value = calculateBoundValue(
            toPrismaDecimal(lower_bound),
            bound_type,
            reference_price,
          );
        }

        if (upper_bound != null && reference_price != null) {
          invariant(bound_type != null, "bound_type must not be null.");
          upper_bound_value = calculateBoundValue(
            toPrismaDecimal(upper_bound),
            bound_type,
            reference_price,
          );
        }

        break;
      }
      default:
        assertUnreachable(type);
    }
  }

  applyLowerBoundConstraint(
    ctx.price_bounds,
    prior_lower_bound_value,
    prior_upper_bound_value,
    lower_bound_value,
    price_target_type,
    constraint,
  );

  applyUpperBoundConstraint(
    ctx.price_bounds,
    prior_lower_bound_value,
    prior_upper_bound_value,
    upper_bound_value,
    price_target_type,
    constraint,
  );

  if (lower_bound_value?.isNaN()) {
    const msg = "Lower bound result was not a valid number.";
    logServerError(new Error(msg), SentryErrorEvent.PriceRulesEngineError, {
      extra: {
        competitor_intelligence,
        constraint,
        ctx,
        previous_constraint,
      },
    });
    constraint.lower_bound_calculated = null;
    constraint.lower_bound_calculation_error = msg;
  }

  if (upper_bound_value?.isNaN()) {
    const msg = "Upper bound result was not a valid number.";
    logServerError(new Error(msg), SentryErrorEvent.PriceRulesEngineError, {
      extra: {
        competitor_intelligence,
        constraint,
        ctx,
        previous_constraint,
      },
    });
    constraint.upper_bound_calculated = null;
    constraint.upper_bound_calculation_error = msg;
  }

  postProcessConstraint(constraint);
  const min_key = getPriceBoundsKey(price_target_type, "min");
  const max_key = getPriceBoundsKey(price_target_type, "max");
  ctx.price_bounds[min_key] = constraint.lower_bound_value;
  ctx.price_bounds[max_key] = constraint.upper_bound_value;
}
