import { experiment_status } from "@prisma/client";
import {
  GetExperimentsForecastOutput,
  GetExperimentsForecastOutputInfinite,
} from "src/frontend/pages/pricing/details/useUpdateExperiment";

type OptimisticActionType = (
  experiment: GetExperimentsForecastOutput["items"][number],
) => { experiment_status: experiment_status };

const updateClosure =
  (updater: OptimisticActionType) =>
  (input: { experimentIds: string[] }) =>
  (
    prev: GetExperimentsForecastOutputInfinite,
  ): GetExperimentsForecastOutputInfinite => {
    const experimentIds = new Set(input.experimentIds);
    if (prev == null) {
      return prev;
    }

    return {
      ...prev,
      pages: prev.pages.map((page) => {
        return {
          ...page,
          items: page.items.map((experiment) => {
            const isSelected = experimentIds.has(experiment.experiment_id);

            if (!isSelected) {
              return experiment;
            }

            return { ...experiment, ...updater(experiment) };
          }),
        };
      }),
    };
  };

function approve(experiment: { experiment_status: experiment_status }): {
  experiment_status: experiment_status;
} {
  const canApprove =
    experiment.experiment_status === experiment_status.PENDING ||
    experiment.experiment_status === experiment_status.REJECTED;

  if (canApprove) {
    return {
      experiment_status: experiment_status.APPROVED,
    };
  }
  return experiment;
}
export const optimisticApprove = updateClosure(approve);

function reject(experiment: { experiment_status: experiment_status }): {
  experiment_status: experiment_status;
} {
  const canReject =
    experiment.experiment_status === experiment_status.PENDING ||
    experiment.experiment_status === experiment_status.APPROVED;

  if (canReject) {
    return {
      experiment_status: experiment_status.REJECTED,
    };
  }
  return experiment;
}
export const optimisticReject = updateClosure(reject);

function revert(experiment: {
  experiment_status: experiment_status;
  revert_successful: boolean | null;
}): { experiment_status: experiment_status } {
  const notReverted =
    experiment.revert_successful === null ||
    experiment.revert_successful === false;
  const canRevert =
    experiment.experiment_status === experiment_status.APPROVED && notReverted;

  if (canRevert) {
    return {
      experiment_status: experiment_status.REVERTED,
    };
  }
  return experiment;
}
export const optimisticRevert = updateClosure(revert);
