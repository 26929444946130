import usePricePlansByStatusQuery from "src/frontend/hooks/queries/usePricePlansByStatusQuery";
import useIsLucaAdmin from "src/frontend/hooks/useIsLucaAdmin";
import { PricePlanStatusType } from "src/shared/trpc/queries/getPricePlansQuerySchema";

export default function usePricePlanDraftsQuery() {
  const isLucaAdmin = useIsLucaAdmin();
  const pricePlanDraftsStatus: PricePlanStatusType[] = isLucaAdmin
    ? ["DRAFT", "INTERNAL_DRAFT", "PREVIEW"]
    : ["DRAFT"];
  return usePricePlansByStatusQuery(pricePlanDraftsStatus, "");
}
