import Papa from "papaparse";
import { useState, ChangeEvent, useRef } from "react";
import TrpcClient from "src/frontend/api/TrpcClient";
import Code from "src/frontend/components/Code";
import Col from "src/frontend/components/Col";
import Button from "src/frontend/components/ui/Button";
import Dialog from "src/frontend/components/ui/Dialog";
import { Input } from "src/frontend/components/ui/Input";
import SemiBold from "src/frontend/components/ui/SemiBold";
import useToast from "src/frontend/components/ui/useToast";
import usePricePlanDraftsQuery from "src/frontend/hooks/queries/usePricePlanDraftsQuery";
import logClientError from "src/frontend/utils/logClientError";
import {
  UploadPricePlanMutationInput,
  UploadPricePlanMutationInputType,
} from "src/shared/trpc/mutations/uploadPricePlanMutationSchema";
import { MaybeNull } from "src/shared/types/maybe/MaybeNull";
import len from "src/shared/utils/arrays/len";
import isCsvFile from "src/shared/utils/csv/isCsvFile";
import SentryErrorEvent from "src/shared/utils/sentryErrorUtils";
import invariant from "tiny-invariant";

export default function UploadPricePlanDialog() {
  const t = useToast();
  const [loading, setLoading] = useState(false);
  const [csv, setCsv] =
    useState<MaybeNull<UploadPricePlanMutationInputType[] | null>>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const csvFileInputRef = useRef<HTMLInputElement>(null);
  const pricePlanQuery = usePricePlanDraftsQuery();
  const uploadPricePlanMutation =
    TrpcClient.internal.uploadPricePlan.useMutation();

  const onClose = () => {
    setCsv(null);
  };

  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files == null) {
      t.errorToast("No file found.");
      return;
    }

    const file = files[0];
    if (!isCsvFile(file)) {
      t.errorToast({
        description: (
          <span>
            File must be a CSV file ending with a <Code>.csv</Code> extension.
          </span>
        ),
        title: "Invalid file type",
      });
      if (csvFileInputRef.current != null) {
        csvFileInputRef.current.value = "";
      }
      return;
    }

    const fileReader = new FileReader();
    fileReader.readAsText(file, "UTF-8");
    fileReader.onload = (e) => {
      if (e.target == null) {
        t.errorToast("There was an error reading the CSV file.");
        return;
      }

      let content = e.target.result;
      if (typeof content !== "string") {
        t.errorToast("Invalid file contents.");
        return;
      }

      if (!content.includes(",")) {
        content = content.replaceAll("\n", ",");
        content = content.replaceAll("\r", "");
      }

      try {
        const parseResult = Papa.parse<UploadPricePlanMutationInputType>(
          content,
          {
            header: true,
            transform: (value) => (value.trim() === "" ? undefined : value),
          },
        );

        const data = parseResult.data.map((val) =>
          UploadPricePlanMutationInput.parse(val),
        );
        setCsv(data);
      } catch (err: any) {
        logClientError(err, SentryErrorEvent.ClientAppError);
        t.errorToast("Could not parse CSV.");
      }
    };
  };

  const onSuccess = async () => {
    await pricePlanQuery.refetch();
    t.successToast("Price plan uploaded successfully!");
    setLoading(true);
    setDialogOpen(false);
  };

  return (
    <Dialog
      confirmTitle="Continue"
      dialogTitle="Upload Price Plan"
      loading={loading}
      onConfirm={() => {
        try {
          invariant(csv != null);
          setLoading(true);
          t.infoToast("Starting upload.");
          uploadPricePlanMutation.mutate(csv, {
            onError: () => {
              setLoading(false);
              t.errorToast("Failed to upload price plan...");
            },
            onSuccess: () => {
              void onSuccess();
            },
          });
        } catch (err: any) {
          logClientError(err, SentryErrorEvent.ClientAppError, {
            extra: { csv },
          });
          t.errorToast("Failed to upload price plan...");
        }
      }}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
        setDialogOpen(open);
      }}
      open={dialogOpen}
      trigger={<Button className="px-7 font-normal">Upload CSV</Button>}
    >
      <Col className="gap-1">
        <p>Upload a new draft price plan from a CSV.</p>
        <p className="mt-2 text-sm">
          The CSV file must contain the following columns:
        </p>
        <ul className="text-sm">
          <li>
            • <Code>sku</Code> <SemiBold>(required)</SemiBold>
          </li>
          <li>
            • <Code>new_list_price</Code> <SemiBold>(required)</SemiBold>
          </li>
          <li>
            • <Code>old_list_price</Code> (optional)
          </li>
          <li>
            • <Code>new_discounted_price</Code> (optional)
          </li>
          <li>
            • <Code>old_discounted_price</Code> (optional)
          </li>
          <li>
            • <Code>new_member_price</Code> (optional)
          </li>
          <li>
            • <Code>old_member_price</Code> (optional)
          </li>
          <li>
            • <Code>new_subscriber_price</Code> (optional)
          </li>
          <li>
            • <Code>old_subscriber_price</Code> (optional)
          </li>
          <li>
            • <Code>price_plan_id</Code> (optional)
          </li>
        </ul>
        <Input
          className="mt-4 py-2 hover:cursor-pointer"
          id="sku-csv-file"
          onChange={(e) => handleUploadFile(e)}
          ref={csvFileInputRef}
          type="file"
        />
      </Col>
      <UploadedCsvSkuPreview csv={csv} />
    </Dialog>
  );
}

type UploadedCsvSkuPreviewProps = {
  csv: MaybeNull<UploadPricePlanMutationInputType[]>;
};

function UploadedCsvSkuPreview({ csv }: UploadedCsvSkuPreviewProps) {
  if (csv == null) {
    return null;
  }
  const groupsCount = len(csv);
  return (
    <div className="text-base">
      <p className="font-semibold">Ready to upload {groupsCount} SKUs.</p>
    </div>
  );
}
