import CardWithContent from "src/frontend/components/ui/CardWithContent";
import BrandApprovalSettings from "src/frontend/pages/company/price-settings/BrandApprovalSettings";
import PriceFrequencySettings from "src/frontend/pages/company/price-settings/PriceFrequencySettings";

export default function PriceSettings() {
  return (
    <div className="flex flex-col gap-6">
      <CardWithContent title="Price Plan Frequency">
        <PriceFrequencySettings />
      </CardWithContent>
      <CardWithContent title="Price Plan Approval">
        <BrandApprovalSettings />
      </CardWithContent>
    </div>
  );
}
