import { CompetitorPriceComparisonTypeEnum } from "src/shared/trpc/common/enum/CompetitorPriceComparisonType";
import { CompetitorPriceLookbackPeriod } from "src/shared/trpc/common/enum/CompetitorPriceLookbackPeriod";
import { ConstraintTypeEnum } from "src/shared/trpc/common/enum/ConstraintType";
import { PriceBoundTypeEnum } from "src/shared/trpc/common/enum/PriceBoundType";
import { PriceComparisonTarget } from "src/shared/trpc/common/enum/PriceComparisonTargetType";
import { PriceTargetTypeEnum } from "src/shared/trpc/common/enum/PriceTargetType";
import { z } from "zod";

const MatchType = z.object({
  brand_match: z.boolean().nullable(),
  flavor_match: z.boolean().nullable(),
  organic_match: z.boolean().nullable(),
  size_match: z.boolean().nullable(),
  variety_match: z.boolean().nullable(),
});

export type MatchTypeType = z.infer<typeof MatchType>;

export const PricingConstraint = z.object({
  average_margin_constraint_id: z.string().nullable(),
  bound_type: PriceBoundTypeEnum.nullable(),
  comparison_price_calculation_error: z.string().nullable(),
  comparison_price_target: PriceComparisonTarget.nullable(),
  competitor_price_calculated: z.number().nullable(),
  competitor_price_calculation_error: z.string().nullable(),
  competitor_price_comparison_type:
    CompetitorPriceComparisonTypeEnum.nullable(),
  competitor_price_lookback_period: CompetitorPriceLookbackPeriod.nullable(),
  competitor_price_target_type: PriceTargetTypeEnum.nullable(),
  competitors: z.string().array(),
  constraint_met: z.boolean().nullable(),
  experiment_id: z.string().uuid().nullable(),
  id: z.string().uuid(),
  lower_bound: z.number().nullable(),
  lower_bound_calculated: z.number().nullable(),
  lower_bound_calculation_error: z.string().nullable(),
  lower_bound_value: z.number().nullable(),
  price_target_type: PriceTargetTypeEnum,
  pricing_constraint_competitor_match_types: MatchType.array(),
  pricing_rules_group_id: z.string().uuid().nullable(),
  pricing_rules_group_name: z.string().nullable(),
  priority: z.number(),
  reference_price_calculated: z.number().nullable(),
  round_to: z.string().array(),
  smart_rounding: z.boolean().nullable(),
  strive_to_meet: z.boolean(),
  type: ConstraintTypeEnum,
  upper_bound: z.number().nullable(),
  upper_bound_calculated: z.number().nullable(),
  upper_bound_calculation_error: z.string().nullable(),
  upper_bound_value: z.number().nullable(),
});

export type PricingConstraintType = z.infer<typeof PricingConstraint>;
