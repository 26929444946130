import { PricingConstraintType } from "src/shared/trpc/common/PricingConstraint";
import arrayEmpty from "src/shared/utils/arrays/arrayEmpty";

export default function isCompetitorPriceConstraintValid(
  constraint: PricingConstraintType,
): boolean {
  if (constraint.type === "COMPETITOR_PRICE") {
    if (
      arrayEmpty(constraint.competitors) ||
      constraint.competitor_price_target_type == null ||
      constraint.competitor_price_comparison_type == null ||
      constraint.competitor_price_lookback_period == null ||
      arrayEmpty(constraint.pricing_constraint_competitor_match_types ?? []) ||
      arrayEmpty(constraint.competitors ?? [])
    ) {
      return false;
    }
  }

  return true;
}
