import { QueryClientProvider, useQueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Head from "next/head";
import { Suspense, useState } from "react";
import { Outlet, useLocation } from "react-router";
import { useSwipeable } from "react-swipeable";
import AppHeader from "src/frontend/components/AppHeader";
import LoadingScreen from "src/frontend/components/LoadingScreen";
import MobileNavBar from "src/frontend/components/MobileNavBar";
import SideBar from "src/frontend/components/Sidebar";
import { LucaHotKeyCommandMenu } from "src/frontend/components/admin/LucaHotKeyCommandMenu";
import BrandIdSyncComponent from "src/frontend/components/misc/BrandIdSyncComponent";
import ProductSidePanel from "src/frontend/components/product-side-panel/ProductSidePanel";
import { PostHogPageView } from "src/frontend/components/providers/PostHogProvider";
import Spinner from "src/frontend/components/ui/Spinner";
import { cn } from "src/frontend/components/ui/utils";
import useBackgroundRefresh from "src/frontend/hooks/useBackgroundRefresh";
import useBreakpoints from "src/frontend/hooks/useBreakpoints";
import useCompleteUserSignup from "src/frontend/hooks/useCompleteUserSignup";
import useHistoryTracker from "src/frontend/hooks/useHistoryTracker";
import useOnNavigateSideEffect from "src/frontend/hooks/useOnNavigateSideEffect";
import usePostHogIdentify from "src/frontend/hooks/usePostHogIdentify";

const SWIPE_RIGHT_BLOCK_LIST = [
  /^\/pricing\/(draft|demo|current|past)\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
  /^\/product-catalog/,
  /^\/competitor-intelligence/,
];

function shouldBlockSwipeRight(currentLocation: string): boolean {
  return SWIPE_RIGHT_BLOCK_LIST.some(
    (pattern) => pattern.test(currentLocation) === true,
  );
}

export default function Root() {
  useOnNavigateSideEffect();
  useBackgroundRefresh();
  usePostHogIdentify();
  useHistoryTracker();
  const queryClient = useQueryClient();
  const location = useLocation();
  const isUserSignupComplete = useCompleteUserSignup();
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const { isMedium } = useBreakpoints();
  const swipeHandlers = useSwipeable({
    onSwipedRight: () => {
      if (!isMobileNavOpen && !shouldBlockSwipeRight(location.pathname)) {
        setIsMobileNavOpen(true);
      }
    },
  });

  if (!isUserSignupComplete) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Head>
          <title>Luca App</title>
          <meta
            content="Luca - Smart Pricing for your eCommerce business"
            key="title"
            property="og:title"
          />
        </Head>
        <Suspense>
          <PostHogPageView />
        </Suspense>
        <div {...swipeHandlers}>
          <div className="flex h-screen max-h-screen w-screen flex-col">
            <AppHeader />
            <div className={cn("flex h-full flex-1 pt-[54px]")}>
              {isMedium ? (
                <MobileNavBar
                  isNavOpen={isMobileNavOpen}
                  setIsNavOpen={setIsMobileNavOpen}
                />
              ) : (
                <SideBar />
              )}
              <LoadingScreen />
              <ProductSidePanel />
              <LucaHotKeyCommandMenu />
              <BrandIdSyncComponent />
              <div className="flex flex-1 flex-col overflow-hidden">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </>
  );
}
