import { CustomerSegment } from "src/shared/trpc/common/enum/CustomerSegment";
import { ImpactFilter } from "src/shared/types/ImpactViewFilter";
import { z } from "zod";

export const DownloadNewPricePlanCsvApiPayload = z.object({
  brandId: z.string(),
  customerSegment: CustomerSegment.nullable(),
  groupIds: z.string().array(),
  impactFilter: ImpactFilter,
  pricePlanId: z.string(),
});

export type DownloadNewPricePlanCsvApiPayloadType = z.infer<
  typeof DownloadNewPricePlanCsvApiPayload
>;

export const DownloadNewPricePlanCsvApiResponse = z.object({
  experiments: z.string(),
});

export type DownloadNewPricePlanCsvApiResponseType = z.infer<
  typeof DownloadNewPricePlanCsvApiResponse
>;
