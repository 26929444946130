import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import TrpcClient from "src/frontend/api/TrpcClient";
import Col from "src/frontend/components/Col";
import Button from "src/frontend/components/ui/Button";
import Checkbox from "src/frontend/components/ui/Checkbox";
import { Modal } from "src/frontend/components/ui/Modal";
import useToast from "src/frontend/components/ui/useToast";
import useIsBrandAdmin from "src/frontend/hooks/useIsBrandAdmin";
import invariant from "tiny-invariant";

export default function ConfirmPriceChangesModal() {
  const { pricePlanId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedPrices, setUploadedPrices] = useState(false);
  const isBrandAdmin = useIsBrandAdmin();
  const t = useToast();
  const navigate = useNavigate();
  const approvePricePlanMutation =
    TrpcClient.internal.approvePricePlan.useMutation();

  return (
    <Modal
      cancelText="Cancel"
      confirmButtonDisabled={!uploadedPrices}
      confirmText="Confirm Launch"
      dialogDescription={
        <Col className="gap-2">
          <p>
            Make sure that the changes from this price plan have been uploaded
            to your system and have gone live before proceeding.
          </p>
          <Checkbox
            checked={uploadedPrices}
            id="uploaded-price-plan"
            label="I confirm I have uploaded the prices and they are live"
            onCheckedChange={() => setUploadedPrices((prev) => !prev)}
          />
        </Col>
      }
      dialogTitle="Confirm Price Updates"
      loading={approvePricePlanMutation.isLoading}
      onConfirm={() => {
        invariant(pricePlanId != null, "pricePlanId cannot be null.");
        approvePricePlanMutation.mutate(
          {
            pricePlanId,
            shouldPushPrices: false,
          },
          {
            onError: () => {
              t.errorToast("Failed to launch price plan.");
            },
            onSuccess: () => {
              t.successToast("Price plan launched successfully!");
              navigate("/pricing/current", { replace: true });
            },
          },
        );
      }}
      onOpenChange={setIsModalOpen}
      open={isModalOpen}
      trigger={
        <Button disabled={pricePlanId == null || !isBrandAdmin}>
          <h2 className="text-sm font-bold">Confirm Price Updates</h2>
        </Button>
      }
    />
  );
}
