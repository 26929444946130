import Bold from "src/frontend/components/Bold";
import Code from "src/frontend/components/Code";
import Col from "src/frontend/components/Col";
import { CopyText } from "src/frontend/components/CopyText";
import Row from "src/frontend/components/Row";
import SemiBold from "src/frontend/components/ui/SemiBold";
import { Separator } from "src/frontend/components/ui/Separator";
import formatGenericEnumLabel from "src/frontend/utils/formatGenericEnumLabel";
import { ProductMasterObjectType } from "src/shared/trpc/common/ProductMasterObject";
import arrayNotEmpty from "src/shared/utils/arrays/arrayNotEmpty";
import formatDate from "src/shared/utils/dates/formatDate";
import capitalize from "src/shared/utils/strings/capitalize";
import jsonStringify from "src/shared/utils/strings/jsonStringify";

type ProductSidePanelContentProps = {
  product: ProductMasterObjectType;
};

export default function ProductSidePanelContent({
  product,
}: ProductSidePanelContentProps) {
  const {
    categories,
    int_product_attributes,
    price_zone,
    product_brand_name,
    sku,
    sub_categories,
  } = product;
  const hasProductAttributes =
    int_product_attributes != null &&
    arrayNotEmpty(Object.keys(int_product_attributes ?? {}));
  const productAttributesAllNull = Object.values(
    int_product_attributes ?? {},
  ).every((val) => val == null);
  const attributesToRender =
    hasProductAttributes && !productAttributesAllNull
      ? int_product_attributes
      : null;
  return (
    <Col className="text-sm">
      <Bold className="mt-1 text-base">Product Details:</Bold>
      <Row>
        <SemiBold className="mr-1">Brand:</SemiBold>
        {product_brand_name ?? "--"}
      </Row>
      {arrayNotEmpty(categories) && (
        <Row>
          <SemiBold className="mr-1">Categories:</SemiBold>
          {categories.join(", ")}
        </Row>
      )}
      {arrayNotEmpty(sub_categories) && (
        <Row>
          <SemiBold className="mr-1">Sub Categories:</SemiBold>
          {sub_categories.join(", ")}
        </Row>
      )}
      {price_zone != null && (
        <Row>
          <SemiBold className="mr-1">Price Zone:</SemiBold>
          {price_zone}
        </Row>
      )}
      <Row>
        <SemiBold className="mr-1">SKU:</SemiBold>
        {sku == null ? (
          "--"
        ) : (
          <CopyText isCode textToCopy={sku}>
            <Code>{sku}</Code>
          </CopyText>
        )}
      </Row>
      <Separator className="mt-4" />
      {attributesToRender != null && (
        <>
          <Bold className="mt-2 text-base">Product Attributes:</Bold>
          {Object.entries(attributesToRender).map(([key, value]) => {
            const stringValue =
              value instanceof Date
                ? formatDate(value)
                : typeof value === "string"
                  ? value
                  : jsonStringify(value);
            return (
              <Row key={key + stringValue}>
                <SemiBold className="mr-1">
                  {capitalize(formatGenericEnumLabel(key))}:
                </SemiBold>
                {value === "" ? "--" : stringValue}
              </Row>
            );
          })}
        </>
      )}
    </Col>
  );
}
